import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { MeasureModel } from "../models/measure.model";
import { DataService } from "./data.service";

@Injectable()
export class MeasureRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }


    async getMeasures(clientId: number, searchName:string, page: number, pageSize: number) {
        var params = "?Name=" + searchName + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/Measure/FilterMeasures" + params,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

    async updateMeasure(obj: MeasureModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Measure/Update", obj,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

    async createMeasure(obj: MeasureModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Measure/Create", obj,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }
}