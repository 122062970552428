import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ArticleModel } from 'src/app/models/article.model';
import { ArticleWarehouseStateModel } from 'src/app/models/articleWarehouseState.model';
import { GroupModel } from 'src/app/models/group.model';
import { GroupTypeModel } from 'src/app/models/groupType.model';
import { IdModel } from 'src/app/models/id.model';
import { MeasureModel } from 'src/app/models/measure.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { ArticleRestService } from 'src/app/services/article.service';
import { ClientRestService } from 'src/app/services/client.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { GroupTypeRestService } from 'src/app/services/groupType.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { WarehouseStateRestService } from 'src/app/services/warehouseState.service';
import { TranslateService } from 'src/app/translate';
import toastr from 'toastr';

declare var $: any;
declare var KTMenu: any;
declare var Inputmask: any;

@Component({
    selector: 'app-warehouse-state',
    templateUrl: './warehouse-state.component.html',
})
export class WarehouseStateComponent {

    public groups: GroupModel[];
    public groupTypes: GroupTypeModel[];
    public warehouses: WarehouseModel[];
    public emptyData: boolean = true;
    public searchForm: UntypedFormGroup;
    public articleStates: ArticleWarehouseStateModel[];
    public name: string;
    public groupIds: number[] = [];
    public groupTypeIds: number[] = [];
    public fromDate: string;
    public toDate: string;
    public warehouseIds: number[] = [];
    public fromWarehouse: boolean;
    public hideZeroes: boolean;
    public diagnostic:boolean = false;
    public awaitDiagnosticCommand:boolean = false;

    @ViewChild('selectFilterClientId') selectFilterClientId: ElementRef;
    @ViewChild('selectFilterWarehouseId') selectFilterWarehouseId: ElementRef;
    @ViewChild('selectFilterGroupId') selectFilterGroupId: ElementRef;
    @ViewChild('selectFilterGroupTypeId') selectFilterGroupTypeId: ElementRef;
    @ViewChild('searchArticleName') searchArticleName: ElementRef;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;

    constructor(
        private warehouseStateRest: WarehouseStateRestService,
        private clientRest: ClientRestService,
        private groupTypeRest: GroupTypeRestService,
        private groupRest: GroupRestService,
        private warehouseRest: WarehouseRestService,
        private formBuilder: UntypedFormBuilder,
        public data: DataService,
        private _changeRef: ChangeDetectorRef,
        private translate: TranslateService,
        private router: Router,
        private datePipe: DatePipe,
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit() {
        KTMenu.createInstances();
        this.createSearchForm();
        this.getWarehouseState();
        this.fetchGroups();
        this.fetchGroupTypes();
        this.fetchWarehouses();
    }

    @HostListener('document:keydown', ['$event'])
    checkForDiagnosticInput(event: KeyboardEvent) {
      if(event.keyCode ==192){
        this.awaitDiagnosticCommand=!this.awaitDiagnosticCommand;
        if(this.awaitDiagnosticCommand==false)
            this.diagnostic=false;        
      }
      else if(this.awaitDiagnosticCommand && (event.key=='T' || event.key=='t')){
        this.diagnostic=!this.diagnostic;
      }
      else 
        this.awaitDiagnosticCommand=false;
    }

    async getWarehouseState() {
        try {
            this.emptyData = false;

            var name = this.searchForm.controls.name.value;
            var response = await this.warehouseStateRest.getWarehouseState(this.fromDate, this.toDate, this.warehouseIds, this.name, this.groupTypeIds, this.groupIds, this.fromWarehouse, this.hideZeroes, this.data.user.clientId, this.page, this.perPage);
            this.articleStates = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchGroups() {
        try {
            var response = await this.groupRest.getGroups("", null, this.data.user.clientId, 1, 10000);
            this.groups = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }
    //   async fetchGroupsByType(){
    //     var response = await this.groupRest.getGroups("", this.groupTypeId, this.data.user.clientId, 1, 10000);
    //     this.groups = response.data;
    // }
    async fetchGroupTypes() {
        try {
            var response = await this.groupTypeRest.getGroupTypes("", this.data.user.clientId, 1, 10000);
            this.groupTypes = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }
    async fetchWarehouses() {
        try {
            var response = await this.warehouseRest.filterWarehouses(this.data.user.clientId, "", 1, 10000);
            this.warehouses = response.data;

            if (this.warehouses.length > 0)
                $("#filter_warehouseId").val(this.warehouses[0].id).trigger("change");
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    createSearchForm() {
        this.searchForm = this.formBuilder.group({
            name: [''],
            hideZeroes: [''],
        })

        $("#filter_groupTypeId").select2();
        var self = this;
        $('#filter_groupTypeId').on("change", function () {
            var values = $(this).val();
            self.groupTypeIds = [];
            values.forEach(x => {
                if (x != undefined && x != null && x != '') {
                    var val = self.formatSelectValue(x);
                    if(!isNaN(val))
                        self.groupTypeIds.push(val);
                }
            });
            //   self.fetchGroupsByType();
        });

        $("#filter_groupId").select2();
        var self = this;
        $('#filter_groupId').on("change", function () {
            var values = $(this).val();
            self.groupIds = [];
            values.forEach(x => {
                if (x != undefined && x != null && x != '') {
                    var val = self.formatSelectValue(x);
                    if(!isNaN(val))
                        self.groupIds.push(val);
                }
            });
        });

        $("#filter_warehouseId").select2();
        $('#filter_warehouseId').on("change", function () {
            var values = $(this).val();
            self.warehouseIds = [];
            if (values != undefined && values!=null && values.length > 0) {
                values.forEach(x => {
                    var val = self.formatSelectValue(x);
                    if(!isNaN(val) && val!=null)
                        self.warehouseIds.push(val);
                });
            }
            self.getWarehouseState();
        });

        this.fromDate = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 0), 'yyyy-MM-dd HH:mm');
        var self = this;
        $("#fromDatePicker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(new Date().getFullYear(), 0, 1),
            locale: {
                format: 'DD/MM/YYYY'
            }
        });

        var start = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 0));
        $("#toDatePicker").daterangepicker({
            singleDatePicker: true,
            timePicker: true,
            autoUpdateInput: true,
            startDate: start,
            locale: {
                format: 'DD/MM/YYYY HH:mm'
            }
        });
        $("#toDatePicker").on('apply.daterangepicker', function (ev, picker) {
            self.toDate = picker.startDate.format('YYYY-MM-DD HH:mm');
            self.getWarehouseState();
        });
        var im = new Inputmask("99/99/9999 99:99");
        im.mask($("#toDatePicker"));
    }

    formatSelectValue(val:string){
        if(val=='')
            return null;
        var split = (val.split(':')[1]).trim();
        return parseInt(split);
      }

    filterByDate(event: KeyboardEvent) {
        if (event.key == "Enter" || event.key == "Tab") {
            var pickerElement = $("#toDatePicker");
            $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        }
    }

    resetFilter() {
        this.searchForm.controls['name'].setValue('');
        this.searchForm.controls['hideZeroes'].setValue('false');

        $('#filter_groupId').val(null).trigger("change");
        $('#filter_groupTypeId').val(null).trigger("change");
        $('#filter_warehouseId').val(null).trigger("change");

        var pickerElement = $("#toDatePicker");
        $(pickerElement).data('daterangepicker').setStartDate(new Date());
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        this.firstLoad = true;
        this.page = 1;
        this.getWarehouseState();
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    this.firstPage = this.maxPage - this.offset;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    this.firstPage = this.maxPage - this.offset;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.getWarehouseState();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
        }
        this.counter++;
        this.getWarehouseState();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.getWarehouseState();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.getWarehouseState();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.getWarehouseState();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}