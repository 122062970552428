export class SignOffModel{
    id:string;
    issuerId:number;
    issuerName:string;
    signOffNumber:string;
    documentNumber:number;
    warehouseId:number;
    warehouseName:string;
    date:Date;
    signOffType:string;
    articles:SignOffArticleModel[];
    totalNC:number;
    totalVPC:number;
    totalMPC:number;
    clientId:number;
    note:string;
    customDocumentNumber:number;
}
export class SignOffArticleModel{
    id:number;
    quantity:number;
    code:string;
    name:string;
    g:number;
    gt:number;
    nc:number;
    vpc:number;
    mpc:number;
    totalNC:number;
    totalVPC:number;
    totalMPC:number;
    measureShort:string;
    uniqueItemId:string;
}