<div class="toolbar" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1" style="font-size:22px!important;">{{'_Group_types' | translate}}</h1>
            <span class="h-20px border-gray-600 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/home']" class="text-gray-600 text-hover-primary" style="font-size:16px!important">{{'_Home' | translate}}</a>
                </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-600 w-5px h-2px" style="font-size:16px!important"></span>
                </li>
                <li class="breadcrumb-item text-gray-600" style="font-size:16px!important">{{'_Group_types' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl" style="padding:0px;margin-top:25px;margin-bottom:25px;">
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title"></div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-group-type-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary me-3" data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end">
                            <span class="svg-icon svg-icon-2"  *ngIf="data.currentMode=='light'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                        fill="black"></path>
                                </svg>
                            </span>
                            <span class="svg-icon svg-icon-2"  *ngIf="data.currentMode=='dark'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                        fill="white"></path>
                                </svg>
                            </span>
                            Filter
                        </button>
                        <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true"
                            id="kt-toolbar-filter">
                            <div class="px-7 py-5">
                                <div class="fs-4 text-dark fw-bolder">{{'_Filter_options' | translate}}</div>
                            </div>
                            <div class="separator border-gray-200"></div>
                            <div class="px-7 py-5">
                                <form [formGroup]="searchForm">
                                    <div class="mb-10">
                                        <label class="form-label fs-5 fw-bold mb-3">{{'_Group_type_name' |
                                            translate}}:</label>
                                        <input type="text" class="form-control form-control-solid" placeholder=""
                                            name="name" value="" formControlName="name" />
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button type="reset" class="btn btn-light btn-active-light-primary me-2"
                                            data-kt-menu-dismiss="true" data-kt-group-type-table-filter="reset"
                                            (click)="resetFilter()">{{'_Reset' | translate}}</button>
                                        <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true"
                                            data-kt-group-type-table-filter="filter"
                                            (click)="searchGroupTypes()">{{'_Apply' | translate}}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#kt_modal_add_group_type" (click)="resetForm()"><i class="fas fa-plus"></i>{{'_Add_group_type' |
                            translate}}</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_group-types_table"
                    *ngIf="!emptyData">
                    <thead>
                        <tr class="text-start text-gray-900 fw-bolder fs-6 text-uppercase gs-0">
                            <th style="width:80%;font-size:14px;padding-left:10px;">{{'_Group_type_name' | translate}}</th>
                            <th class="text-center" style="width:20%;font-size:14px;">{{'_Actions' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-1000">
                        <tr *ngFor="let a of groupTypes;let r=index" [ngClass]="{ 'evenTableRowColor': r%2==0, 'oddTableRowColor':r%2!=0 }">
                            <td style="width:80%;padding-left:10px;padding-top:8px;padding-bottom:8px;">{{a.name}}</td>
                            <td class="text-center" style="width:20%;padding-top:8px;padding-bottom:8px;">
                                <popper-content #popperEditItem>
                                    {{'_Edit'|translate}}
                                </popper-content>
                                <div [popper]="popperEditItem"
                                    [popperShowOnStart]="false"
                                    [popperTrigger]="'hover'"
                                    popperHideOnClickOutside
                                    [popperHideOnMouseLeave]="true"
                                    [popperHideOnScroll]="true"
                                    [popperPlacement]="'top'">
                                <a (click)="selectGroupType(a)" class="btn btn-icon btn-warning fs-8"
                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_edit_group_type">
                                    <i class="fa fa-pencil-alt fs-4" aria-hidden="true"></i>
                                </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row" *ngIf="!emptyData">
                    <div
                        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="dataTables_length" id="kt_customers_table_length"><label><select
                                    name="kt_customers_table_length" aria-controls="kt_customers_table"
                                    class="form-select form-select-sm form-select-solid" [(ngModel)]="perPage"
                                    (change)="perPageHandler()">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select></label></div>
                    </div>
                    <div
                        class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div class="dataTables_paginate paging_simple_numbers" id="kt_customers_table_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous disabled"
                                    id="kt_customers_table_previous"><a (click)="prevpage()"
                                        aria-controls="kt_customers_table" data-dt-idx="0" tabindex="0"
                                        class="page-link" style="cursor:pointer"><i class="previous"></i></a></li>
                                <div *ngFor="let p of pages">
                                    <li class="paginate_button page-item active" *ngIf="page==p">
                                        <a aria-controls="kt_customers_table" data-dt-idx="2" tabindex="0"
                                            class="page-link" (click)="goToPage(p)" style="cursor:pointer">{{p}}</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="page!=p">
                                        <a aria-controls="kt_customers_table" data-dt-idx="2" tabindex="0"
                                            class="page-link" (click)="goToPage(p)" style="cursor:pointer">{{p}}</a>
                                    </li>
                                </div>
                                <li class="paginate_button page-item disabled" id="kt_customers_table_morePages" *ngIf="maxPage>offset"><a
                                    (click)="morePages()" aria-controls="kt_customers_table" data-dt-idx="0"
                                    tabindex="0" class="page-link" style="cursor:pointer">...</a></li>
                                <li class="paginate_button page-item next" id="kt_customers_table_next"><a
                                        (click)="nextPage()" aria-controls="kt_customers_table" data-dt-idx="5"
                                        tabindex="0" class="page-link" style="cursor:pointer"><i class="next"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="emptyData">
                    <div class="text-center">
                        <h1 class="fw-bolder mb-5">{{'_No_results' | translate}}</h1>
                        <div class="separator separator-dashed border-danger opacity-25 mb-5"></div>
                        <div class="mb-9 text-dark">
                            {{'_No_results_text' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_add_group_type" tabindex="-1" aria-hidden="true" #closeAddGroupTypeModal>
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <form class="form" action="#" id="kt_modal_add_group_type_form" [formGroup]="formGroup">
                <div class="modal-header" id="kt_modal_add_group_type_header">
                    <h2 class="fw-bolder">{{'_Add_group_type' | translate}}</h2>
                    <div id="kt_modal_add_group_type_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                        (click)="closeAddModal()">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_add_group_type_scroll" data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_add_group_type_header"
                        data-kt-scroll-wrappers="#kt_modal_add_group_type_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Group_type_name' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="name"
                                value="" formControlName="name" required
                                [ngClass]="{ 'is-invalid': isSubmitted && f.name.errors }" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer flex-center">
                    <button type="reset" id="kt_modal_add_group_type_cancel" (click)="closeAddModal()"
                        class="btn btn-light me-3">{{'_Discard' | translate}}</button>
                    <button type="submit" id="kt_modal_add_group_type_submit" class="btn btn-primary"
                        (click)="addGroupType()">
                        <span class="indicator-label">{{'_Submit' | translate}}</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_edit_group_type" tabindex="-1" aria-hidden="true" #closeEditGroupTypeModal>
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <form class="form" action="#" id="kt_modal_edit_group_type_form" [formGroup]="formGroup">
                <div class="modal-header" id="kt_modal_edit_group_type_header">
                    <h2 class="fw-bolder">{{'_Edit_group_type' | translate}}</h2>
                    <div id="kt_modal_add_group_type_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                       (click)="closeEditModal()">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_edit_group_type_scroll" data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_edit_group_type_header"
                        data-kt-scroll-wrappers="#kt_modal_edit_group_type_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Group_type_name' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="name"
                                value="" formControlName="name" required
                                [ngClass]="{ 'is-invalid': isSubmitted && f.name.errors }" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer flex-center">
                    <button type="reset" id="kt_modal_add_group_type_cancel" (click)="closeEditModal()"
                        class="btn btn-light me-3">{{'_Discard' | translate}}</button>
                    <button type="submit" id="kt_modal_add_group_type_submit" class="btn btn-primary"
                        (click)="updateGroupType()">
                        <span class="indicator-label">{{'_Submit' | translate}}</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-http-loader [spinner]="'sk-wave'"></ng-http-loader>