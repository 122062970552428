import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ArticleModel } from 'src/app/models/article.model';
import { GroupModel } from 'src/app/models/group.model';
import { GroupTypeModel } from 'src/app/models/groupType.model';
import { IdModel } from 'src/app/models/id.model';
import { MeasureModel } from 'src/app/models/measure.model';
import { ArticleRestService } from 'src/app/services/article.service';
import { ClientRestService } from 'src/app/services/client.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { GroupTypeRestService } from 'src/app/services/groupType.service';
import { TranslateService } from 'src/app/translate';
import toastr from 'toastr';
import { environment } from '../../../environments/environment';
import { NormativeModel } from 'src/app/models/normative.model';
import { HalfProductModel } from 'src/app/models/halfProduct.model';
import { MeasureRestService } from 'src/app/services/measure.service';
import { error } from 'protractor';
import { TaxModel } from 'src/app/models/tax.model';
import { TaxRestService } from 'src/app/services/tax.service';

declare var $: any;
declare var KTMenu: any;
declare var Swal: any;
declare var KTImageInput: any;
declare var KTScroll: any;

@Component({
    selector: 'erp-article',
    templateUrl: './article.component.html',
})
export class ArticleComponent {

    public articles: ArticleModel[];
    public measures: MeasureModel[];
    public groupTypes: GroupTypeModel[];
    public groups: GroupModel[];
    public taxes: TaxModel[];
    public emptyData: boolean = true;
    public searchForm: UntypedFormGroup;
    public formGroup: UntypedFormGroup;
    public selectedArticle: ArticleModel;
    public selectedArticleMeasure: string;
    public isSubmitted: boolean = false;

    public mpc: number;
    public vpc: number;
    public nc: number;
    public hh: number;
    public margin: number;

    public groupId: number = null;
    public groupTypeId: number = null;
    public measureId: number = null;
    public isActive: any = "null";
    public isVisible: any = "null";

    public addEditGroupId: number = null;
    public addEditGroupTypeId: number = null;
    public addEditMeasureId: number = null;
    public addEditIsActive: boolean = true;
    public isNormative: boolean = false;
    public normativeAmount: number = 0;
    public articleForNormative: ArticleModel;
    public workingNormativeData: NormativeModel[];
    public shouldProduce: boolean = false;
    public articleForHalfProduct: ArticleModel;
    public workingHalfProductData: HalfProductModel[];
    public imageAdd: typeof KTImageInput;
    public imageEdit: typeof KTImageInput;
    public selectedImage: File = null;
    public base64Image: string = null;
    public imagesUrl: string = environment.imagesUrl;
    public clientId: number;
    public showBarcodes: boolean = false;
    public newBarcode: string;
    public barcodesList: string[] = [];
    public displaySecondaryBarcodes: string[] = [];
    public showBarcodeErrorAdd = false;
    public showBarcodeErrorEdit: boolean = false;
    public barcodeErrorTextAdd: string = "";
    public barcodeErrorTextEdit: string = "";
    get f() { return this.formGroup.controls; }
    public normativeSelection: ArticleModel[];
    public normativeTotal: number;
    public halfProductSelection: ArticleModel[];
    public halfProductAmount: number = 0;
    public editingHalfProductItem: HalfProductModel = null;
    public editingNormativeItem: NormativeModel=null;
    public firstMeasure: MeasureModel;
    public startingNormativeSelection: ArticleModel[];
    public startingHalfProductSelection: ArticleModel[];

    @ViewChild('closeAddArticleModal') closeAddArticleModal: ElementRef;
    @ViewChild('closeEditArticleModal') closeEditArticleModal: ElementRef;
    @ViewChild('selectedAddEditClient') selectedAddEditClient: ElementRef;
    @ViewChild('selectFilterClientId') selectFilterClientId: ElementRef;
    @ViewChild('selectFilterMeasureId') selectFilterMeasureId: ElementRef;
    @ViewChild('selectFilterGroupId') selectFilterGroupId: ElementRef;
    @ViewChild('searchArticleName') searchArticleName: ElementRef;
    @ViewChild('normativeSearch') normativeSearch: ElementRef;
    @ViewChild('normativeScroll') normativeScroll: ElementRef;
    @ViewChild('halfProductSearch') halfProductSearch: ElementRef;
    @ViewChild('halfProductScroll') halfProductScroll: ElementRef;

    @ViewChild('selectAddArticleGroupId') selectAddArticleGroupId: ElementRef;
    @ViewChild('selectAddMeasureId') selectAddMeasureId: ElementRef;
    @ViewChild('selectAddNormativeId') selectAddNormativeId: ElementRef;
    @ViewChild('editNormativeDataTrigger') editNormativeDataTrigger: ElementRef;
    @ViewChild('editHalfProductDataTrigger') editHalfProductDataTrigger: ElementRef;

    @ViewChild('selectEditArticleGroupId') selectEditArticleGroupId: ElementRef;
    @ViewChild('selectEditMeasureId') selectEditMeasureId: ElementRef;

    @ViewChild('imageCancelAdd') imageCancelAdd: ElementRef;
    @ViewChild('imagePreviewAdd') imagePreviewAdd: ElementRef;
    public customCode: boolean;
    public nextNumber: number;
    @ViewChild('submitFilter') submitFilter: ElementRef;

    public skipAfterMPC: boolean = true;
    file: File | null = null;
    @ViewChild('listViewImageUpload') listViewImageUpload:ElementRef;
    uploadImageForArticleId:number;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;

    constructor(
        private articleRest: ArticleRestService,
        private clientRest: ClientRestService,
        private groupRest: GroupRestService,
        private taxRest: TaxRestService,
        private measureRest: MeasureRestService,
        private formBuilder: UntypedFormBuilder,
        public data: DataService,
        private _changeRef: ChangeDetectorRef,
        private translate: TranslateService,
        private router: Router
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit() {
        this.clientId = this.data.user.clientId;
        KTMenu.createInstances();
        var imageInputAdd = document.querySelector("#kt_image_input_add");
        this.imageAdd = new KTImageInput(imageInputAdd);
        var imageInputEdit = document.querySelector("#kt_image_input_edit");
        this.imageEdit = new KTImageInput(imageInputEdit);
        this.createSearchForm();
        this.fetchArticles();
        this.fetchGroups();
        this.fetchTaxes();
        this.fetchMeasures();
        this.createAddEditForm();
        this.getUserSettings();

        var imageInputElement = document.querySelector("#kt_image_input_add");
        var imageInput = KTImageInput.getInstance(imageInputElement);
        imageInput.on("kt.imageinput.cancel", function () {
        });
    }

    ngAfterViewInit() {
        $("#normativeDataModal").on('shown.bs.modal', () => {
            $("#searchItemInput").focus();
        });

        $("#normativeDataModal").on('hidden.bs.modal', () => {
            this.normativeSelection = JSON.parse(JSON.stringify(this.startingNormativeSelection));
            this.articleForNormative = undefined;
            this.resetNormativeForm();
            this._changeRef.detectChanges();
        });

        this.searchForNormative(true);

        $("#halfProductDataModal").on('shown.bs.modal', () => {
            $("#halfProductSearch").focus();
        });

        $("#halfProductDataModal").on('hidden.bs.modal', () => {
            this.halfProductSelection = JSON.parse(JSON.stringify(this.startingHalfProductSelection));
            this.articleForHalfProduct = undefined;
            this.resetNormativeForm();
            this._changeRef.detectChanges();
        });

        $("#kt_modal_add_article").on('shown.bs.modal', () => {
            $("#article_add_measureId").select2().val(this.firstMeasure.id).trigger('change');
            $("#codeInput").focus();
        });

        this.searchForHalfProduct(true);
    }

    async getUserSettings() {
        try {
            var settings = await this.clientRest.getUserSettings(this.data.user.id);
            this.skipAfterMPC = settings.skipAfterMPC;
        } catch (err: any) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchArticles(forNormative: boolean = false, forHalfProduct: boolean = false) {
        try {
            this.emptyData = false;
            var name = this.searchForm.controls['name'].value;
            var response = await this.articleRest.getArticles(name, this.groupId, this.data.user.clientId, this.measureId, this.isActive, this.page, this.perPage);
            this.articles = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchGroups() {
        var response = await this.groupRest.getGroups("", null, this.data.user.clientId, 1, 10000);
        this.groups = response.data;
    }

    async fetchTaxes() {
        var response = await this.taxRest.getTaxes(this.data.user.clientId, '', 1, 10000);
        this.taxes = response.data;
    }


    async fetchMeasures() {
        var response = await this.measureRest.getMeasures(this.data.user.clientId, "", 1, 10000);
        this.measures = response.data;
        var customMeasures = this.measures.filter(x => x.clientId != 0).sort(x => x.id);
        if (customMeasures.length != 0)
            this.firstMeasure = customMeasures[0];
        else
            this.firstMeasure = this.measures[0];
    }

    createSearchForm() {
        this.searchForm = this.formBuilder.group({
            name: [''],
            groupId: [null],
            measureId: [this.measureId],
            isActive: ['null']
        })

        $("#article_filter_measureId").select2();
        var self = this;
        $('#article_filter_measureId').on("change", function () {
            self.measureId = $(this).val();
        });

        $("#article_filter_groupId").select2();
        var self = this;
        $('#article_filter_groupId').on("change", function () {
            self.groupId = $(this).val();
            self.submitFilter.nativeElement.click();
        });
    }

    searchArticles(nameValue: string = '', forNormative: boolean = false, forHalfProduct: boolean = false, groupId: any = null) {
        this.searchForm.controls["name"].setValue(nameValue);
        this.searchForm.controls["groupId"].setValue(groupId);
        this.firstLoad = true;
        this.page = 1;
        this.fetchArticles(forNormative, forHalfProduct);
    }

    resetFilter() {
        this.searchForm.controls['name'].setValue('');
        this.searchArticleName.nativeElement.value = "";
        $('#article_filter_groupId').select2().val(null).trigger('change');
        this.firstLoad = true;
        this.page = 1;
        this.fetchArticles();
    }

    async searchForNormative(firstFetch:boolean=false) {
        var response = await this.articleRest.getArticles(this.normativeSearch.nativeElement.value, null, this.data.user.clientId, null, true, 1, 50, false);
        this.normativeSelection = response.data;

        if(firstFetch){
            this.startingNormativeSelection = JSON.parse(JSON.stringify(this.normativeSelection));
        }
    }

    selectForNormative(a: ArticleModel) {
        this.articleForNormative = a;
        this.hideTable();
        this._changeRef.detectChanges();
    }
    showTable() {
        $("#normativeTableWrapper").show();
    }
    hideTable() {
        $("#normativeTableWrapper").hide();
    }
    enterOnRow(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            var element = event.target as HTMLElement;
            element.click();
        }
    }
    async checkBottom(scrollableElement: HTMLElement) {
    }

    async searchForHalfProduct(firstFetch:boolean=false) {
        var response = await this.articleRest.getArticles(this.halfProductSearch.nativeElement.value, null, this.data.user.clientId, null, true, 1, 50, false);
        this.halfProductSelection = response.data;
        if(firstFetch){
            this.startingHalfProductSelection = JSON.parse(JSON.stringify(this.halfProductSelection));
        }
    }
    selectForHalfProduct(a: ArticleModel) {
        this.articleForHalfProduct = a;
        this.hideTableHalfProduct();
        this._changeRef.detectChanges();
    }
    showTableHalfProduct() {
        $("#halfProductTableWrapper").show();
    }
    hideTableHalfProduct() {
        $("#halfProductTableWrapper").hide();
    }

    async getNextNumber() {
        this.nextNumber = await this.articleRest.getNextNumber(this.data.user.clientId);
        this.formGroup.controls.code.setValue(this.nextNumber);
        // $(this.codeAddLegal.nativeElement).val(this.setCode);
    }

    startAddingArticle(){
        this.resetForm();
        this.workingNormativeData = [];
        this.workingHalfProductData = [];
    }

    async selectArticle(a: ArticleModel) {
        await this.getNextNumber();
        this.selectedArticle = a;
        this.selectedArticleMeasure = this.measures.find(x => x.id == a.measureId).short;

        this.formGroup.controls.name.setValue(this.selectedArticle.name);
        this.formGroup.controls.code.setValue(this.selectedArticle.code);
        this.formGroup.controls.description.setValue(this.selectedArticle.description);
        this.formGroup.controls.addEditGroupId.setValue(this.selectedArticle.groupId);
        this.addEditGroupTypeId = this.selectedArticle.groupTypeId;
        this.addEditGroupId = this.selectedArticle.groupId;
        this.formGroup.controls.nc.setValue(this.selectedArticle.nc);
        this.formGroup.controls.mpc.setValue(this.selectedArticle.mpc);
        this.formGroup.controls.vpc.setValue(this.selectedArticle.vpc);
        this.formGroup.controls.margin.setValue(this.selectedArticle.margin);
        this.formGroup.controls.happyHourPrice.setValue(this.selectedArticle.happyHourPrice);
        this.formGroup.controls.minAmount.setValue(this.selectedArticle.minAmount);
        this.addEditMeasureId = this.selectedArticle.measureId;
        this.formGroup.controls.addEditMeasureId.setValue(this.selectedArticle.measureId);
        this.formGroup.controls.addEditIsActive.setValue(this.selectedArticle.isActive);
        this.formGroup.controls.isNormative.setValue(this.selectedArticle.isNormative);
        this.formGroup.controls.shouldProduce.setValue(this.selectedArticle.shouldProduce);

        $('#article_edit_group_typeId').select2().val(this.selectedArticle.groupTypeId).trigger("change");
        $('#article_edit_groupId').select2().val(this.selectedArticle.groupId).trigger("change");
        $('#article_edit_measureId').select2().val(this.selectedArticle.measureId).trigger("change");

        this.newBarcode = "";
        this.barcodesList = this.selectedArticle.barcodes;
        this.displaySecondaryBarcodes = this.selectedArticle.barcodes.slice(1);
        this.showBarcodeErrorEdit = false;
        $("#newBarcodeEdit").removeClass('is-invalid');

        if (a.picture != null) {
            var imgUrl = environment.imagesUrl + this.data.user.clientId + '/' + a.picture;
            $("#imagePreviewEdit").css('background-image', "url('" + imgUrl + "')");
        } else {
            $("#imagePreviewEdit").css('background-image', "url('/assets/media/svg/avatars/blank.svg')");
        }

        if(this.selectedArticle.isNormative){
            this.selectedArticle.normativeItems = await this.articleRest.getNormativeData(this.selectedArticle.id);
            this.workingNormativeData = JSON.parse(JSON.stringify(this.selectedArticle.normativeItems));
            this.normativeTotal = this.workingNormativeData.reduce((accumulator, currentItem) => {
                return accumulator + parseFloat((currentItem.amount * currentItem.nc).toFixed(6));
            }, 0)
            this._changeRef.detectChanges();
        }
        else {
            this.workingNormativeData = [];
            this.normativeTotal = 0;
        }

        if(this.selectedArticle.shouldProduce){
            this.selectedArticle.halfProductData = await this.articleRest.getHalfProductData(this.selectedArticle.id);
            this.workingHalfProductData = JSON.parse(JSON.stringify(this.selectedArticle.halfProductData));
            this._changeRef.detectChanges();
        }
        else {
            this.workingHalfProductData = [];
        }
    }

    async toggleArticleActivity(article:any) {
        var toggleElement = $("#" + article.id.toString());
        var successText = article.isActive ? "_Article_deactivated_text" : "_Article_activated_text";
        var cancelText = article.isActive ? "_Deactivation_article_cancelled_text" : "_Activation_article_cancelled_text";
        var question_text = this.translate.instant(article.isActive ? "_Article_deactivation_question_text" : "_Article_activation_question_text");

        var self = this;
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "question",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-danger'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    var obj = new IdModel();
                    obj.id = article.id;

                    await self.articleRest.toggleActive(obj);
                    self.fetchArticles();
                    Swal.fire(self.translate.instant("_Success"), self.translate.instant(successText), 'success');
                }
                catch (err) {
                    Swal.fire(self.translate.instant("_Error"), err.error['Message'], 'danger')
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(self.translate.instant("_Warning"), self.translate.instant(cancelText), 'warning');
            }
        });

        var currentValue = toggleElement.prop('checked') as boolean;
        toggleElement.prop('checked', !currentValue);
    }

    resetForm() {
        this.formGroup.controls['name'].setValue("");
        this.formGroup.controls['code'].setValue("");
        this.formGroup.controls['description'].setValue("");
        this.formGroup.controls['addEditGroupId'].setValue(0);
        this.formGroup.controls['mpc'].setValue(0);
        this.formGroup.controls['nc'].setValue(0);
        this.formGroup.controls['vpc'].setValue(0);
        this.formGroup.controls['margin'].setValue(0);
        this.formGroup.controls['happyHourPrice'].setValue(0);
        this.formGroup.controls['addEditMeasureId'].setValue(0);
        this.formGroup.controls['minAmount'].setValue(0);
        this.formGroup.controls['isNormative'].setValue(false);
        this.formGroup.controls['shouldProduce'].setValue(false);
        this.formGroup.controls['addEditIsActive'].setValue(true);

        $('#article_add_groupId').val(null).trigger("change");
        $('#article_add_measureId').val(null).trigger("change");

        this.barcodesList = [];
        this.newBarcode = "";
        this.showBarcodeErrorAdd = false;
        $("#newBarcodeAdd").removeClass('is-invalid');

        $("#imagePreviewAdd").css('background-image', "url('/assets/media/svg/avatars/blank.svg')");
        this.selectedImage = undefined;
        this.base64Image = undefined;
        this.selectedArticle = undefined;

        this.getNextNumber();
    }

    createAddEditForm() {
        this.formGroup = this.formBuilder.group({
            name: ['', Validators.required],
            code: [''],
            description: [''],
            addEditGroupId: [0, Validators.required],
            mpc: [0],
            nc: [0],
            vpc: [0],
            margin: [0],
            happyHourPrice: [0],
            addEditMeasureId: [0, Validators.required],
            minAmount: [0],
            isNormative: [null, Validators.required],
            shouldProduce: [null, Validators.required],
            addEditIsActive: [null, Validators.required]
        })

        var self = this;
        $("#article_add_measureId").select2();
        $('#article_add_measureId').on("change", function () {
            self.selectAddMeasureId.nativeElement.value = $(this).val();
            self.addEditMeasureId = $(this).val();
        });

        $("#article_add_groupId").select2();
        var self = this;
        $('#article_add_groupId').on("change", function () {
            self.selectAddArticleGroupId.nativeElement.value = $(this).val();
            self.addEditGroupId = $(this).val();
        });

        $("#article_edit_measureId").select2();
        $('#article_edit_measureId').on("change", function () {
            self.selectEditMeasureId.nativeElement.value = $(this).val();
            self.addEditMeasureId = $(this).val();
        });

        $("#article_edit_groupId").select2();
        $('#article_edit_groupId').on("change", function () {
            self.selectEditArticleGroupId.nativeElement.value = $(this).val();
            self.addEditGroupId = $(this).val();
        });
    }

    diagnose() {
    }

    selectImageFromListView(id:number){
        this.uploadImageForArticleId=id;
        this.listViewImageUpload.nativeElement.click();
    }

    async editArticleImageFromListView(event: any){
        var file =null;
        if (event.target.files && event.target.files.length > 0) {
            file = event.target.files[0]; 
        }else {
            return;
        }

        try{
            await this.articleRest.addArticleImage(this.data.user.clientId, this.uploadImageForArticleId, file);
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Changed_image'), this.translate.instant("_Success"));
            this.fetchArticles();
        }
        catch (err) {
            if(err.status==200){
                toastr.options = this.data.toastrOptions;
                toastr.success(this.translate.instant('_Changed_image'), this.translate.instant("_Success"));
                this.fetchArticles();
            }
            else{
                toastr.options = this.data.toastrOptions;
                toastr.error(err.error['Message'], this.translate.instant("_Error"));
            }
        }
    }

    async addArticle() {
        this.isSubmitted = false;
        if (this.formGroup.invalid) {
            this.diagnose();
            this.isSubmitted = true;
        }

        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        var obj = this.createAddObject();

        try {
            var response=await this.articleRest.createArticle(obj);
            if(this.file!=null && this.file!=undefined)
                await this.articleRest.addArticleImage(obj.clientId, response, this.file)
            this.closeAddArticleModal.nativeElement.click();
            this.groupId = null;
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_article_text'), this.translate.instant("_Success"));
            this.fetchArticles();
        }
        catch (err) {
            if(err.status==200){
                this.closeAddArticleModal.nativeElement.click();
            this.groupId = null;
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_article_text'), this.translate.instant("_Success"));
            this.fetchArticles();
            }
            else{
                toastr.options = this.data.toastrOptions;
                toastr.error(err.error['Message'], this.translate.instant("_Error"));
            }
        }
    }

    validateAddForm() {
        var check = true;

        if (this.selectAddArticleGroupId.nativeElement.value == 0) {
            $("#article_add_groupId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#article_add_groupId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.selectAddMeasureId.nativeElement.value == 0) {
            $("#article_add_measureId").nextAll('span').first().addClass("is-invalid")
            check = false;
        }
        else {
            $("#article_add_measureId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    async editArticle() {
        this.isSubmitted = false;
        if (this.formGroup.invalid) {
            this.diagnose();
            this.isSubmitted = true;
        }

        if (!this.validateEditForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        var obj = this.createEditObject();

        try {
            await this.articleRest.updateArticle(obj);
            if(this.file!=null && this.file!=undefined)
                await this.articleRest.addArticleImage(obj.clientId, obj.id, this.file)
            this.closeEditArticleModal.nativeElement.click();
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Edited_article_text'), this.translate.instant("_Success"));
            this.fetchArticles();
        }
        catch (err) {
            if (err.status == 200) {
                this.closeEditArticleModal.nativeElement.click();
                toastr.options = this.data.toastrOptions;
                toastr.success(this.translate.instant('_Edited_article_text'), this.translate.instant("_Success"));
                this.fetchArticles();
            }
            else {
                toastr.options = this.data.toastrOptions;
                toastr.error(err.error['Message'], this.translate.instant("_Error"));
            }
        }
    }

    validateEditForm() {
        var check = true;

        if (this.selectEditArticleGroupId.nativeElement.value == 0) {
            $("#article_edit_groupId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#article_edit_groupId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.selectEditMeasureId.nativeElement.value == 0) {
            $("#article_edit_measureId").nextAll('span').first().addClass("is-invalid")
            check = false;
        }
        else {
            $("#article_edit_measureId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    addBarcode(inputId: string, addCheck: boolean) {
        if (this.validateBarcode(inputId, addCheck))
            this.barcodesList.push(this.newBarcode);
    }

    validateBarcode(inputId: string, addCheck: boolean) {
        if (this.newBarcode.toString().length < 12 || this.newBarcode.toString().length > 15) {
            $(inputId).addClass("is-invalid");
            addCheck ? this.showBarcodeErrorAdd = true : this.showBarcodeErrorEdit = true;
            addCheck ? this.barcodeErrorTextAdd = "Barcode must be between 12 and 15 digits" :
                this.barcodeErrorTextEdit = "Barcode must be between 12 and 15 digits";
            return false;
        }
        else {
            $(inputId).removeClass("is-invalid");
            addCheck ? this.showBarcodeErrorAdd = false : this.showBarcodeErrorEdit = false;
        }

        if (this.barcodesList.includes(this.newBarcode)) {
            $(inputId).addClass("is-invalid");
            addCheck ? this.showBarcodeErrorAdd = true : this.showBarcodeErrorEdit = true;
            addCheck ? this.barcodeErrorTextEdit = "Duplicate barcode" : this.barcodeErrorTextEdit = "Duplicate barcode";
            return false;
        }
        else {
            $(inputId).removeClass("is-invalid");
            addCheck ? this.showBarcodeErrorAdd = false : this.showBarcodeErrorEdit = false;
        }

        return true;
    }

    removeBarcode(b: string) {
        var removeIndex = this.barcodesList.indexOf(b);

        var dataAfterRemoval: string[] = [];
        for (let index = 0; index < this.barcodesList.length; index++) {
            if (index != removeIndex)
                dataAfterRemoval.push(this.barcodesList[index]);
        }

        this.barcodesList = dataAfterRemoval;
    }

    addNormative() {
        if (!this.validateNormativeForm())
            return;
        
        var checkExisting = this.workingNormativeData.find(x => x.articleId == this.articleForNormative.id);
        if (checkExisting != undefined) {
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant('_Duplicate_normative_text'), this.translate.instant("_Error"));
            return;
        }

        var newNormativeItem = new NormativeModel();
        newNormativeItem.amount = parseFloat(this.normativeAmount.toFixed(6));
        newNormativeItem.articleId = this.articleForNormative.id;
        newNormativeItem.name = this.articleForNormative.name;
        newNormativeItem.measure = this.articleForNormative.measureShort;
        newNormativeItem.nc = this.articleForNormative.nc;

        this.workingNormativeData.push(newNormativeItem);
        this.calculateNormativeTotal();
        this.setTotalAsNC();
        this.articleForNormative = undefined;

        $("#normative_add_id").val(null).trigger("change");
        this.normativeAmount = 0;
    }
    calculateNormativeTotal() {
        this.normativeTotal = this.workingNormativeData.reduce((accumulator, currentItem) => {
            return accumulator + parseFloat((currentItem.amount * currentItem.nc).toFixed(6));
        }, 0);
    }
    setTotalAsNC() {
        this.nc = this.normativeTotal;
        this.formGroup.controls.nc.setValue(this.nc);
    }
    validateNormativeForm() {
        var check = true;
        if ((this.articleForNormative == undefined || this.articleForNormative == null) && (this.editingNormativeItem==null && this.editingNormativeItem==undefined)) {
            check = false;
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant('_No_article_chosen'), this.translate.instant("_Error"));
        }
        if (this.normativeAmount == undefined || this.normativeAmount == 0) {
            check = false;
            $("#normativeAmount").addClass('is-invalid');
        } else {
            $("#normativeAmount").removeClass('is-invalid');
        }

        return check;
    }
    startEditingNormativeAmount(normative: NormativeModel) {
        this.editingNormativeItem = normative;
        this.hideTable();
        this.normativeSearch.nativeElement.value = "";
        $(this.normativeSearch.nativeElement).prop('disabled', true);
        this.articleForNormative = null;
        this.normativeAmount = normative.amount;
        $("#normativeAmount").removeClass('is-invalid');
    }
    cancelEditingNormativeAmount(){
        this.editingNormativeItem = undefined;
        this.resetNormativeForm();
    }
    updateNormativeAmount() {
        if (!this.validateNormativeForm())
            return;
        this.editingNormativeItem.amount = this.normativeAmount;
        this.editingNormativeItem = undefined;
        this.articleForNormative = undefined;
        this.resetNormativeForm();
        this.calculateNormativeTotal();
        this.setTotalAsNC();
    }
    deleteNormative(article: NormativeModel) {
        this.workingNormativeData.forEach((element, index) => {
            if (element == article) this.workingNormativeData.splice(index, 1);
            this.calculateNormativeTotal();
        });
        this.setTotalAsNC();
    }
    resetNormativeData() {
        if (this.selectedArticle != undefined && this.selectedArticle.isNormative) 
            this.workingNormativeData = JSON.parse(JSON.stringify(this.selectedArticle.normativeItems));
        else 
            this.workingNormativeData = [];
        this.calculateNormativeTotal();
        this.articleForNormative = undefined;
        this.editingNormativeItem = undefined;
        this.resetNormativeForm();
        this.setTotalAsNC();
    }
    resetNormativeForm() {
        this.normativeSearch.nativeElement.value = "";
        this.normativeSelection = JSON.parse(JSON.stringify(this.startingNormativeSelection));
        this.normativeAmount = 0;
        $("#normativeAmount").removeClass('is-invalid');
        $(this.normativeSearch.nativeElement).prop('disabled', false);
    }

    async editHalfProductData() {
        try {
            if (this.selectedArticle.halfProductData == null) {
                var response = await this.articleRest.getHalfProductData(this.selectedArticle.id);
                this.selectedArticle.halfProductData = response;
                this.workingHalfProductData = response;
            }

            this.editHalfProductDataTrigger.nativeElement.click();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant('_Fetch_half_product_fail'), this.translate.instant("_Error"));
        }
    }
    addHalfProduct() {
        if (!this.validateHalfProductForm())
            return;

        var checkExisting = this.workingHalfProductData.find(x => x.id == this.articleForHalfProduct.id);
        if (checkExisting != undefined) {
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant('_Duplicate_half_product_text'), this.translate.instant("_Error"));
            return;
        }

        var newHalfProductItem = new HalfProductModel();
        newHalfProductItem.quantity = parseFloat(this.halfProductAmount.toFixed(6));
        newHalfProductItem.id = this.articleForHalfProduct.id;
        newHalfProductItem.name = this.articleForHalfProduct.name;
        newHalfProductItem.measure = this.articleForHalfProduct.measureShort;
        newHalfProductItem.nc = this.articleForHalfProduct.nc;
        newHalfProductItem.mpc = this.articleForHalfProduct.mpc;
        newHalfProductItem.marginAmount = this.articleForHalfProduct.ruc;
        newHalfProductItem.marginPercentage = this.articleForHalfProduct.margin;
        newHalfProductItem.groupId = this.articleForHalfProduct.groupId;

        this.workingHalfProductData.push(newHalfProductItem);
        this.articleForHalfProduct = undefined;

        $("#halfProduct_add_id").val(null).trigger("change");
        this.halfProductAmount = 0;
    }
    validateHalfProductForm() {
        var check = true;
        if ((this.articleForHalfProduct == undefined || this.articleForHalfProduct == null) && (this.editingHalfProductItem == undefined || this.editingHalfProductItem == null)) {
            check = false;
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant('_No_article_chosen'), this.translate.instant("_Error"));
        }
        if (this.halfProductAmount == undefined || this.halfProductAmount == 0) {
            check = false;
            $("#halfProductAmount").addClass('is-invalid');
        } else {
            $("#halfProductAmount").removeClass('is-invalid');
        }

        return check;
    }
    startEditingHalfProductAmount(halfProduct: HalfProductModel) {
        this.editingHalfProductItem = halfProduct;
        this.hideTableHalfProduct();
        this.halfProductSearch.nativeElement.value = "";
        $(this.halfProductSearch.nativeElement).prop('disabled', true);
        this.articleForHalfProduct = null;
        this.halfProductAmount = halfProduct.quantity;
        $("#halfProductAmount").removeClass('is-invalid');
    }
    cancelEditingHalfProductAmount(){
        this.editingHalfProductItem = undefined;
        this.resetHalfProductForm();
    }
    updateHalfProductAmount() {
        if (!this.validateHalfProductForm())
            return;
        this.editingHalfProductItem.quantity = this.halfProductAmount;
        this.editingHalfProductItem = undefined;
        this.resetHalfProductForm();
    }
    deleteHalfProduct(article: HalfProductModel) {
        this.workingHalfProductData.forEach((element, index) => {
            if (element == article) this.workingHalfProductData.splice(index, 1);
        });
    }
    resetHalfProductData() {
        if (this.selectedArticle != undefined && this.selectedArticle.shouldProduce) 
            this.workingHalfProductData = JSON.parse(JSON.stringify(this.selectedArticle.halfProductData));
        else 
            this.workingHalfProductData = [];

        this.articleForHalfProduct = undefined;
        this.editingHalfProductItem = undefined;
        this.resetHalfProductForm();
    }
    resetHalfProductForm() {
        this.halfProductSearch.nativeElement.value = "";
        this.halfProductSelection = JSON.parse(JSON.stringify(this.startingHalfProductSelection));
        this.halfProductAmount = 0;
        $("halfProductAmount").removeClass('is-invalid');
        $(this.halfProductSearch.nativeElement).prop('disabled', false);
    }

    calculateMargin() {
        this.margin = this.mpc - this.nc;
        this.formGroup.controls.margin.setValue(this.margin.toFixed(2));
    }
    calculatePrices() {
        this.hh = this.mpc;
        this.formGroup.controls.happyHourPrice.setValue(this.hh.toFixed(2));

        if(this.addEditGroupId==null || this.addEditGroupId==undefined)
            return;

        var group = this.groups.find(x=>x.id==this.addEditGroupId);
        var taxToApply = this.taxes.find(x=>x.id==group.taxId).amount;
        if(taxToApply==0)
            this.vpc = this.mpc;
        else
            this.vpc = parseFloat((this.mpc * (1 - taxToApply / 100)).toFixed(6));
        this.formGroup.controls.vpc.setValue(this.vpc);
    }

    negateHalfProduct() {
        this.shouldProduce = false;
    }
    negateNormative() {
        this.isNormative = false;
    }

    onImageSelected(event: any) {
        var files = event.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();

            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);
        }
    }
    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64Image = btoa(binaryString);
    }

    onFileSelected(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            this.file = event.target.files[0]; // Prvi odabrani fajl
        }
    }

    createAddObject() {
        var obj = new ArticleModel();
        obj.barcodes = this.barcodesList;
        obj.clientId = this.data.user.clientId;
        obj.description = this.formGroup.controls.description.value;
        obj.groupId = this.formGroup.controls.addEditGroupId.value;
        obj.shouldProduce = this.formGroup.controls.shouldProduce.value;
        obj.isNormative = this.formGroup.controls.isNormative.value;
        if (obj.isNormative)
            obj.normativeItems = this.workingNormativeData;
        if (obj.shouldProduce)
            obj.halfProductData = this.workingHalfProductData;
        obj.measureId = this.formGroup.controls.addEditMeasureId.value;
        obj.name = this.formGroup.controls.name.value;
        obj.code = this.formGroup.controls.code.value;
        if (this.nextNumber.toString() != obj.code)
            obj.customCode = true;
        obj.isActive = this.formGroup.controls.addEditIsActive.value;
        obj.mpc = this.formGroup.controls.mpc.value == null ? 0 : this.formGroup.controls.mpc.value;
        obj.nc = this.formGroup.controls.nc.value == null ? 0 : this.formGroup.controls.nc.value;
        obj.vpc = this.formGroup.controls.vpc.value == null ? 0 : this.formGroup.controls.vpc.value;
        obj.happyHourPrice = this.formGroup.controls.happyHourPrice.value == null ? 0 : this.formGroup.controls.happyHourPrice.value;
        obj.minAmount = this.formGroup.controls.minAmount.value == null ? 0 : this.formGroup.controls.minAmount.value;
        obj.margin = this.formGroup.controls.margin.value == null ? 0 : this.formGroup.controls.margin.value;
        return obj;
    }

    uploadImage(picture) {
        if (this.selectedImage != null && this.selectedImage != undefined) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Image = reader.result?.toString();
                picture = base64Image;
            };
            reader.readAsDataURL(this.selectedImage);
        }
    }

    createEditObject() {
        var obj = new ArticleModel();
        obj.barcodes = this.barcodesList;
        obj.clientId = this.data.user.clientId;
        obj.description = this.formGroup.controls.description.value;
        obj.groupId = this.formGroup.controls.addEditGroupId.value;
        obj.groupTypeId = this.selectedArticle.groupTypeId;

        obj.shouldProduce = this.formGroup.controls.shouldProduce.value;
        obj.halfProductData = this.selectedArticle.halfProductData;
        obj.isNormative = this.formGroup.controls.isNormative.value;
        if (obj.isNormative)
            obj.normativeItems = this.workingNormativeData;
        if (obj.shouldProduce)
            obj.halfProductData = this.workingHalfProductData;
        obj.measureId = this.formGroup.controls.addEditMeasureId.value;
        obj.name = this.formGroup.controls.name.value;
        obj.code = this.formGroup.controls.code.value;
        if (this.nextNumber.toString() != obj.code)
            obj.customCode = true;
        obj.isActive = this.formGroup.controls.addEditIsActive.value;
        obj.fisNumber = this.selectedArticle.fisNumber;
        obj.ruc = this.selectedArticle.ruc;
        obj.id = this.selectedArticle.id;

        obj.mpc = this.formGroup.controls.mpc.value == null ? 0 : this.formGroup.controls.mpc.value;
        obj.nc = this.formGroup.controls.nc.value == null ? 0 : this.formGroup.controls.nc.value;
        obj.vpc = this.formGroup.controls.vpc.value == null ? 0 : this.formGroup.controls.vpc.value;
        obj.happyHourPrice = this.formGroup.controls.happyHourPrice.value == null ? 0 : this.formGroup.controls.happyHourPrice.value;
        obj.minAmount = this.formGroup.controls.minAmount.value == null ? 0 : this.formGroup.controls.minAmount.value;
        obj.margin = this.formGroup.controls.margin.value == null ? 0 : this.formGroup.controls.margin.value;

        return obj;
    }

    saveHalfProductData() {
        if (this.selectedArticle != undefined)
            this.selectedArticle.halfProductData = this.workingHalfProductData;
    }

    onKeydown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            event.preventDefault();
            this.closeAddModal();
        }
    }

    closeAddModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closeAddArticleModal.nativeElement.click();
                self.workingHalfProductData = [];
                self.workingNormativeData = [];
                self.normativeTotal = 0;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    closeEditModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closeEditArticleModal.nativeElement.click();
                self.workingHalfProductData = [];
                self.workingNormativeData = [];
                self.normativeTotal = 0;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchArticles();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchArticles();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchArticles();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchArticles();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchArticles();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}

