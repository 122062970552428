import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleRestService } from 'src/app/services/article.service';
import { CustomerSupplierRestService } from 'src/app/services/customerSupplier.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { OutgoingInvoiceRestService } from 'src/app/services/outgoing-invoice.service';
import { PaymentTypeRestService } from 'src/app/services/payment-type.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { GroupModel } from 'src/app/models/group.model';
import { TranslateService } from 'src/app/translate';
import { ArticleModel } from 'src/app/models/article.model';
import { TaxModel } from 'src/app/models/tax.model';
import { PaymentTypeModel } from 'src/app/models/paymentType.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { CustomerSuppliersModel } from 'src/app/models/customerSupplier.model';
import toastr from 'toastr';
import { OutgoingInvoiceModel } from 'src/app/models/documents/outgoingInvoice.model';
import { SoldArticleModel } from 'src/app/models/documents/soldArticle.model';
import { CurrencyModel } from 'src/app/models/currency.model';
import { CurrencyRestService } from 'src/app/services/currency.service';
import { InvoiceCustomerPaymentModel } from 'src/app/models/documents/invoice-customer-payment.model';
import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import { ReportRestService } from 'src/app/services/reports.service';
import { RecapitulationSignOffModel } from 'src/app/models/reports/recapitulationSignOffModel.model';
import { Router } from '@angular/router';
import { DocumentPdfComponent } from '../../documents/document-pdf/document-pdf.component';
import { SignOffArticlesByGroupModel } from 'src/app/models/reports/recapitulationSignedOffArticles.model';
import { SignOffRestService } from 'src/app/services/sign-off.service';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;

@Component({
  selector: 'app-recapitulation-sign-off-articles',
  templateUrl: './recapitulation-sign-off-articles.component.html',
})
export class RecapitulationSignOffArticlesComponent {
  public selectedRow: string = undefined;
  public signOffs: any[];
  public signOffTypes:string[];
  public currencies: CurrencyModel[];
  public warehouses: WarehouseModel[];
  public emptyData: boolean = false;
  public searchForm: UntypedFormGroup;
  public addForm: UntypedFormGroup;
  dateFrom: string;
  dateTo: string;
  public pdfSource: any;
  public isSubmitted: boolean;
  public selectedSignOff: RecapitulationSignOffModel;
  public selectedCurrency: string;
  public headerFormEnabled: boolean;
  tableSetUp: boolean = false;

  @ViewChild('headerTrigger') headerTrigger: ElementRef;
  @ViewChild('listTrigger') listTrigger: ElementRef;
  @ViewChild('firstHeaderForm') firstHeaderFormElement: ElementRef;

  public onHeaderTab: boolean = false;
  @ViewChild("selectFilterWarehouseId") selectFilterWarehouseId: ElementRef;
  public warehouseIds: number[];
  @ViewChild(DocumentPdfComponent) documentPdfComponent: DocumentPdfComponent;
  public showPdf:boolean=false;
  public signOffType:string='_Standard_sign_off';

  public articlesByGroup:SignOffArticlesByGroupModel[];
  public totalQuantity:number;
  public totalMPC:number;
  public totalNC:number;
  public resizeObserver: ResizeObserver;

  constructor(
      private reportRestService: ReportRestService,
      private signOffRest: SignOffRestService,
      private datePipe: DatePipe,
      private _changeRef: ChangeDetectorRef,
      public data: DataService,
      private formBuilder: UntypedFormBuilder,
      private translate: TranslateService,
      private warehouseRestService: WarehouseRestService,
      private router: Router
  ) {
  }

  ngOnInit(): void {
      KTMenu.createInstances();
      this.fetchSignOffs();
      this.getSignOffTypes();
      this.createSearchForm();
      this.fetchWarehouses();
  }

  async fetchWarehouses() {
      try {
          this.warehouses = await this.warehouseRestService.getWarehousesForClient(this.data.user.clientId);
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  async getSignOffTypes() {
    try {
        this.signOffTypes = await this.signOffRest.getTypes();
        this.signOffType=this.signOffTypes[0];
    }
    catch (err) {
        if (err.status == 401) {
            this.data.logout();
        }
    }
  }

  formatSelectValue(val:string){
    if(val=='')
        return null;
    var split = (val.split(':')[1]).trim();
    return parseInt(split);
  }

  async createSearchForm() {
      this.searchForm = this.formBuilder.group({
          filterWarehouseId: [null],
          signOffType: ['_Standard_sign_off'],
      })

      $("#filter_warehouseId").select2();
      $('#filter_warehouseId').on("change", function () {
          var values = $(this).val();
          self.warehouseIds = [];
          if (values != undefined && values.length > 0) {
              values.forEach(x => {
                  var val = self.formatSelectValue(x);
                  if(val!=null && !isNaN(val))
                      self.warehouseIds.push(val);
              });
          }
          self.fetchSignOffs();
      });
      $("#filter_signOff_type").select2();
      $('#filter_signOff_type').on("change", function () {
        if ($(this).val() == '')
            self.signOffType = null;
        else
            self.signOffType = $(this).val();
      });

      var start = new Date(new Date().getFullYear(), 0, 1);
      var end = new Date();
      var self = this;
      $("#kt_daterangepicker_filter").daterangepicker({
          startDate: start,
          endDate: end,
          autoApply: true,
          autoUpdateInput: true,
          locale: {
              format: 'DD/MM/YYYY',
              separator: '-'
          }
      });
      $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
          self.dateFrom = picker.startDate.format('YYYY-MM-DD');
          self.dateTo = picker.endDate.format('YYYY-MM-DD');
          self.fetchSignOffs();
      });
      $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
          picker.setStartDate(new Date());
          picker.setEndDate(new Date());
          self.dateFrom = picker.startDate.format('YYYY-MM-DD');
          self.dateTo = picker.endDate.format('YYYY-MM-DD');
          self.fetchSignOffs();
      });
      var im = new Inputmask("99/99/9999-99/99/9999");
      im.mask($("#kt_daterangepicker_filter"));
  }

  async resetFilter() {
      this.searchForm.reset();
      $("#filter_warehouseId").val(null).trigger("change");
      $("#filter_signOff_type").val('_Standard_sign_off').trigger("change");
      var pickerElement = $("#kt_daterangepicker_filter");
      var today = new Date();
      $(pickerElement).data('daterangepicker').setStartDate(new Date(today.getFullYear(), 0, 1, 0, 0 ,0));
      $(pickerElement).data('daterangepicker').setEndDate(new Date());
      $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
  }

  filterByDate(event: KeyboardEvent) {
      if (event.key == "Enter" || event.key == "Tab") {
          var pickerElement = $("#kt_daterangepicker_filter");
          $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
      }
  }

  async fetchSignOffPDF() {
    try{
        var response = await this.reportRestService.getRecapitulationsSignOffArticlesPDF(this.data.user.clientId, this.dateFrom, this.dateTo, this.warehouseIds, this.signOffType);
        this.pdfSource = window.URL.createObjectURL(response);
    }
    catch(err){
        toastr.options = this.data.toastrOptions;
        toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
    }
  }

  async exportPDF() {
    await this.fetchSignOffPDF();
        if (this.pdfSource){
            // this.router.navigate(['/recapitulation-incoming-pdf'], { queryParams: { pdfSrc: this.pdfSource } });
            this.showPdf=true;
            this.documentPdfComponent.setSource(this.pdfSource);
        }
    }
    closePDF(){
        this.showPdf=false;
    }


  async fetchSignOffs() {
      try {
          this.emptyData = false;
          if (this.dateFrom == undefined)
              this.dateFrom = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
          if (this.dateTo == undefined)
              this.dateTo = this.datePipe.transform((new Date()), 'yyyy-MM-dd');

          var response = await this.reportRestService.getRecapitulationSignOffArticles(this.data.user.clientId, this.warehouseIds, this.dateFrom, this.dateTo, this.signOffType);
          this.articlesByGroup = response.signedOffArticlesByGroupResponses;
          this.totalQuantity = response.totalQuantity;
          this.totalMPC = response.totalMPC;
          this.totalNC = response.totalNC;
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  async searchInvoices(customerName: string = '') {
      this.fetchSignOffs();
  }

  toggleDocNav(toggle: boolean) {
      this.onHeaderTab = toggle;
  }

  manualInputDate(id: string) {
      var pickerElement = $("#" + id);
      $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
  }

  selectOnFocus(id: string) {
      $("#" + id).select();
  }

  backToHeader() {
      $("#headerModalTrigger").click();
  }

  negateEnter(event: KeyboardEvent) {
      if (event.key == "Enter")
          event.preventDefault();
  }

  showList() {
      this.listTrigger.nativeElement.click();
  }
}