import { InvoiceCustomerPaymentModel } from "./invoice-customer-payment.model";
import { SoldArticleModel } from "./soldArticle.model";

export class OutgoingInvoiceModel{
    id:string;
    clientId:number;
    outgoingInvoiceNumber:string;
    documentNumber:number;
    customDocumentNumber:number;
    customerId:number;
    customerName:string;
    customerIDNumber:string;
    customerPDVNumber:string;
    customerAddress:string;
    createdAt:Date;
    billDate:Date;
    arrivalDate:Date;
    paymentTypeId:number;
    currencyId:number;
    warehouseId:number;
    taxId:number;
    fis:number;
    note:string;
    invoiceDiscount:number;
    totalAmount:number;
    totalBase:number;
    totalDiscount:number;
    totalTaxAmount:number;
    articles:SoldArticleModel[];
    paidOff:boolean;
    totalDebtAmount:number;
    totalPaidAmount:number;
    payments:InvoiceCustomerPaymentModel[];
}