<div class="modal fade" [id]="customModalId" tabindex="-1" aria-hidden="true" #modal>
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <div class="modal-header" id="kt_modal_select_invoice_item_header">
                <h2 class="fw-bolder" [hidden]="quickAdd">{{'_Select_article' | translate}}</h2>
                <h2 class="fw-bolder" [hidden]="!quickAdd">{{'_Add_article' | translate}}</h2>
                <div id="kt_modal_select_invoice_item_close" class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
                    <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="black" />
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                fill="black" />
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body py-10 px-lg-17" style="padding-left: 30px !important;padding-right: 30px !important;">
                <div id="lookupBodyWrapper">
                    <div class="fv-row mb-7 " [hidden]="quickAdd">
                        <div class="d-flex" style="justify-content: space-between;">
                            <input (change)="searchItems()" [(ngModel)]="searchItemCodeName" [ngModelOptions]="{standalone:true}" type="text" tabindex="1"
                            data-kt-supplier-table-filter="search" class="form-control form-control-solid w-250px ps-4" id="searchItemInput" #searchItemInput
                            placeholder="{{'_Search_articles' | translate}}" />
                            <button class="btn btn-primary" (click)="openQuickAdd()" tabindex="1" *ngIf="quickAddEnabled">{{'_Add_article'|translate}}</button>
                        </div>
                    </div>
                    <div class="fv-row mb-7" [hidden]="quickAdd">
                        <div class="table-window" style="border: dashed #eee 1px;">
                            <table class="table align-middle fs-6 gy-5">
                                <div class="bg-gray-200">
                                <thead class="scroll-items-header">
                                    <tr class="fs-5 fw-bold form-label" style="display: table;
                                    width: 100%;
                                    table-layout: fixed;">
                                        <th style="padding-left:20px;font-weight: bold;width:20%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">{{'_Code'|translate}}</th>
                                        <th style="font-weight: bold;width:20%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">{{'_Article_name'|translate}}</th>
                                        <th style="font-weight: bold;width:15%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">{{'_MPC'|translate}}</th>
                                        <th style="font-weight: bold;width:15%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">{{'_VPC'|translate}}</th>
                                        <th style="font-weight: bold;width:15%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">{{'_NC'|translate}}</th>
                                      <th style="font-weight: bold;width:15%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;" *ngIf="!noStates">{{'_State'|translate}}</th> 
                                    </tr>
                                </thead>
                                </div>
                                <div class="hover-scroll-overlay-y scroll-items" #itemSelectBody style=" margin-top: 0%;
                                padding-top: 0%;
                                max-height: 20em;
                                width: 100%;
                               overflow-y: scroll; ">
                                    <tbody *ngIf="!addingRaw">
                                        <tr *ngFor="let a of itemsToAdd;let r=index" tabindex="1"  class="fs-5 clickableRow" (click)="selectArticle(a)" (keydown)="enterOnRow($event)" data-bs-dismiss="modal" style="border-bottom: #eee dashed 1px !important;">
                                            <td style="padding-left:20px;width:20%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.code}}</td>
                                            <td style="width:20%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.name}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.mpc}} {{selectedCurrency}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.vpc}} {{selectedCurrency}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.nc}} {{selectedCurrency}}</td>
                                         <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;" *ngIf="!noStates">{{a.warehouseState}}</td> 
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="addingRaw">
                                        <tr *ngFor="let a of rawsToAdd;let r=index" tabindex="1" class="fs-5 clickableRow" (click)="selectArticle(a)" (keydown)="enterOnRow($event)" data-bs-dismiss="modal" style="border-bottom: #eee dashed 1px !important;">
                                            <td style="padding-left:20px;width:20%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.code}}</td>
                                            <td style="width:20%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.name}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.mpc}} {{selectedCurrency}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.vpc}} {{selectedCurrency}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.nc}} {{selectedCurrency}}</td>
                                          <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;" *ngIf="!noStates">{{a.warehouseState}}</td> 
                                        </tr>
                                    </tbody>
                                </div>
                            </table>
                            <br>
                        </div>
                    </div>
                    <div class="row fv-row mb-7" [hidden]="!quickAdd">
                        <div class="col-12 mb-2" style="background-color:rgb(242 242 242);border-radius:10px;">
                            <p class="fs-5 fw-bolder my-2">{{'_Quick_add_message'|translate}} <span data-bs-dismiss="modal" [routerLink]="['/articles']" style="cursor:pointer;font-weight:bold;color:rgb(103, 141, 255)">{{'_Articles' | translate}}</span> {{'_page.' | translate}}</p>
                        </div>
                        <form [formGroup]="quickAddForm">
                            <div class="col-12 mb-2">
                                <label class="fs-6 fw-bold mb-2">{{'_Article_Code' | translate}}</label>
                                <input type="text" class="form-control form-control-solid" placeholder="" 
                                    #firstQuickAddElement (keydown)="loopQuickAddForm($event, false)"
                                    name="code" value="" formControlName="code" tabindex="1" id="codeInput"
                                    [ngClass]="{ 'is-invalid': isSubmitted && f.code.errors }" />
                            </div>
                            <div class="col-12 mb-2">
                                <label class="required fs-6 fw-bold mb-2">{{'_Article_Name' |
                                    translate}}</label>
                                <input type="text" class="form-control form-control-solid" placeholder=""
                                    name="name" value="" formControlName="name" required tabindex="1"
                                    [ngClass]="{ 'is-invalid': isSubmitted && f.name.errors }" />
                            </div>
                            <div class="col-12 mb-2">
                                <label class="required form-label fs-6 fw-bold mb-2">{{'_Group' |
                                    translate}}:</label>
                                <select #selectAddArticleGroupId
                                    class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite grupu" tabindex="1"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="article_add_groupId"
                                    data-dropdown-parent="#lookupBodyWrapper"
                                    formControlName="addEditGroupId" [(ngModel)]="addEditGroupId"
                                    name="addEditGroupId">
                                    <option></option>
                                    <option *ngFor="let g of groups" [value]="g.id">{{g.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 mb-2 ">
                                <label class="required form-label fs-6 fw-bold mb-2">{{'_Measure' |
                                    translate}}:</label>
                                <select #selectAddMeasureId
                                    class="form-select form-select-solid fw-bolder" tabindex="1"
                                    data-placeholder="Odaberite jedinicu" data-allow-clear="true"
                                    data-kt-group-type-table-filter="month"
                                    id="article_add_measureId"
                                    data-dropdown-parent="#lookupBodyWrapper"
                                    formControlName="addEditMeasureId"
                                    [(ngModel)]="addEditMeasureId" name="addEditMeasureId">
                                    <option></option>
                                    <option *ngFor="let m of measures" [value]="m.id">{{m.short}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 mb-2">
                                <div class="row fv-row d-flex">
                                    <div class="col-4">
                                        <div class="fv-row mb-2">
                                            <label class="fs-6 fw-bold mb-2">{{'_MPC' | translate}}</label>
                                            <input type="number" min="0" class="form-control form-control-solid"
                                                placeholder="" name="mpc" value="" formControlName="mpc"
                                                [(ngModel)]="mpc" (change)="calculateMargin()"
                                                (change)="calculatePrices()" tabindex="1"
                                                [ngClass]="{ 'is-invalid': isSubmitted && f.mpc.errors }" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="fv-row mb-2">
                                            <label class="fs-6 fw-bold mb-2">{{'_NC' | translate}}</label>
                                            <input type="number" min="0" class="form-control form-control-solid"
                                                placeholder="" name="nc" value="" formControlName="nc"
                                                [(ngModel)]="nc" (change)="calculateMargin()" tabindex="1"
                                                [ngClass]="{ 'is-invalid': isSubmitted && f.nc.errors }" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="fv-row mb-2">
                                            <label class="fs-6 fw-bold mb-2">{{'_VPC' | translate}}</label>
                                            <input type="number" min="0" class="form-control form-control-solid"
                                                placeholder="" name="vpc" value="" formControlName="vpc"
                                                [(ngModel)]="vpc" (change)="calculateMargin()"
                                                (change)="calculatePrices()" tabindex="1"
                                                [ngClass]="{ 'is-invalid': isSubmitted && f.vpc.errors }" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-2">
                                <label class="fs-6 fw-bold mb-2">{{'_Bar_code' | translate}}</label>
                                <div [hidden]="barcodesList.length==0" class="d-flex px-6 flex-column">
                                    <table class="table align-middle table-row-dashed fs-6">
                                        <tbody>
                                            <tr *ngFor="let b of barcodesList">
                                                <td>{{b}}</td>
                                                <td><button type="button" class="btn btn-primary"
                                                        (click)="removeBarcode(b)">{{'_Remove'|translate}}</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row fv-row mb-2">
                                    <div class="col-md-6 fv-row">
                                        <input type="number" id="newBarcodeAdd" tabindex="1"
                                            (change)="validateBarcode('#newBarcodeAdd', true)"
                                            class="form-control form-control-solid" [(ngModel)]="newBarcode"
                                            [ngModelOptions]="{standalone:true}" />
                                        <p *ngIf="showBarcodeErrorAdd" style="color:red">
                                            {{barcodeErrorTextAdd|translate}}</p>
                                    </div>
                                    <div class="col-md-6 fv-row">
                                        <button type="button" class="btn btn-light-primary" tabindex="1"
                                            (click)="addBarcode('#newBarcodeAdd', true)">{{'_Add_barcode'|translate}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-center">
                                <button type="button" id="kt_modal_add_article_cancel" class="btn btn-light me-3"
                                    (click)="closeQuickAdd()">
                                    {{'_Discard' | translate}}</button>
                                <button type="button" id="kt_modal_add_article_submit" class="btn btn-primary"
                                    (click)="addArticle()" data-bs-dismiss="modal" tabindex="1"  
                                    (keydown)="loopQuickAddForm($event, true)" #saveArticleButton>
                                    <span class="indicator-label">{{'_Submit' | translate}}</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>