export const LANG_BS_NAME = 'bs';

export const LANG_BS_TRANS = {
    '_Login_text': 'Prijava na sistem',
    '_Username': 'Korisničko ime',
    '_Password':'Lozinka',
    '_Login':'Prijavite se',
    '_Error':'Greška',
    '_General_data':'Osnovni podaci',
    '_Countries':'Države',
    '_Home':'Početna',
    '_Add_country':'Dodaj državu',
    '_Country_name':'Naziv države',
    '_Actions':'Akcije',
    '_Edit':'Uredi',
    '_No_results':'Nema rezultata',
    '_No_results_text':'Čini se da ne možemo pronaći stavke koje odgovaraju vašem pretraživanju. Pokušajte prilagoditi pretraživanje ili filter da pronađete ono što tražite.',
    '_Submit':'Spremi',
    '_Discard':'Odbaci',
    '_Added_country_text':'Uspješno ste dodali novu državu!',
    '_Edit_country':'Uredi državu',
    '_Updated_country_text':'Uspješno ste uredili odabranu državu!',
    '_Towns':'Gradovi',
    '_Added_town_text':'Uspješno ste dodali novi grad!',
    '_Updated_town_text':'Uspješno ste uredili odabrani grad!',
    '_Add_town':'Dodaj grad',
    '_City_name':'Naziv grada',
    '_Country':'Država',
    '_Select_country':'Odaberite državu',
    '_Group_types':'Tipovi grupa',
    '_Add_group_type':'Dodaj tip grupe',
    '_Group_type_name':'Naziv tipa grupe',
    '_Article_Name':'Naziv artikla',
    '_Client_name':'Naziv klijenta',
    '_Filter_options':'Filter',
    '_Client':'Klijent',
    '_Articles':'Artikli',
    '_Groups':'Grupe',
    '_Dashboard':'Početna',
    '_User_management':'Upravljanje korisnicima',
    '_Admins':'Administratori',
    '_Clients':'Klijenti',
    '_Users':'Korisnici',
    '_Customers_suppliers':'Kupci i dobavljači',
    '_Reset':'Resetiraj',
    '_Apply':'Pretraži',
    '_Success':'Uspjeh',
    '_Edit_town':'Uredi grad',
    '_Added_group_type_text':'Uspješno ste dodali novi tip grupe!',
    '_Edit_group_type':'Uredi tip grupe',
    '_Updated_group_type_text':'Uspješno ste uredili odabrani tip grupe!',
    '_Company_name':'Kompanija',
    '_Address':'Adresa',
    '_Town':'Grad',
    '_Phone_number':'Telefon',
    '_Email':'Email',
    '_Pdv_number':'PDV broj',
    '_Price':'Cijena',
    '_Licence_expiration_date':'Istek licence',
    '_Is_active':'Status',
    '_Add_client':'Dodaj klijenta',
    '_Active':'Aktivan',
    '_Inactive':'Neaktivan',
    '_Point_of_sale':'Kase',
    '_Retail_place':'Poslovnice',
    '_Details':'Detalji',
    '_Search_clients':'Pretraži klijente',
    '_Date_from':'Datum od',
    '_Date_to':'Datum do',
    '_Client_deactivation_question_text':'Želite li doista deaktivirati odabranog klijenta? Njegov račun i pristup bit će obustavljeni!',
    '_Yes_text':'Da',
    '_No_text':'Ne',
    '_Client_deactivated_text':'Uspješno ste deaktivirali odabranog klijenta. Njegovi računi i pristup su obustavljeni!',
    '_Deactivation_client_cancelled_text':'Otkazali ste deaktiviranje odabranog klijenta. Radnja nije izvršena!',
    '_Warning':'Upozorenje',
    '_Client_activation_question_text':'Želite li doista aktivirati odabranog klijenta?',
    '_Client_activated_text':'Uspješno ste aktivirali odabranog klijenta. Njegovi računi i pristup su aktivirani!',
    '_Activation_cancelled_text':'Otkazali ste aktiviranje odabranog klijenta. Radnja nije izvršena!',
    '_Licence_information':'Informacije o licenci',
    '_Bank_information':'Informacije o banci',
    '_Order_number':'Broj računa',
    '_Number1':'Broj 1',
    '_Number2':'Broj 2',
    '_First_name':'Ime',
    '_Last_name':'Prezime',
    '_User_Role':'Korisnička uloga',
    '_Status':'Status',
    '_Add_admin':'Dodaj administratora',
    '_Group_name':'Naziv grupe',
    '_Add_group':'Dodaj grupu',
    '_Group_type':'Tip grupe',
    '_Added_group_text':'Uspješno ste dodali novu grupu!',
    '_Search_groups':'Pretraži grupe',
    '_Search_admins':'Pretraži administratore',
    '_Admin_role':'Administratorska uloga',
    '_Admin_deactivation_question_text':'Želite li doista deaktivirati odabranog administratora? Njegov račun i pristup bit će obustavljeni!',
    '_Admin_deactivated_text':'Uspješno ste deaktivirali odabranog administratora. Njegovi računi i pristup su obustavljeni!',
    '_Deactivation_admin_cancelled_text':'Otkazali ste deaktiviranje odabranog administratora. Radnja nije izvršena!',
    '_Admin_activation_question_text':'Želite li doista aktivirati odabranog administratora?',
    '_Admin_activated_text':'Uspješno ste aktivirali odabranog administratora. Njegovi računi i pristup su aktivirani!',
    '_Activation_admin_cancelled_text':'Otkazali ste aktiviranje odabranog administratora. Radnja nije izvršena!',
    '_Middle_name':'Srednje ime',
    '_Repeat_password':'Ponoviti lozinku',
    '_Added_admin_text':'Uspješno ste dodali novog administratora!',
    '_Updated_admin_text':'Uspješno ste uredili odabranog administratora!',
    '_Edit_admin':'Uredi administratora',
    '_Added_client_text':'Uspješno ste dodali novog klijenta!',
    '_Edit_client':'Uredi klijenta',
    '_Updated_client_text':'Uspješno ste uredili odabranog klijenta!',
    '_Select_all':'Odaberite sve',
    '_User_role':'Korisnička uloga',
    '_Add_user':'Dodaj korisnika',
    '_User_deactivation_question_text':'Želite li doista deaktivirati odabranog korisnika? Njegov račun i pristup bit će obustavljeni!',
    '_User_deactivated_text':'Uspješno ste deaktivirali odabranog korisnika. Njegovi računi i pristup su obustavljeni!',
    '_Deactivation_user_cancelled_text':'Otkazali ste deaktiviranje odabranog korisnika. Radnja nije izvršena!',
    '_User_activation_question_text':'Želite li doista aktivirati odabranog korisnika?',
    '_User_activated_text':'Uspješno ste aktivirali odabranog korisnika. Njegovi računi i pristup su aktivirani!',
    '_Activation_user_cancelled_text':'Otkazali ste aktiviranje odabranog korisnika. Radnja nije izvršena!',
    '_Edit_user':'Uredi korisnika',
    '_Search_users':'Pretraži korisnike',
    '_Retail_name':'Naziv poslovnice',
    '_Search_retail_places':'Pretraži poslovnice',
    '_Add_retail_place':'Dodaj poslovnicu',
    '_Client_management':'Upravljanje klijentima',
    '_Added_retailPlace_text':'Uspješno ste dodali novu poslovnicu!',
    '_Edit_retail_place':'Uredi poslovnicu',
    '_Updated_retailPlace_text':'Uspješno ste uredili odabranu poslovnicu!',
    '_Updated_user_text':'Uspješno ste uredili odabranog korisnika!',
    '_Added_user_text':'Uspješno ste dodali novog korisnika!',
    '_Search_towns':'Pretraži gradove',
    '_Search_countries':'Pretraži države',
    '_Add_point_of_sale':'Dodaj kasu',
    '_Point_of_sale_name':'Naziv kase',
    '_Is_mobile':'Is mobile',
    '_Added_pointOfSale_text':'Uspješno ste dodali novu kasu!',
    '_Quick_links':'Brzi pristup',
    '_Manage_clients':'Upravljanje klijentima',
    '_Manage':'Pregledaj',
    '_English':'Engleski',
    '_Bosnia_and_Herzegovine':'Bosanski',
    '_Cancel_question_text':'Da li ste sigurni da želite da zatvorite?',
    '_Yes_cancel_text':'Da, zatvori',
    '_No_return_text':'Ne, vrati se',
    '_Sign_out':'Odjava',
    'Language':'Jezik',
    'My profile':'Moj profil',
    '_Edit_point_of_sale':'Uredi kasu',
    '_Updated_pointOfSale_text':'Uspješno ste uredili odabranu kasu!',
    '_Search_point_of_sale':'Pretraži kase',
    '_Account_settings':'Postavke računa',
    '_Profile_details':'Detalji profila',
    '_First_Last_name':'Ime i prezime',
    '_Sign_in_method':'Login podaci',
    '_Discard_changes_question_text':'Da li ste sigurni da želite da odbacite promjene?',
    '_Yes_discard_text':'Da, odbaci',
    '_Updated_profile_text':'Uspješno ste uredili svoje korisničke podatke!',
    '_Add_article':'Dodaj artikal',
    '_Article_name':'Naziv artikla',
    '_Code':'Šifra',
    '_Group':'Grupa',
    '_Measure':'Jedinica',
    '_Description':'Opis',
    '_NC':'NC',
    '_MPC':'MPC',
    '_VPC':'VPC',
    '_RUC':'RUC',
    '_Margin':'Marža',
    '_Min_amount':'Minimalni iznos',
    '_Amount_article': 'Količina',
    '_Happy_hour_price':'Happy hour cijena',
    '_Is_visible':'Vidljivost',
    '_Visible':'Vidljiv',
    '_Hidden':'Skriven',
    '_Is_normative':'Potrebno normirati',
    '_Normative_data':'Podaci o normativima',
    '_Add_normative':'Dodaj normativ',
    '_Normative_name':'Naziv normativa',
    '_Is_half_product':'Poluproizvod',
    '_Bar_code':'Bar code',
    '_Client_data_management':'Upravljanje klijentskim podacima',
    '_Repeat_PIN':'Ponoviti PIN',
    '_Edit_group':'Uredi grupu',
    '_Tax':'Porez',
    '_Retail_place_name':'Naziv poslovnice',
    '_Article_active_changed':'Aktivni status artikla promijenjen.',
    '_Article_visibility_question_text':'Želite li promijeniti vidljivost artikla?',
    '_Article_visibility_changed':'Vidljivost artikla promijenjena.',
    '_Visibility_article_cancelled_text':'Promjena vidljivosti artikla poništena.',
    '_Visibility':'Vidljivost',
    '_Standardize':'Standardiziraj',
    '_Priority':'Prioritet',
    '_Remove':'Ukloni',
    '_Half_product_name':'Naziv poluproizvoda',
    '_Add_half_product':'Dodaj poluproizvod',
    '_Half_product_data':'Podaci o poluproizvodima',
    '_Added_article_text':'Uspješno ste dodali novi artikal!',
    '_Article_details':'Detalji artikla',
    '_Edit_article':'Uredi artikal',
    '_Edited_article_text':'Artikal promjenjen',
    '_Add_barcode':'Dodaj barkod',
    'Duplicate barcode':'Barkod već postoji',
    'Barcode must be between 12 and 15 digits':'Barkod mora imati između 12 i 15 znamenki',
    '_Article_deactivated_text':'Artikal je uspješno deaktiviran.',
    '_Article_activated_text':'Artikal je uspješno aktiviran',
    '_Deactivation_article_cancelled_text':'Deaktiviranje artikla je poništeno. Artikal je i dalje aktivan.',
    '_Activation_article_cancelled_text':'Aktivacija artikla je poništena. Artikal je i dalje neaktivan.',
    '_Article_deactivation_question_text':'Jeste li sigurni da želite deaktivirati artikal?',
    '_Article_activation_question_text':'Jeste li sigurni da želite aktivirati artikal?',
    '_Article_Code':'Šifra',
    '_Password_changed_text':'Šifra je promjenjena',
    '_PIN_changed_text':'Pin je promjenjen',
    '_Change_PIN':'Promjeni PIN',
    '_Customers':'Kupci',
    '_Search_customers':'Pretraži kupce',
    '_Add_customer':'Dodaj kupca',
    '_Edit_customer': 'Uredi kupca',
    '_ID_Number':'ID broj',
    '_Customer_name':'Ime kupca',
    '_Suppliers':'Dobavljači',
    '_Search_Suppliers':'Pretraži dobavljače',
    '_Add_Supplier':'Dodaj dobavljača',
    '_Edit_Supplier':'Uredi dobavljača',
    '_PDV_number':'PDV broj',
    '_Card_number':'Broj kartice',
    '_Name':'Naziv',
    '_CustomersAndSuppliers':'Kupci i dobavljači',
    '_Add_legal':'Dodaj pravno lice',
    '_Add_physical':'Dodaj fizičko lice',
    '_JMBG':'JMBG',
    '_Is_Supplier':'Dobavljač',
    '_Is_Customer':'Kupac',
    '_Phone':'Telefon',
    '_Employer':'Poslodavac',
    '_Documents':'Dokumenti',
    '_Retail_bills':'Maloprodajni računi',
    '_Bill_number':'Broj računa',
    '_Date':'Datum',
    '_Total':'Ukupno',
    '_Search_bills':'Pretraži po kupcu',
    '_Add_bill':'Dodaj račun',
    '_Total_price':'Ukupan iznos',
    '_Customer':'Kupac',
    '_Payment_type':'Način plaćanja',
    '_Payment_types':'Načini plaćanja',
    '_Add_payment_type':'Dodaj način plaćanja',
    '_Edit_payment_type':'Uredi način plaćanja',
    '_FIS_number':'FIS broj',
    '_Warehouse':'Skladište',
    '_Discount':'Popust',
    '_Header':'Zaglavlje',
    '_Items':'Stavke',
    '_Added_retail_bill_text':'Račun dodan',
    '_Bill_details':'Detalji računa',
    '_Outgoing_invoices':'Izlazne fakture',
    '_Search_by_customer':'Pretraži po kupcu',
    '_Bill_date':'Datum računa',
    '_Arrival_date':'Datum dospijeća',
    '_Outgoing_invoice_details':'Detalji izlazne fakture',
    '_Submit_document':'Spremi dokument',
    '_Discard_document':'Odbaci dokument',
    '_Add_items':'Dodaj stavke',
    '_Base_price':'Osnovica',
    '_Final_price':'Krajnja cijena',
    '_Cancel_question_text_document':'Zatvaranjem se dokument odbacuje. Jeste li sigurni da želite zatvoriti?',
    '_Back_to_header':'Povratak na zaglavlje',
    '_Add_outgoing_invoice':'Dodaj izlaznu fakturu',
    '_Edit_outgoing_invoice':'Uredi izlaznu fakturu',
    '_Deleted_outgoing_invoice_text':'Izlazna faktura izbrisana',
    '_Paid_off':'Plaćeno',
    '_Payments':'Uplate',
    '_Amount':'Iznos',
    '_Add_payment':'Dodaj uplatu',
    '_Note':'Bilješka',
    '_View':'Pregled',
    '_Select_customer':'Odaberi kupca',
    '_Add_header':'Dodaj zaglavlje',
    '_Select_article':'Odaberi artikal',
    '_Article_state':'Stanje artikla',
    '_Article_info':'Podaci o artiklu',
    '_Article':'Artikal',
    '_Back':'Natrag',
    '_Created_date':'Datum kreiranja',
    '_Invoice_discount':'Popust fakture',
    '_Invoice_number':'Br. fakture',
    '_Currency':'Valuta',
    '_Selected_customer':'Odabrani kupac',
    '_Selected_article':'Odabrani artikal',
    '_Adding_items_to_invoice_number':'Dodavanje stavki na fakturu br.',
    '_for_customer':'za kupca',
    '_Save_header':'Spremi zaglavlje',
    '_Save_items':'Spremi stavke',
    '_Edit_header':'Uredi zaglavlje',
    '_Edit_items':'Uredi stavke',
    '_Previous':'Prethodni',
    '_Next':'Sljedeći',
    '_Total_invoice_amount':'Ukupni iznos fakture',
    '_Paid_amount':'Plaćeni iznos',
    '_Save_item':'Spremi stavku',
    '_Add_item':'Dodaj stavku',
    '_Delete':'Izbriši',
    '_Delete_invoice_text':'Jeste li sigurni da želite izbrisati fakturu? Ovaj proces će se odraziti na skladište.',
    '_Save_changes':'Spremi promjene',
    '_Discard_added_items':'Odbaci nove stavke',
    '_Discard_item_changes':'Odbaci promjene stavke',
    '_Warehouses':'Skladišta',
    '_Search_by_name':'Pretraži po nazivu',
    '_Add_warehouse':'Dodaj skladište',
    '_Edit_warehouse':'Uredi skladište',
    '_Added_warehouse_text':'Skladište uspješno dodano',
    '_Updated_warehosue_text':'Skladište uspješno uređeno',
    '_Deleted_warehouse_transfer_text':'Međuskladišnica izbrisana',
    '_Taxes':'Porezi',
    '_Add_tax':'Dodaj porez',
    '_Edit_tax':'Uredi porez',
    '_PostDiscountPrice':'Fakturna cijena',
    '_Added_outgoing_invoice_text':'Dodali ste izlaznu fakturu',
    '_Edited_outgoing_invoice_text':'Promjene spremljene',
    '_Search_articles':'Pretraži artikle',
    '_Incoming_invoices':'Ulazne fakture',
    '_Add_incoming_invoice':'Dodaj ulaznu fakturu',
    '_Added_incoming_invoice_text':'Dodali ste ulaznu fakturu',
    '_Deleted_incoming_invoice_text':'Ulazna faktura izbrisana',
    '_Evidential_number':'Evidencijski broj',
    '_Incoming_invoice_number':'Broj ulazne fakture',
    '_for_supplier':'za dobavljača',
    '_Margin_amount':'Marža (iznos)',
    '_Unit_price':'Jed. cijena',
    '_Amount_without_tax':'Iznos bez poreza',
    '_Tax_amount':'Iznos poreza',
    '_Supplier':'Dobavljač',
    '_Select_supplier':'Odaberi dobavljača',
    '_Selected_supplier':'Odabrani dobavljač',
    '_Search_by_supplier':'Pretraži po dobavljaču',
    '_Search_suppliers':'Pretraži dobavljače',
    '_BUF':'BUF',
    '_Supplier_name':'Naziv dobavljača',
    '_Edited_incoming_invoice_text':'Uredili ste ulaznu fakturu',
    '_Reports':'Izvještaji',
    '_Warehouse_state':'Stanje skladišta',
    '_State':'Stanje',
    '_Incoming':'Ulazi',
    '_Outgoing':'Izlazi',
    '_Search_by_client':'Pretraga po klijentu',
    '_FIS':'FIS',
    '_Discounted_amount':'Iznos popusta',
    '_Exit_details':'Izlaz iz detalja',
    '_Remember_price_as':'Pamti cijenu kao',
    '_Average':'Prosjek',
    '_Last':'Zadnju',
    '_Affect_price':'Utiče na cijenu',
    '_Apply_invoice_prices':'Primjeni cijene',
    '_Prices_updated_text':'Cijene promjenjene',
    '_Entry_price':'Ulazna cijena',
    '_Invoice_amount_not_matching_text':'Iznos fakture se ne poklapa sa iznosom stavki!',
    '_Is_raw_group':'Grupa za sirovine',
    '_Updated_customer_text':'Uspješno ste uredili odabranog kupca.',
    '_Updated_warehouse_text':'Uspješno ste uredili odabrano skladište.',
    '_Updated_tax_text':'Uspješno ste uredili odabrani porez.',
    '_Added_tax_text':'Dodali ste novi porez.',
    '_Added_warehouse_transfer_text':'Dodali ste međuskladišnicu',
    '_Edited_warehouse_transfer_text':'Uredili se međuskladišnicu',
    '_Delete_warehouse_transfer_text': 'Jeste li sigurni da želite izbrisati međuskladišnicu? Ovaj proces će se odraziti na skladišta.',
    '_Warehouse_transfers':'Međuskladišnice',
    '_Warehouse_from':'Skladište razduženja',
    '_Warehouse_to':'Skladište zaduženja',
    '_Transfer_number':'Broj međuskladišnice',
    '_Add_warehouse_transfer':'Dodaj međuskladišnicu',
    '_Adding_items_to_warehouse_transfer_number':'Dodavanje stavki na međuskladišnicu br. ',
    '_Normative_data_singular':'Podaci o normativu',
    '_Add_normative_data':'Dodaj/pogledaj podatke o normativu',
    '_Edit_normative_data':'Uredi/pogledaj podatke o normativu',
    '_Add_text':'Dodaj',
    '_Quantity':'Količina',
    '_JM':'JM',
    '_NV':'NV',
    '_Set_as_NC':'Postavi kao NC',
    '_Set_price_message':'Cijena postavljena',
    '_Dispatch_notes':'Otpremnice',
    '_Add_dispatch_note':'Dodaj otpremnicu',
    '_DispatchNote_number':'Broj otpremnice',
    '_Affect_warehouse':'Utječe na skladište',
    '_Without_prices':'Bez cijena',
    '_Added_dispatch_note_text':'Uspješno ste dodali otpremnicu',
    '_Edited_dispatch_note_text':'Uspješno ste uredili otpremnicu',
    '_DispatchNote_discount':'Popust otpremnice',
    '_Delete_dispatch_note_text':'Želite li izbrisati otpremnicu?',
    '_Deleted_dispatch_note_text':'Otpremnica izbrisana',
    '_Duplicate_normative_text':'Sirovina je već dodana.',
    '_Production_documents':'Proizvodni nalozi',
    '_Production_document_number':'Br. proizvodnog naloga',
    '_Add_production_document':'Dodaj proizvodni nalog',
    '_Deleted_production_document_text':'Proizvodni nalog izbrisan',
    '_Adding_items_to_production_document_number':'Dodavanje stavki na proizvodni nalog br.',
    '_Add_product_to_produce':'Dodaj artikal za proizvesti',
    '_Article_to_produce':'Artikal za proizvesti',
    '_Article_raw':'Sirovina',
    '_Quantity_short':'Kol.',
    '_Add_raw':'Dodaj sastojak',
    '_Raw':'Sirovina',
    '_Selected_raw':'Odabrana sirovina',
    '_Add_half_product_data':'Dodaj/pogledaj podatke o poluproizvodima',
    '_Edit_half_product_data':'Uredi/pogledaj podatke o poluproizvodima',
    '_Duplicate_half_product_text':'Dupli poluproizvod',
    '_Fetch_normative_fail':'Dohvatanje podataka o normativu nije uspjelo',
    '_Fetch_half_product_fail':'Dohvatanje podataka o poluproizvodima nije uspjelo',
    '_Apply_text':'Primjeni',
    '_Stocktakings':'Inventure',
    '_Add_stocktaking':'Dodaj inventuru',
    '_Stocktaking_number':'Broj inventure',
    '_Add_sources':'Dodaj izvore',
    '_UI_Control':'Kontrola UI',
    '_Filter_groups':'Filter grupa',
    '_Added_stocktaking_text':'Inventura dodana',
    '_Delete_stocktaking_text':'Jeste li sigurni da želite izbrisati inventuru?',
    '_Added_sources_text':'Izvori dodani na inventuru',
    '_Real_state':'Stvarno stanje',
    '_VPV':'VPV',
    '_MPV':'MPV',
    '_Set_filters':'Postavi filtere',
    '_Edited_stocktaking_text':'Inventura uređena',
    '_Deleted_stocktaking_text':'Inventura izbrisana',
    '_Date_source':'Datum izvora',
    '_Hide_entered':'Sakrij unesene',
    '_Group_sort':'Sortiraj abecedno po grupi',
    '_Jump_last':'Fokus posljednji',
    '_Jump_not_included':'Posljednji promjeni artikal nije uključen u filterima',
    '_Control_updated_text':'Kontrola UI promjenjena',
    '_Standard_insert':'Standardni unos',
    '_Search_input':'Barkod unos',
    '_Barcode':'Barkod',
    '_Selected_barcode':'Unešeni barkod',
    '_Sources_timeline':'Vremena dodavanja izvora',
    '_Source':'Izvor',
    '_Time':'Vrijeme',
    '_Update_rs_failed':'Unos nije uspio za artikal ',
    '_Enter_barcode':'Unesite barkod',
    '_Error_logs':'Zapisi grešaka',
    '_Attempted_entry':'Neuspjeli unos',
    '_Recapitulation_Outgoing':'Rekapitulacija izlazne fakture',
    '_Recapitulation_Incoming':'Rekapitulacija ulazne fakture',
    '_Supply_amount' : 'Nabavni iznos',
    '_PDV':'PDV',
    '_Amount_with_tax':'Iznos sa PDV',
    '_VP_amount':'VP iznos',
    '_MP_amount':'MP iznos',
    '_Margin_amount_r':'Iznos marže',
    '_Invoice_price':'Fakturna cijena',
    '_NC_total':'NC ukupno',
    '_Document_number':'Br. dokumenta',
    '_Doc_number':'Br. dok',
    '_Recapitulation_Production':'Rekapitulacija dokumenta proizvodnje',
    '_Recapitulation_Transfer':'Rekapitulacija međuskladišnice',
    '_Recapitulation_Dispatch':'Rekapitulacija otpremnice',
    '_Recapitulations':'Rekapitulacije',
    '_NC_total_used':'NC ukupno utrošeno',
    '_VPC_total_used':'VP ukupno utrošeno',
    '_MPC_total_used':'MP ukupno utrošeno',
    '_NC_total_produced':'NC ukupno proizvedeno',
    '_VPC_total_produced':'VP ukupno proizvedeno',
    '_MPC_total_produced':'MP ukupno proizvedeno',
    '_Add':'Dodaj',
    '_Article_card':'Kartica artikla',
    '_Document':'Dokument',
    '_Customer/Supplier':'Kupac / Dobavljač',
    '_Enter_article_code':'Unesite šifru artikla',
    '_Sales_by':'Promet',
    '_Sales_by_article':'Promet po artiklu',
    '_Sales_by_operator':'Promet po operateru',
    '_Sales_by_operator_date':'Promet po operateru i datumu',
    '_Sales_with_discount':'Promet sa popustom',
    '_Sales_without_tax':'Promet bez popusta',
    '_Operator':'Operater',
    '_Sales_by_customer':'Promet po kupcu',
    '_Warehouse_discharge':'Razduženje skladišta',
    '_Total_all':'Ukupno NC za sve',
    '_Sales_by_payment':'Promet po načinu plaćanja',
    '_Shift_closings':'Zaključci smjene',
    '_Closing_number':'Br. zaključka',
    '_Closing_date':'Datum i vrijeme',
    '_Operator_name':'Ime operatera',
    '_Bill_number_from':'Od broja računa',
    '_Bill_number_to':'Do broja računa',
    '_Amount_by_payment_type':'Iznos po načinu plaćanja',
    '_Fiscalization_settings':'Postavke fiskalizacije',
    '_Updated_fiscal_config_text':'Postavke fiskalizacije uspješno promjenjene',
    '_Fiscal_type':'Fiskalni tip',
    '_Operator_code':'Šifra operatera',
    '_Fiscal_device_id':'ID fiskalnog uređaja',
    '_Fiscalize':'Fiskaliziraj',
    '_Storn':'Storniraj',
    '_Fiscalize_invoice_text':'Želite li fiskalizirati izlaznu fakturu?',
    '_Fiscalized_text':'Fiskalizacija uspješno obavljena',
    '_Request_failed_text':'Zahtjev nije uspio. Obratite se administratoru.',
    '_Fiscal_config_not_set':'Postavke fiskalizacije nisu podešene!',
    '_Storn_invoice_text':'Želite li stornirati izlaznu fakturu?',
    '_Storned_text':'Storniranje uspješno obavljeno',
    '_Create_stocktaking_difference':'Napravi inventurnu razliku',
    '_Created_stocktaking_difference':'Uspješno ste napravili inventurnu razliku',
    '_Confirm_stocktaking_diff_text':'Želite li napraviti inventurnu razliku?',
    '_All_states_text':'Sva stanja nisu unešena',
    '_Stocktaking_differences':'Inventurne razlike',
    '_Difference':'Razlika',
    '_Sources':'Izvori',
    '_Stocktaking_difference_number':'Broj inventurne razlike',
    '_Stocktaking_date':'Datum inventure',
    '_Stocktaking_difference_date':'Datum inventurne razlike',
    '_Warehouse_value':'Vrijednost skladišta',
    '_No_RS_entered':'Nijedno stvarno stanje nije unešeno',
    '_Print_fiscal_report':'Ispis fiskalnog izvješća',
    '_Fiscal_report':'Fiskalni report',
    '_Periodic_report':'Periodično izvješće',
    '_Current_state_report':'Izvješće trenutnog stanja',
    '_Daily_report':'Dnevno izvješće',
    '_Enter_dates':'Unesite datume',
    '_Money_deposit':'Polog novca',
    '_Money_withdrawal':'Podizanje novca',
    '_Print_duplicate_report':'Ispis duplikata izvješća',
    '_Fiscal_bill':'Fiskalni račun',
    '_Cancelled_fiscal_bill':'Poništeni fiskalni račun',
    '_Fiscal_reports':'Fiskalna izvješća',
    '_JMBG_13_chars':'JMBG treba imati 13 karaktera',
    '_ID_13_chars':'ID broj treba imati 13 karaktera',
    '_No_new_sources_selected':'Odaberite izvore za dodati',
    '_Updated_measure_text':'Mjerna jedinica promijenjena',
    '_Added_measure_text':'Mjerna jedinica dodana',
    '_Add_Measure':'Dodaj mjernu jedinicu',
    '_Short':'Kratica',
    '_Edit_Measure':'Uredi mjernu jedinicu',
    '_Measures':'Mjerne jedinice',
    '_Type':'Tip',
    '_Physical':'Fizičko lice',
    '_Legal':'Pravno lice',
    '_View_incoming_invoice':'Pregled ulaznih faktura',
    '_Apply_zero_profit_price':'Bez profita',
    '_Customer_doesnt_exist':'Kupac nije pronađen',
    '_Mode':'Način rada',
    '_Cash':'Gotovina',
    '_Vir':'Virman',
    '_Settings':'Postavke',
    '_Fiscalization':'Fiskaclizacija',
    '_Skip_after_MPC':'Pri dodavanju/uređivanju artikala preskoči sva polja nakon MPC',
    '_Updated_user_settings_text':'Opće postavke promjenjene',
    '_General_settings':'Opće postavke',
    '_Product_raws':'Definirane sirovine',
    '_VP_total':'VP ukupno',
    '_View_stocktaking_difference':'Pregled inventurnih razlika',
    '_View_stocktaking':'Pregled inventura',
    '_View_production_documents':'Pregled proizvodnih naloga',
    '_No_data':'Nema podataka za export u PDF',
    '_View_PDF':'Pregled PDF-a',
    '_Download':'Preuzmi dokument',
    '_View_outgoing_invoice':'Pregled izlaznih faktura',
    '_View_warehouse_transfers':'Pregled međuskladišnica',
    '_View_dispatch_notes':'Pregled otpremnice',
    '_View_PDF_without_prices':'Pregled PDF-a (bez cijena)',
    'No data to showcase':'Nema podataka za prikaz',
    '_View_Dispatch_Note_From_Outgoing_PDF':'Pregled otpremnice iz izlazne fakture',
    '_Add_product':'Dodaj proizvod',
    '_Print':'Print',
    '_Supplier_not_selected':'Dobavljač nije odabran',
    '_Invalid_code':'Nevaljana šifra',
    '_Document_type':'Tip dokumenta',
    '_Most_sold_articles':'Najprodavaniji artikli',
    '_Most_sold_articles_description':'Najprodavaniji artikli u odabranom periodu',
    '_Techical_support':'Tehnička podrška',
    '_Back_to_general_info':'Natrag na opće informacije',
    '_Reset_normative_data':'Resetiraj podatke o normativu',
    '_No_article_chosen':'Artikal nije odabran',
    '_Editing_article':'Uređivanje artikla',
    '_Reset_half_product_data':'Resetiraj podatke o poluproizvodima',
    '_Failed_to_add_item':'Dodavanje stavke nije uspjelo',
    '_Mark_items_for_price_change':'Označite stavke čiju cijenu želite primjeniti',
    '_Apply_production_prices':'Primjeni cijene',
    '_Quantity_must_be_greater_than_zero':'Količina mora biti veća od 0',
    'INCINV':'Ulazna faktura',
    'OUTINV':'Izlazna faktura',
    'DISPNT':'Otpremnica',
    'WHTRANS':'Međuskladišnica',
    'PROD':'Proizvodni nalog',
    'RETAIL':'MP račun',
    'STOCKDIFF':'Inv. razlika',
    '_Min_amount_WH':'Minimalna količina na skladištu',
    '_Sign_off':'Otpis robe',
    '_Sign_offs':'Otpisi robe',
    '_Add_sign_off':'Dodaj otpis',
    '_Standard_sign_off':'Standardni otpis',
    '_Occasion_sign_off':'Prigoda otpis',
    '_Daily_sign_off':'Dnevna ponuda otpis',
    '_Sign_off_number':'Br. otpisa',
    '_Sign_off_date':'Datum otpisa',
    '_Sign_off_type':'Tip otpisa',
    '_Adding_items_to_sign_off_number':'Dodavanje stavki na otpis br.',
    '_View_sign_off':'Pregled otpisa',
    '_Total_NC':'Ukupno NC',
    '_Total_MPC':'Ukupno MPC',
    '_Total_VPC':'Ukupno VPC',
    '_Delete_signOff_text':'Jeste li sigurni da želite izbrisati otpis?',
    '_Added_signOff_text':'Otpis robe uspješno dodan',
    '_Edited_signOff_text':'Otpis robe uspješno uređen',
    '_Deleted_signOff_text':'Otpis robe izbrisan',
    '_Cant_add_duplicate_products_to_production_document':'Dodavanje duplih proizvoda nije dozvoljeno.',
    '_Cant_add_duplicate_raws_to_production_document':'Dodavanje duplih sastojaka na proizvod nije dozvoljeno.',
    '_Outgoing_PDV':'Izlazni PDV',
    '_Recapitulation_Sign_off':'Rekapitulacija otpisa',
    '_Predefined_data':'Podaci su predefinirani',
    '_Apply_NC':'Primjeni NC',
    '_Source_history':'Povijest izvora',
    '_Edit_image':'Promjeni sliku',
    '_Changed_image':'Slika uspješno promijenjena',
    '_Total_NV':'Ukupna nabavna vrijednost',
    '_No_articles_fiscalize_text':'Faktura mora sadrzavati artikle',
    '_Payment_info_updated':'Podaci o uplatama su uspješno ažurirani',
    '_Recapitulation_Sign_off_articles':'Rekapitulacija otpisanih artikala',
    '_Total_quantity':'Ukupna količina',
    '_Payment_big_error':'Plaćeni iznos ne može biti veći od duga',
    '_Remaining_debt':'Preostali dug',
    '_Quick_add_message':'Info: Ovo je brzo kreiranje novog artikla, za više opcija idite na ',
    '_page.':'stranicu.',
}   

