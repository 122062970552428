import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleRestService } from 'src/app/services/article.service';
import { CustomerSupplierRestService } from 'src/app/services/customerSupplier.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { OutgoingInvoiceRestService } from 'src/app/services/outgoing-invoice.service';
import { PaymentTypeRestService } from 'src/app/services/payment-type.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { GroupModel } from 'src/app/models/group.model';
import { TranslateService } from 'src/app/translate';
import { ArticleModel } from 'src/app/models/article.model';
import { TaxModel } from 'src/app/models/tax.model';
import { PaymentTypeModel } from 'src/app/models/paymentType.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { CustomerSuppliersModel } from 'src/app/models/customerSupplier.model';
import toastr from 'toastr';
import { OutgoingInvoiceModel } from 'src/app/models/documents/outgoingInvoice.model';
import { SoldArticleModel } from 'src/app/models/documents/soldArticle.model';
import { minValidator } from 'src/app/validators/minValidator';
import { CurrencyModel } from 'src/app/models/currency.model';
import { CurrencyRestService } from 'src/app/services/currency.service';
import { InvoiceCustomerPaymentModel } from 'src/app/models/documents/invoice-customer-payment.model';
import { OutgoingInvoiceSaveModel } from 'src/app/models/documents/pageSaveModels/outgoingInvoiceSave.model';
import { UpdateInvoicePaymentDTO } from 'src/app/models/updateInvoicePaymentDTO';
import { UpdateInvoiceDiscountDTO } from 'src/app/models/documents/updateInvoiceDiscountDTO';
import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import { ItemLookupComponent } from '../item-lookup/item-lookup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import * as focusTrap from 'focus-trap';
import { DocumentPdfComponent } from '../document-pdf/document-pdf.component';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;

@Component({
    selector: 'app-outgoing-invoice',
    templateUrl: './outgoing-invoice.component.html',
})
export class OutgoingInvoiceComponent implements OnInit {
    public selectedRow: string = undefined;
    public outgoingInvoices: OutgoingInvoiceModel[];
    public groups: GroupModel[];
    public customers: CustomerSuppliersModel[];
    public taxes: TaxModel[];
    public currencies: CurrencyModel[];
    public paymentTypes: PaymentTypeModel[];
    public warehouses: WarehouseModel[];
    public retailPlaces: RetailPlaceModel[];
    public emptyData: boolean = false;
    public searchForm: UntypedFormGroup;
    public addForm: UntypedFormGroup;
    dateFrom: string;
    dateTo: string;
    customerName: string = '';
    public isSubmitted: boolean;
    public selectedInvoice: OutgoingInvoiceModel;
    public selectedInvoiceTax: TaxModel;
    public selectedInvoicePaymentType: PaymentTypeModel;
    public selectedInvoiceWarehouse: WarehouseModel;
    public addCustomerId: number = 0;
    public addPaymentTypeId: number = 0;
    public addWarehouseId: number = 0;
    public addCurrencyId: number = 0;
    public addBillDate: Date = new Date();
    public addArrivalDate: Date = new Date();
    public addPaidOff: boolean = false;
    public searchCustomerName: string;
    public filterRetailPlaceId: number;
    public filterWarehouseId: number;
    public customerSubset: CustomerSuppliersModel[];
    public selectedCustomer: CustomerSuppliersModel;
    public headerForm: UntypedFormGroup;
    get hForm() { return this.headerForm.controls; }

    public headerFormEnabled: boolean;
    public itemFormEnabled: boolean;

    public newPaymentAmount: number = 0;
    public newPaymentDate: Date = new Date();
    public newPayments: InvoiceCustomerPaymentModel[] = [];

    public creatingInvoice: OutgoingInvoiceModel;
    public activeInvoicePreEdit:OutgoingInvoiceModel;
    public selectedArticle: ArticleModel;
    public totalAmount: number = 0;
    tableSetUp: boolean = false;
    public addTaxId: number = 0;
    public basePrice: number;
    public basePriceDisplay: number;
    public triggerFinal: boolean;
    public finalPrice: number;
    public postDiscountPrice: number;
    public invoiceDiscount: number;
    public addItemForm: UntypedFormGroup;
    public selectedCurrency: string;
    get iForm() { return this.addItemForm.controls; }
    selectionModalTimer: any;
    enterCount: number = 0;
    creationInProgress: boolean = false;
    public previousWarehouseId: number;

    public paymentsToAdd: InvoiceCustomerPaymentModel[];
    public paymentsForm: UntypedFormGroup;
    get pForm() { return this.paymentsForm.controls; }

    @ViewChild('closeAddInvoiceHeaderModal') closeAddInvoiceHeaderModal: ElementRef;
    @ViewChild('closeAddInvoiceItemsModal') closeAddInvoiceItemsModal: ElementRef;
    @ViewChild('closeEditInvoiceHeaderModal') closeEditInvoiceHeaderModal: ElementRef;
    @ViewChild('closeEditInvoiceItemsModal') closeEditInvoiceItemsModal: ElementRef;
    @ViewChild('selectAddInvoicePaymentTypeId') selectAddInvoicePaymentTypeId: ElementRef;
    @ViewChild('selectAddInvoiceCurrencyId') selectAddInvoiceCurrencyId: ElementRef;
    @ViewChild('selectAddInvoiceWarehouseId') selectAddInvoiceWarehouseId: ElementRef;
    @ViewChild('selectTaxId') selectTaxId: ElementRef;
    @ViewChild('selectEditInvoiceCustomerId') selectEditInvoiceCustomerId: ElementRef;
    @ViewChild('selectEditInvoicePaymentTypeId') selectEditInvoicePaymentTypeId: ElementRef;
    @ViewChild('selectEditInvoiceCurrencyId') selectEditInvoiceCurrencyId: ElementRef;
    @ViewChild('selectEditInvoiceWarehouseId') selectEditInvoiceWarehouseId: ElementRef;
    @ViewChild('selectEditTaxId') selectEditTaxId: ElementRef;
    @ViewChild('closeSelectItemModal') closeSelectItemModal: ElementRef;
    @ViewChild('openFinalPriceModal') openFinalPriceModal: ElementRef;
    @ViewChild('closeFinalPriceModal') closeFinalPriceModal: ElementRef;
    @ViewChild('headerTrigger') headerTrigger: ElementRef;
    @ViewChild('itemsTrigger') itemsTrigger: ElementRef;
    @ViewChild('listTrigger') listTrigger: ElementRef;
    @ViewChild('closeInvoiceDiscountModal') closeInvoiceDiscountModal: ElementRef;
    @ViewChild('invoiceNumberOpen') invoiceNumberOpen: ElementRef;
    @ViewChild('invoiceNumberClose') invoiceNumberClose: ElementRef;
    @ViewChild('firstHeaderForm') firstHeaderFormElement: ElementRef;
    @ViewChild('itemCodeInput') itemCodeInput: ElementRef;
    @ViewChild('amountInput') amountInput: ElementRef;
    @ViewChild('invoiceDiscountInput') invoiceDiscountInput: ElementRef;
    @ViewChild('invoiceNumberInput') invoiceNumberInput: ElementRef;
    @ViewChild('finalPriceInput') finalPriceInput: ElementRef;
    @ViewChild('closePaymentsModal') closePaymentsModal: ElementRef;
    @ViewChild('selectFilterRetailPlaceId') selectFilterRetailPlaceId: ElementRef;
    @ViewChild('selectFilterWarehouseId') selectFilterWarehouseId: ElementRef;
    @ViewChild('addItemsButton') addItemsButton: ElementRef;
    @ViewChild('saveEditItemButton') saveEditItemButton: ElementRef;
    @ViewChild(ItemLookupComponent) itemLookupComponent: ItemLookupComponent;
    @ViewChild('itemsTab') itemsTab: ElementRef;
    @ViewChild('headerTab') headerTab: ElementRef;
    @ViewChild('customerModal') customerModal: ElementRef;
    @ViewChild('trapDistract') trapDistract: ElementRef;
    @ViewChild('saveHeaderAddButton') saveHeaderAddButton: ElementRef;
    @ViewChild('saveHeaderEditButton') saveHeaderEditButton: ElementRef;
    @ViewChild('addItemButton') addItemButton: ElementRef;
    @ViewChild('loopPrevious') loopPrevious: ElementRef;    
    @ViewChild('documentPdfComponent') documentPdfComponent: DocumentPdfComponent;
    @ViewChild('documentPdfAsDispatchComponent') documentPdfAsDispatchComponent: DocumentPdfComponent;
    public headerTrap:focusTrap.FocusTrap;
    itemTrap:focusTrap.FocusTrap;
    customerModalTrap:focusTrap.FocusTrap;
    shouldActivateTrap:boolean=false;

    public showPdf:boolean=false;
    public showPdfAsDispatch:boolean=false;

    public showItems: boolean = false;
    public setUpComplete: boolean = false;
    public editEnabled: boolean = false;
    public startedEditing: boolean = false;
    public showCustomerError: boolean = false;
    public nextNumber: number;
    public addEnabled: boolean = false;
    public changedArrivalDate: boolean = false;
    public headerSaved: boolean = false;
    public editingItems: boolean = false;
    public newInvoiceActive: boolean = false;
    public totalPaymentsAmount: number = 0;
    public totalDebtAmount: number = 0;
    public editingItem: SoldArticleModel;
    public editingItemIndex: number;
    public documentArticles: ArticleModel[];
    public onHeaderTab: boolean = false;
    public pdfSource: any;
    public pdfSource2: any;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;
    public resizeObserver: ResizeObserver;
    public passedInvoice:OutgoingInvoiceModel;

    public shiftPressed:boolean=false;

    constructor(
        private outgoingInvoiceRest: OutgoingInvoiceRestService,
        private articleRestService: ArticleRestService,
        private groupRestService: GroupRestService,
        private customerRestService: CustomerSupplierRestService,
        private paymentTypeRestService: PaymentTypeRestService,
        private warehouseRestService: WarehouseRestService,
        private currencyRestService: CurrencyRestService,
        private datePipe: DatePipe,
        private _changeRef: ChangeDetectorRef,
        public data: DataService,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    async ngOnInit() {
        KTMenu.createInstances();
        this.createSearchForm();
        this.createHeaderForm();
        this.createPaymentAndDiscountForm();
        this.createAddItemForm();

        await this.fetchInvoices();
        await this.fetchTaxes();
        await this.fetchGroups();
        await this.completeHeaderSetup();
       
    }

    async completeHeaderSetup(){
        await this.fetchHeaderDropdownData();
        
        Object.keys(this.headerForm.controls).forEach(name => {
            let control = this.headerForm.get(name);

            control.valueChanges.subscribe(value => this.updateCreatingInvoice(name, value));
        })

        var self = this;    
        this.route.queryParams.subscribe(params => {
            var paramInvoice = params['passedInvoice'];
            this.passedInvoice = JSON.parse(paramInvoice);
            // if(this.passedInvoice!=undefined)
                setTimeout(this.selectPassedInvoice, 100, self);
        });
    }

    async fetchHeaderDropdownData(){
        await this.fetchCustomers();
        await this.fetchCurrencies();
        await this.fetchPaymentTypes();
        await this.fetchWarehouses();
    }
    selectPassedInvoice(self:any){
        console.log(self.passedInvoice);

            if(self.passedInvoice!=undefined)
                self.selectInvoice(self.passedInvoice);

            // else {
            //     var test = new OutgoingInvoiceModel();
            //     test.id= "1310910678084620288";
            //     test.outgoingInvoiceNumber= "41/24";
            //     test.documentNumber= 41;
            //     test.customerId= 77;
            //     test.customerName= "test dobavljac";
            //     test.customerAddress= "test adrresa 26";
            //     test.customerIDNumber= "5467890'";
            //     test.customerPDVNumber="";
            //     test.billDate=new Date("2024-11-26T11:10:58.301+01:00");
            //     test.createdAt=new Date('2024-11-26T11:11:01.183+01:00');
            //     test.arrivalDate=new Date("2024-12-26T00:00:00+01:00");
            //     test.paymentTypeId= 7;
            //     test.currencyId= 1;
            //     test.taxId= 0;
            //     test.warehouseId=26;
            //     test.fis= 0;
            //     test.totalAmount= 0.0;
            //     test.totalBase= 0.0;
            //     test.totalDiscount= 0.0;
            //     test.totalTaxAmount= 0.0;
            //     test.note= null;
            //     test.invoiceDiscount= 0.0;
            //     test.articles= [];
            //     test.paidOff=false;
            //     test.payments= [];
            //     test.totalDebtAmount= 0.0;
            //     test.totalPaidAmount= 0.0;
            //     self.selectInvoice(test);
            // }
    }

    @HostListener('document:keydown', ['$event'])
    checkForShiftPress(event: KeyboardEvent) {
      if(event.key=='Shift'){
        this.shiftPressed=true;
      }
    }
    @HostListener('document:keyup', ['$event'])
    checkForShiftRelease(event: KeyboardEvent) {
      if(event.key=='Shift'){
        this.shiftPressed=false;
      }
    }
    public loopHeaderTab(event: KeyboardEvent, lastElement:boolean){
        if(lastElement){
            if(event.key=='Tab' && !this.shiftPressed){
                event.preventDefault();
                this.firstHeaderFormElement.nativeElement.focus();
            }
        }else{
            if(event.key=='Tab' && this.shiftPressed){
                event.preventDefault();
                if(this.startedEditing)
                    this.saveHeaderEditButton.nativeElement.focus();
                else
                    this.saveHeaderAddButton.nativeElement.focus();
            }
        }
    }
    public loopItemTab(event: KeyboardEvent, lastElement:boolean, potentialFirst:boolean=false){
        if(lastElement){
            if(event.key=='Tab' && !this.shiftPressed){
                event.preventDefault();
                if(this.editingItem==null || this.editingItem==undefined)
                    this.itemCodeInput.nativeElement.focus();
                else 
                    this.amountInput.nativeElement.focus();
            }
        }else{
            if(event.key=='Tab' && this.shiftPressed){
                if(potentialFirst){
                    if(this.editingItem!=null && this.editingItem!=undefined){
                        event.preventDefault();
                        this.saveEditItemButton.nativeElement.focus();
                    }
                }else{
                    event.preventDefault();
                    if(this.editingItem==null || this.editingItem==undefined)
                        this.addItemButton.nativeElement.focus();
                    else 
                        this.saveEditItemButton.nativeElement.focus();
                }
            }
        }
    }
    continueItemLoop(){
        if(!this.shiftPressed){

            if(this.editingItem==null)
                this.addItemButton.nativeElement.focus();
            else 
                this.saveEditItemButton.nativeElement.focus();
        }else{
            this.loopPrevious.nativeElement.focus();
        }
    }

    ngAfterViewInit() {       
        $(this.headerTrigger.nativeElement).on('shown.bs.tab', () => {
            this.firstHeaderFormElement.nativeElement.focus();
        });

        $(this.itemsTrigger.nativeElement).on('shown.bs.tab', () => {
            if(this.editingItem==null)
                this.itemCodeInput.nativeElement.focus();
            else 
                this.amountInput.nativeElement.focus();
        });

       
        $("#kt_modal_invoice_number").on('shown.bs.modal', () => {
            this.invoiceNumberInput.nativeElement.focus();
        });
        $("#kt_modal_invoice_number").on('hidden.bs.modal', () => {
            this.firstHeaderFormElement.nativeElement.focus();
        });

        $("#kt_modal_select_invoice_customer").on('shown.bs.modal', () => {
            $("#searchCustomerInput").focus();
        });
        $("#kt_modal_select_invoice_customer").on('hidden.bs.modal', () => {
            this.refreshCustomerLookup();
            this._changeRef.detectChanges();
        });

        $("#kt_modal_invoice_discount").on('shown.bs.modal', () => {
            this.invoiceDiscount = this.creatingInvoice.invoiceDiscount;
            this.invoiceDiscountInput.nativeElement.focus();
        });

        $("#kt_modal_final_price").on('shown.bs.modal', () => {
            this.finalPrice = undefined;
            this.finalPriceInput.nativeElement.focus();
        });
        $("#kt_modal_final_price").on('hidden.bs.modal', () => {
            if (this.finalPrice == undefined)
                this.finalPrice = 0;
            $("#discount1").focus();
        });
    }

    async createSearchForm() {
        this.searchForm = this.formBuilder.group({
            filterRetailPlaceId: [null],
            filterWarehouseId: [null]
        })

        $("#invoice_filter_warehouseId").select2();
        $('#invoice_filter_warehouseId').on("change", function () {
            self.selectFilterWarehouseId.nativeElement.value = $(this).val();
            self.filterWarehouseId = $(this).val();
        });

        $("#invoice_filter_retailPlaceId").select2();
        $('#invoice_filter_retailPlaceId').on("change", function () {
            self.selectFilterRetailPlaceId.nativeElement.value = $(this).val();
            self.filterRetailPlaceId = $(this).val();
        });

        var start = new Date(new Date().getFullYear(), 0, 1);
        var end = new Date();
        var self = this;
        $("#kt_daterangepicker_filter").daterangepicker({
            startDate: start,
            endDate: end,
            autoApply: true,
            autoUpdateInput: true,
            locale: {
                format: 'DD/MM/YYYY',
                separator: '-'
            }
        });
        $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            $("#searchByCustomerInput").focus();
            self.fetchInvoices();
        });
        $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
            picker.setStartDate(new Date());
            picker.setEndDate(new Date());
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            self.fetchInvoices();
        });
        var im = new Inputmask("99/99/9999-99/99/9999");
        im.mask($("#kt_daterangepicker_filter"));
    }

    async resetFilter() {
        this.searchForm.reset();
        $("#invoice_filter_warehouseId").val(null).trigger("change");
        $("#invoice_filter_retailPlaceId").val(null).trigger("change");
        var pickerElement = $("#kt_daterangepicker_filter");
        var today = new Date();
        $(pickerElement).data('daterangepicker').setStartDate(new Date(today.getFullYear(), 0, 1, 0, 0 ,0));
        $(pickerElement).data('daterangepicker').setEndDate(new Date());
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }

    filterByDate(event: KeyboardEvent) {
        if (event.key == "Enter" || event.key == "Tab") {
            var pickerElement = $("#kt_daterangepicker_filter");
            $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        }
    }

    async fetchGroups() {
        try {
            var response = await this.groupRestService.getGroups('', null, null, 1, 100000);
            this.groups = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchCustomers() {
        try {
            var response = await this.customerRestService.getCustomerSuppliers(this.data.user.clientId, '', null, null, true, null,null, 1, 999999);
            this.customers = response.data;
            this.customerSubset = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchTaxes() {
        try {
            this.taxes = await this.groupRestService.getTaxesByClientId(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchCurrencies() {
        try {
            this.currencies = await this.currencyRestService.getCurrencies();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchPaymentTypes() {
        try {
            var response = await this.paymentTypeRestService.getPaymentTypes(this.data.user.clientId, '', 1, 9999);
            this.paymentTypes = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchWarehouses() {
        try {
            this.warehouses = await this.warehouseRestService.getWarehousesForClient(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchInvoices() {
        try {
            this.emptyData = false;
            if (this.dateFrom == undefined)
                this.dateFrom = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
            if (this.dateTo == undefined)
                this.dateTo = this.datePipe.transform((new Date()), 'yyyy-MM-dd');
            var response = await this.outgoingInvoiceRest.getInvoices(this.customerName, this.filterWarehouseId, this.filterRetailPlaceId, '', this.dateFrom, this.dateTo, this.data.user.clientId, this.page, this.perPage);
            this.outgoingInvoices = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchInvoicePdf(id: string) {
        var response = await this.outgoingInvoiceRest.getInvoicesPDF(this.data.user.clientId, id);
        this.pdfSource = window.URL.createObjectURL(response);
    }

    async fetchDispatchNoteFromInvoicePdf(id: string) {
        var response = await this.outgoingInvoiceRest.getDispatchNoteFromInvoicePDF(this.data.user.clientId, id);
        this.pdfSource2 = window.URL.createObjectURL(response);
    }

    exportPDF() {
        if (this.pdfSource){
            this.showPdf=true;
            this.documentPdfComponent.setSource(this.pdfSource);
            // this.router.navigate(['/outgoing-invoice-pdf'], { queryParams: { pdfSrc: this.pdfSource } });
        }
        else {
            toastr.options = this.data.toastrOptions;
            toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
        }
    }
    closePDF(){
        this.showPdf=false;
    }
    
    exportDispatchNotePDF() {
        if (this.pdfSource2){
            // this.router.navigate(['/outgoing-invoice-dispatch-note-pdf'], { queryParams: { pdfSrc: this.pdfSource2 } });
            this.showPdf=true;
            this.showPdfAsDispatch=true;
            this.documentPdfAsDispatchComponent.setSource(this.pdfSource2);
        }
        else {
            toastr.options = this.data.toastrOptions;
            toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
        }
    }
    closeWithoutPricesPDF(){
        this.showPdf=false;
        this.showPdfAsDispatch=false;
    }

    async getNextNumber() {
        this.nextNumber = await this.outgoingInvoiceRest.getNextNumber(this.data.user.clientId);
        if (this.creatingInvoice.documentNumber == undefined) {
            this.creatingInvoice.documentNumber = this.nextNumber;
            this.creatingInvoice.outgoingInvoiceNumber = this.nextNumber + '/' + ((new Date()).getFullYear() - 2000);
        }
    }

    resetActiveInvoice() {
        this.creatingInvoice = new OutgoingInvoiceModel();
        this.creatingInvoice.clientId=this.data.user.clientId;
        this.creatingInvoice.articles = [];
        this.creatingInvoice.invoiceDiscount=0;
        this.creatingInvoice.totalAmount=0;
        this.creatingInvoice.totalBase=0;
        this.creatingInvoice.totalDiscount=0;
        this.creatingInvoice.totalTaxAmount=0;
        this.creatingInvoice.totalDebtAmount=0;
        this.creatingInvoice.totalPaidAmount=0;
        this.creatingInvoice.paidOff=false;
        this.creatingInvoice.payments = [];
        this.totalPaymentsAmount=0;
        this.totalDebtAmount=0;
        this.paymentsToAdd=[];
        this.invoiceDiscount=0;
        this.activeInvoicePreEdit = this.creatingInvoice;
    }



    updateCreatingInvoice(name: string, value: any) {
        this.creatingInvoice[name] = value;
    }

    async searchInvoices(customerName: string) {
        this.customerName = customerName;
        this.firstLoad = true;
        this.page = 1;
        this.fetchInvoices();
    }

    async searchCustomers() {
        if (this.searchCustomerName == '')
            this.customerSubset = this.customers;
        else
            this.customerSubset = this.customers.filter(x => x.name.toLowerCase().startsWith(this.searchCustomerName.toLowerCase()));
    }

    selectCustomer(c: CustomerSuppliersModel) {
        this.selectedCustomer = c;
        this.creatingInvoice.customerId = c.id;
        this.creatingInvoice.customerName = c.name;
        this.creatingInvoice.customerIDNumber = c.idNumber;
        this.creatingInvoice.customerPDVNumber = c.pdvNumber;
        this.creatingInvoice.customerAddress = c.address;
        this.showCustomerError = false;
    }

    refreshCustomerLookup() {
        this.searchCustomerName = '';
        this.customerSubset = this.customers;
    }

    openItemsOnEmpty($event: KeyboardEvent = null) {
        var fieldValue = $("#itemCodeInput").val();
        if (!this.itemFormEnabled || (fieldValue != '' && fieldValue != undefined))
            return;

        if ($event != null) {
            if ($event.key == "Enter") {
                this.enterCount++;
                clearTimeout(this.selectionModalTimer);
                this.selectionModalTimer = setTimeout(() => {
                    this.enterCount = 0;
                }, 500);

                if (this.enterCount != 2)
                    return;
                
                // this.itemTrap.deactivate();
                this.itemLookupComponent.openItemsModal();
            }
        } else {
            // this.itemTrap.deactivate();
            this.itemLookupComponent.openItemsModal();
        }
    }

    toggleItems(toggle: boolean) {
        this.showItems = toggle;
    }
    toggleDocNav(toggle: boolean) {
        this.onHeaderTab = toggle;
    }

    switchToArticles() {
        this.shouldActivateTrap=true;
        this.itemsTrigger.nativeElement.click();
        this.startAddingItems();
    }

    switchToHeader() {
        this.headerTrigger.nativeElement.click();
    }

    viewDocuments() {
        this.toggleItems(false);
        this.toggleDocNav(false);
        this.resetForm();
        this.resetItemForm();
        this.disableForm();
        this.headerSaved = false;
        this.startedEditing = false;
        this.editEnabled = false;
        this.addEnabled = false;
        this.editingItems = false;
    }

    selectInvoice(invoice: OutgoingInvoiceModel, switchToHeaderTab: boolean = true) {
        if (switchToHeaderTab)
            this.headerTrigger.nativeElement.click();

        this.creatingInvoice = invoice;
        this.fetchInvoicePdf(this.creatingInvoice.id);
        this.fetchDispatchNoteFromInvoicePdf(this.creatingInvoice.id);
        this.selectedInvoicePaymentType = this.paymentTypes.find(x => x.id == invoice.paymentTypeId);
        console.log(this.currencies, invoice);
        this.selectedCurrency = this.currencies.find(x => x.id == invoice.currencyId).code;
        this.selectedInvoiceTax = this.taxes.find(x => x.id == invoice.taxId);

        $('#add_invoice_paymentTypeId').val(this.creatingInvoice.paymentTypeId).trigger("change");
        $('#add_invoice_warehouseId').val(this.creatingInvoice.warehouseId).trigger("change");
        $('#add_invoice_currencyId').val(this.creatingInvoice.currencyId).trigger("change");
        $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingInvoice.billDate));
        $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingInvoice.arrivalDate));
        this.hForm.billDate.setValue(this.creatingInvoice.billDate);
        this.hForm.arrivalDate.setValue(this.creatingInvoice.arrivalDate);
        this.addEnabled = false;
        this.editEnabled = true;
        this.startedEditing = false;

        this.activeInvoicePreEdit = this.makePreEditObject();
        this.hForm.customerId.setValue(this.creatingInvoice.customerId);
        var customerCode = this.customers.find(x => x.id == this.creatingInvoice.customerId).code;
        $("#customerCodeInput").val(customerCode);
        this.selectedCustomer = this.customers.find(x => x.id == this.creatingInvoice.customerId);
        this.hForm.customerId.setValue(this.creatingInvoice.customerId);
        this.hForm.fis.setValue(this.creatingInvoice.fis);
        this.hForm.note.setValue(this.creatingInvoice.note);

        if (this.creatingInvoice.warehouseId != this.previousWarehouseId) {
            this._changeRef.detectChanges();
            this.previousWarehouseId = this.creatingInvoice.warehouseId;
            this.itemLookupComponent.fetchArticles(true);
        } else {
            this.itemLookupComponent.refreshItemLookup();
        }

        this.selectedArticle = null;
        this.invoiceDiscount = this.creatingInvoice.invoiceDiscount;
        this.iForm.discount1.setValue(this.invoiceDiscount);

        this.pForm.paidOff.setValue(this.creatingInvoice.paidOff);
        this.paymentsToAdd = [];
        if(this.creatingInvoice.payments !=undefined)
            this.creatingInvoice.payments.forEach(x => this.paymentsToAdd.push(x));
        this.totalPaymentsAmount = 0;
        this.paymentsToAdd.forEach(x => this.totalPaymentsAmount += x.amount);
        this.totalDebtAmount = this.creatingInvoice.totalDebtAmount;

        this.headerSaved = true;
        this.disableForm();
    }

    async selectNext() {
        var index = this.outgoingInvoices.findIndex(x => x.id == this.creatingInvoice.id);

        if (index == 0) {
            if (this.page == 1)
                return;

            await this.prevpage();
            await this.fetchInvoices();

            if (this.outgoingInvoices.length > 0) {
                this.selectInvoice(this.outgoingInvoices[this.outgoingInvoices.length - 1]);
            }
        }
        else {
            this.selectInvoice(this.outgoingInvoices[index - 1]);
        }
    }

    async selectPrevious() {
        var index = this.outgoingInvoices.findIndex(x => x.id == this.creatingInvoice.id);
        if (index == (this.outgoingInvoices.length - 1)) {
            if (this.page == this.maxPage)
                return;

            await this.nextPage();
            await this.fetchInvoices();

            if (this.outgoingInvoices.length > 0) {
                this.selectInvoice(this.outgoingInvoices[0]);
            }
        }
        else {
            this.selectInvoice(this.outgoingInvoices[index + 1]);
        }
    }

    startEditing(header: boolean) {
        if (header) {
            this.headerSaved = false;
            if(!this.creatingInvoice.isConsolidated)
                this.firstHeaderFormElement.nativeElement.focus();
        }
        this.enableHeaderForm(false);
        this.disableItemForm();
        this.resetItemForm();
        this.startedEditing = true;
        if (this.editingItems)
            this.editingItems = false;
    }
    startAddingItems(safeToActivate:boolean=false) {
        if (this.itemLookupComponent.articles.length == 0)
            this.itemLookupComponent.fetchArticles();
        this.enableItemForm();
        this.startedEditing = true;
        this.editingItems = true;
        if(!this.onHeaderTab)
            this.itemCodeInput.nativeElement.focus();
    }

    cancelEditing(header: boolean = true) {
        this.selectInvoice(this.activeInvoicePreEdit, false);
        this.disableForm();
        if (!header) {
            this.resetItemForm();
        }
        this.invoiceDiscount=this.creatingInvoice.invoiceDiscount;
        this.startedEditing = false;
        this.editEnabled = true;
        this.addEnabled = false;
        this.editingItems = false;
        this.editingItem=null;

        if (this.creatingInvoice.id != undefined)
            this.headerSaved = true;
    }

    cancelAdding() {
        this.disableForm();
        this.resetActiveInvoice();
        this.addEnabled=false;
        this.resetForm();
    }

    manualInputDate(id: string) {
        var pickerElement = $("#" + id);
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }

    selectOnFocus(id: string) {
        $("#" + id).select();
    }

    async createHeaderForm() {
        this.headerForm = this.formBuilder.group({
            customerId: [0, Validators.required],
            paymentTypeId: [0, Validators.required],
            warehouseId: [0, Validators.required],
            currencyId: [0, Validators.required],
            fis: [0],
            billDate: [],
            arrivalDate: [],
            note: [''],
        });

        var self = this;

        $("#add_invoice_paymentTypeId").select2();
        $('#add_invoice_paymentTypeId').on("change", function () {
            self.selectAddInvoicePaymentTypeId.nativeElement.value = $(this).val();
            self.addPaymentTypeId = $(this).val();
        });

        $("#add_invoice_warehouseId").select2();
        $('#add_invoice_warehouseId').on("change", function () {
            self.selectAddInvoiceWarehouseId.nativeElement.value = $(this).val();
            self.addWarehouseId = $(this).val();
        });

        $("#add_invoice_currencyId").select2();
        $('#add_invoice_currencyId').on("change", function () {
            self.selectAddInvoiceCurrencyId.nativeElement.value = $(this).val();
            self.addCurrencyId = $(this).val();
        });

        var start = (new Date()).setDate((new Date()).getDate() - 29);
        var end = new Date();
        $("#kt_bill_date_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: start,
            endDate: end,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });
        $("#kt_bill_date_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.addBillDate = picker.startDate._d;
            self.headerForm.controls.billDate.setValue(self.addBillDate);
            if (!self.changedArrivalDate) {
                self.addArrivalDate = new Date(self.addBillDate.getFullYear(), self.addBillDate.getMonth() + 1, self.addBillDate.getDate());
                $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(self.addArrivalDate);
                self.headerForm.controls.arrivalDate.setValue(self.addArrivalDate);
            }
        });
        var im2 = new Inputmask("99/99/9999");
        im2.mask($("#kt_bill_date_add_picker"));

        var arrivalDefault = new Date(new Date().setMonth(new Date().getMonth() + 1));
        var startArrival = (arrivalDefault).setDate(arrivalDefault.getDate());
        $("#kt_arrival_date_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: startArrival,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });
        $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(arrivalDefault);
        $("#kt_arrival_date_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.changedArrivalDate = true;
            self.addArrivalDate = picker.startDate._d;
            self.headerForm.controls.arrivalDate.setValue(self.addArrivalDate);
        });
        var im = new Inputmask("99/99/9999");
        im.mask($("#kt_arrival_date_add_picker"));

        if (this.editEnabled && !this.startedEditing)
            this.disableForm();
    }

    createAddItemForm() {
        this.addItemForm = this.formBuilder.group({
            quantity: [null, [Validators.required, Validators.min(0.000001)]],
            basePrice: [0, [Validators.required, Validators.min(0.000001)]],
            discount1: [0, Validators.required],
            discount2: [0, Validators.required],
            addTaxId: [0, Validators.required],
        });
        var self = this;
        $("#add_item_taxId").select2();
        $('#add_item_taxId').on("change", function () {
            self.selectTaxId.nativeElement.value = $(this).val();
            self.addTaxId = $(this).val();
            self.selectedInvoiceTax = self.taxes.find(x => x.id == self.addTaxId);
            self.recalculateBaseAndFinalPrice();
        });
    }

    async createPaymentAndDiscountForm() {
        this.paymentsForm = this.formBuilder.group({
            newPaymentAmount: [0, Validators.required],
            paymentDate: [],
            paidOff: [false]
        });
        var self = this;
        var start = new Date();
        $("#kt_new_payment_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: start,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });
        $("#kt_new_payment_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.newPaymentDate = picker.startDate._d;
            $("#newPaymentAmount").focus();
        });
        var im = new Inputmask("99/99/9999");
        im.mask($("#kt_new_payment_add_picker"));
    }

    disableForm() {
        this.disableHeaderForm(false);
        this.disableItemForm();
    }
    disableHeaderForm(emit: boolean = true) {
        if (emit == false) {
            this.headerForm.disable({ emitEvent: false });
        } else {
            this.headerForm.disable();
        }

        this.headerFormEnabled = false;
        $("#customerCodeInput").prop("disabled", true);
        $("#customerLookup").addClass("disabled");
        $("#kt_bill_date_add_picker").prop("disabled", true);
        $("#kt_arrival_date_add_picker").prop("disabled", true);
    }
    disableItemForm() {
        this.addItemForm.disable();
        if (this.itemFormEnabled) {
            this.itemFormEnabled = false;
            setTimeout(() => {
                this.addItemsButton.nativeElement.focus();
            }, 0);
        }
        $("#itemCodeInput").prop("disabled", true);
        $("#itemLookup").addClass("disabled");
    }

    enableForm() {
        this.enableHeaderForm();
        this.enableItemForm();
    }
    enableHeaderForm(emit: boolean = true) {
        if (emit == false)
            this.headerForm.enable({ emitEvent: false });
        else
            this.headerForm.enable();
        this.headerFormEnabled = true;
        $("#customerCodeInput").prop("disabled", false);
        $("#customerLookup").removeClass("disabled");
        $("#kt_bill_date_add_picker").prop("disabled", false);
        $("#kt_arrival_date_add_picker").prop("disabled", false);
        $("#add_invoice_paymentTypeId").prop("disabled", true);
        $("#add_invoice_warehouseId").prop("disabled", true);
    }
    enableItemForm() {
        if (this.basePrice == undefined)
            this.basePrice = 0;
        this.addItemForm.enable();
        this.itemFormEnabled = true;
        if (this.invoiceDiscount > 0) {
            $("#discount1").prop("disabled", true);
            $("#discount2").prop("disabled", true);
        }
        $("#itemCodeInput").prop("disabled", false);
        $("#itemLookup").removeClass("disabled");
        if(this.taxes!=undefined && this.taxes.length>0)
            $("#add_item_taxId").val(this.taxes[0].id).trigger("change");
    }

    async resetForm() {
        this.selectedCustomer = null;
        if(this.paymentTypes!=undefined && this.paymentTypes.length > 0)
            $('#add_invoice_paymentTypeId').val(this.paymentTypes[0].id).trigger("change");
        if(this.currencies!=undefined && this.currencies.length > 0)
            $('#add_invoice_currencyId').val(this.currencies[0].id).trigger("change");
        if(this.warehouses != undefined && this.warehouses.length > 0)
            $('#add_invoice_warehouseId').val(this.warehouses[0].id).trigger("change");
        $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date());
        this.addBillDate = new Date();
        this.hForm.billDate.setValue(this.addBillDate);
        this.addArrivalDate = new Date(this.addBillDate.getFullYear(), this.addBillDate.getMonth() + 1, this.addBillDate.getDate());
        $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(this.addArrivalDate);
        this.hForm.arrivalDate.setValue(this.addArrivalDate);

        this.headerForm.controls.fis.setValue(0);
        this.headerForm.controls.note.setValue(null);

        $('#itemCodeInput').val(null);
        this.selectedArticle = undefined;
        this.addItemForm.controls.quantity.setValue(null);
        this.addItemForm.controls.basePrice.setValue(undefined);
        this.addItemForm.controls.discount1.setValue(0);
        this.addItemForm.controls.discount2.setValue(0);
        if(this.taxes!=undefined && this.taxes.length>0)
            $('#add_item_taxId').val(this.taxes[0].id).trigger("change");

        this.addEnabled = false;
        this.editEnabled = false;
        this.startedEditing = false;

        this.basePrice = 0;
        this.finalPrice = 0;
        this.totalAmount = 0;

        this.paymentsForm.controls.paidOff.setValue(false);
        this.addPaidOff = false;
        this.newPaymentAmount = 0;

        this.headerFormEnabled = false;
        this.itemFormEnabled = false;
        this.changedArrivalDate = false;
    }

    async addOutgoingInvoice() {
        if (this.creationInProgress)
            return;
        this.creationInProgress = true;
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            this.creationInProgress = false;
            return;
        }

        this.creatingInvoice.clientId=this.data.user.clientId;
        try {
            await this.outgoingInvoiceRest.createOutgoingInvoice(this.creatingInvoice);
            this.creatingInvoice.id = await this.getNewlyCreatedId();
            this.totalDebtAmount = this.creatingInvoice.totalAmount;
            this.creatingInvoice.totalDebtAmount = this.totalDebtAmount;
            this.activeInvoicePreEdit = this.makePreEditObject();
            this.headerSaved = true;
            this.editingItems = true;
            this.setPostAddingLayout();
            this.invoiceDiscount = 0;

            if (this.creatingInvoice.warehouseId != this.previousWarehouseId) {
                this.previousWarehouseId = this.creatingInvoice.warehouseId;
                this.itemLookupComponent.fetchArticles(true);
            } else {
                this.itemLookupComponent.refreshItemLookup();
            }

            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_outgoing_invoice_text'), this.translate.instant("_Success"));
            this.fetchInvoices();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
        this.creationInProgress = false;
    }
    makePreEditObject() {
        var obj = new OutgoingInvoiceModel();
        Object.keys(this.creatingInvoice).forEach(key => {
            if (key != 'articles') {
                let value = this.creatingInvoice[key];
                obj[key] = value;
            }
        })
        obj.articles = [];
        this.creatingInvoice.articles.forEach(x => {
            var itemCopy = new SoldArticleModel();
            Object.keys(x).forEach(key => {
                let value = x[key];
                itemCopy[key] = value;
            })
            obj.articles.push(itemCopy);
        });
        return obj;
    }
    async getNewlyCreatedId() {
        var response = await this.outgoingInvoiceRest.getInvoices('', this.filterWarehouseId, this.filterRetailPlaceId, this.creatingInvoice.outgoingInvoiceNumber, this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.data.user.clientId, 1, 1)
        var id = response.data[0].id;
        return id;
    }

    async editOutgoingInvoice(instantReverse: boolean = false, causedByArticleId:any=null, itemQuantity:any=null, editOverlap:boolean=false) {
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        try {
            if(this.creatingInvoice.isConsolidated)
                await this.outgoingInvoiceRest.editConsolidatedInvoice(this.creatingInvoice);
            else
                await this.outgoingInvoiceRest.editOutgoingInvoice(this.creatingInvoice);
            this.creatingInvoice.totalPaidAmount = this.totalPaymentsAmount;
            this.creatingInvoice.totalDebtAmount = this.totalDebtAmount;

            if (this.creatingInvoice.fis == null) {
                this.creatingInvoice.fis = 0;
                this.headerForm.controls.fis.setValue(this.creatingInvoice.fis);
            }

            this.startedEditing = false;
            this.editingItems = false;
            this.headerSaved = true;
            this.resetItemForm();
            this.disableForm();

            if (this.creatingInvoice.warehouseId != this.previousWarehouseId) {
                this.previousWarehouseId = this.creatingInvoice.warehouseId;
                this.itemLookupComponent.fetchArticles(true);
            }else{
                if(causedByArticleId!=null && !editOverlap){
                    if(this.itemLookupComponent.articles.find(x=>x.id==causedByArticleId)!=undefined)
                        this.itemLookupComponent.updateWarehouseState(causedByArticleId, itemQuantity);
                }
            }

            this.activeInvoicePreEdit = this.makePreEditObject();
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Edited_outgoing_invoice_text'), this.translate.instant("_Success"));
            this.fetchInvoices();
        }
        catch (err) {
            if (instantReverse)
                this.creatingInvoice = this.activeInvoicePreEdit;

            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    async deleteInvoice(id: string) {
        var self = this;
        var question_text = this.translate.instant("_Delete_invoice_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.outgoingInvoiceRest.deleteOutgoingInvoice(id);
                if (this.creatingInvoice!=undefined && this.creatingInvoice.id == id) {
                    this.resetActiveInvoice();
                    this.disableForm();
                    this.startedEditing = false;
                    this.editEnabled = true;
                    this.addEnabled = false;
                    this.editingItems = false;
                    this.resetForm();
                }
                await this.fetchInvoices();
                toastr.options = this.data.toastrOptions;
                toastr.success(this.translate.instant('_Deleted_outgoing_invoice_text'), this.translate.instant("_Success"));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    async deleteConsolidatedInvoice(id: string) {
        var self = this;
        var question_text = this.translate.instant("_Delete_invoice_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.outgoingInvoiceRest.deleteConsolidatedInvoice(id);
                if (this.creatingInvoice!=undefined && this.creatingInvoice.id == id) {
                    this.resetActiveInvoice();
                    this.disableForm();
                    this.startedEditing = false;
                    this.editEnabled = true;
                    this.addEnabled = false;
                    this.editingItems = false;
                    this.resetForm();
                }
                await this.fetchInvoices();
                toastr.options = this.data.toastrOptions;
                toastr.success(this.translate.instant('_Deleted_outgoing_invoice_text'), this.translate.instant("_Success"));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    shouldFetchArticleState(id: number) {
        if (this.itemLookupComponent.articles.find(x => x.id == id) != undefined)
            return false;
        if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == id) != undefined)
            return false;

        return true;
    }
    async selectItem(item: SoldArticleModel) {
        if (this.shouldFetchArticleState(item.id)) {
            try {
                var articlesToFetch = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.creatingInvoice.warehouseId, 1, 99999, '', false, item.id, null, true);
                if (this.documentArticles == undefined)
                    this.documentArticles = [];
                this.documentArticles.push(articlesToFetch[0]);
                this.selectedArticle = articlesToFetch[0];
            } catch (err: any) {
                toastr.options = this.data.toastrOptions;
                toastr.error(err.error['Message'], this.translate.instant("_Error"));
            }
        } else {
            if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == item.id))
                this.selectedArticle = this.documentArticles.find(x => x.id == item.id);
            else
                this.selectedArticle = this.itemLookupComponent.articles.find(x => x.id == item.id);
        }

        this.enableItemForm();
        $("#amountInput").val(item.quantity);
        this.iForm.quantity.setValue(item.quantity);
        $("#discount1").val(item.discount1Percentage);
        $("#discount2").val(item.discount2Percentage);
        $("#add_item_taxId").val(item.taxId).trigger("change");
        this.iForm.discount1.setValue(item.discount1Percentage);
        this.iForm.discount2.setValue(item.discount2Percentage);
        this.basePrice = item.basePrice;
        this.basePriceDisplay = parseFloat(item.basePrice.toFixed(2));
        this.iForm.basePrice.setValue(this.basePriceDisplay);
        this.finalPrice = item.finalPrice;
        this.postDiscountPrice = item.postDiscountPrice;

        this.startedEditing = true;
        this.editingItemIndex = this.creatingInvoice.articles.indexOf(item);
        this.editingItems = true;
        this.editingItem = item;

        this.itemsTrigger.nativeElement.click();
        this._changeRef.detectChanges();
        $('#amountInput').focus();
    }
    editItem() {
        this.isSubmitted = false;
        if (this.addItemForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateItemForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted) {
            return;
        }

        var itemId = 0; var itemQuantity = 0;var quantityDiff=0;var editOverlap=false;
        var existingPriceArticle = this.checkForSamePriceItem(null, true);
        if (existingPriceArticle != null) {
            var preEditTotalArticleQuntity = 0;
            this.creatingInvoice.articles.filter(x=>x.id==existingPriceArticle.id).forEach(x=>preEditTotalArticleQuntity+=x.quantity);

            itemId = existingPriceArticle.id;
            itemQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
            quantityDiff=existingPriceArticle.quantity -itemQuantity;

            existingPriceArticle.quantity += parseFloat((this.iForm.quantity.value).toFixed(6));
            existingPriceArticle.totalPrice = parseFloat((existingPriceArticle.finalPrice * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalDiscount1Amount = parseFloat((existingPriceArticle.discount1Amount * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalDiscount2Amount = parseFloat((existingPriceArticle.discount2Amount * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalCombinedDiscountAmount = existingPriceArticle.totalDiscount1Amount + existingPriceArticle.totalDiscount2Amount;
            existingPriceArticle.totalPostDiscount1Price = parseFloat((existingPriceArticle.postDiscount1Price * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalPostDiscountPrice = parseFloat((existingPriceArticle.postDiscountPrice * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalTaxAmount = parseFloat((existingPriceArticle.taxAmount * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalBasePrice = parseFloat((existingPriceArticle.basePrice*existingPriceArticle.quantity).toFixed(6));
        
            this.creatingInvoice.articles.forEach((element, index) => {
                if (element == this.editingItem) this.creatingInvoice.articles.splice(index, 1);
            });

            var postEditTotalArticleQuntity = 0;
            this.creatingInvoice.articles.filter(x=>x.id==existingPriceArticle.id).forEach(x=>postEditTotalArticleQuntity+=x.quantity);
            editOverlap = preEditTotalArticleQuntity == postEditTotalArticleQuntity;
            quantityDiff = preEditTotalArticleQuntity - postEditTotalArticleQuntity;
        } else {
            var newQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
            quantityDiff = this.editingItem.quantity - newQuantity;
            this.editingItem.quantity = newQuantity;
            this.editingItem.basePrice = this.basePrice;
            this.recalculateBaseAndFinalPrice();
           
            this.editingItem.discount1Percentage = parseFloat((this.iForm.discount1.value).toFixed(6));
            this.editingItem.discount1Amount = parseFloat((this.editingItem.basePrice * (this.editingItem.discount1Percentage / 100)).toFixed(6));
            this.editingItem.postDiscount1Price = parseFloat((this.editingItem.basePrice * (1 - (this.editingItem.discount1Percentage / 100))).toFixed(6));
            this.editingItem.discount2Percentage = parseFloat((this.iForm.discount2.value).toFixed(6));
            this.editingItem.discount2Amount = parseFloat((this.editingItem.postDiscount1Price * (this.editingItem.discount2Percentage / 100)).toFixed(6));
            this.editingItem.postDiscountPrice = this.postDiscountPrice;
            this.editingItem.combinedDiscountAmount = this.editingItem.discount1Amount + this.editingItem.discount2Amount;

            this.editingItem.taxId = this.addTaxId;
            this.editingItem.taxName = this.taxes.find(x => x.id == this.addTaxId).name;
            this.editingItem.taxPercentage = this.taxes.find(x => x.id == this.addTaxId).amount;
            this.editingItem.taxAmount = parseFloat((this.editingItem.postDiscountPrice * (this.editingItem.taxPercentage / 100)).toFixed(6));
            this.editingItem.finalPrice = this.finalPrice;

            this.editingItem.totalBasePrice = parseFloat((this.editingItem.basePrice * this.editingItem.quantity).toFixed(6));
            this.editingItem.totalDiscount1Amount = parseFloat((this.editingItem.discount1Amount * this.editingItem.quantity).toFixed(6));
            this.editingItem.totalDiscount2Amount = parseFloat((this.editingItem.discount2Amount * this.editingItem.quantity).toFixed(6));
            this.editingItem.totalPostDiscount1Price = parseFloat((this.editingItem.postDiscount1Price * this.editingItem.quantity).toFixed(6));
            this.editingItem.totalPostDiscountPrice = parseFloat((this.editingItem.postDiscountPrice * this.editingItem.quantity).toFixed(6));
            this.editingItem.totalCombinedDiscountAmount = parseFloat((this.editingItem.combinedDiscountAmount * this.editingItem.quantity).toFixed(6));
            this.editingItem.totalTaxAmount = parseFloat((this.editingItem.taxAmount * this.editingItem.quantity).toFixed(6));
            this.editingItem.totalPrice = parseFloat((this.editingItem.finalPrice * this.editingItem.quantity).toFixed(6));

            itemId = this.editingItem.id;
        }

        this.creatingInvoice.totalAmount = 0;
        this.creatingInvoice.articles.forEach(x => this.creatingInvoice.totalAmount += x.totalPrice);
        this.creatingInvoice.totalBase = 0;
        this.creatingInvoice.articles.forEach(x => this.creatingInvoice.totalBase += x.totalBasePrice);
        this.creatingInvoice.totalTaxAmount = 0;
        this.creatingInvoice.articles.forEach(x => this.creatingInvoice.totalTaxAmount += x.totalTaxAmount);
        this.creatingInvoice.totalDiscount = 0;
        this.creatingInvoice.articles.forEach(x => this.creatingInvoice.totalDiscount += x.totalCombinedDiscountAmount);

        this.totalDebtAmount = 0;
        this.creatingInvoice.articles.forEach(x=>this.totalDebtAmount+=x.totalPrice);
        this.totalDebtAmount -= this.totalPaymentsAmount;
        if(this.totalDebtAmount<0)
            this.totalDebtAmount=0;
        this.creatingInvoice.totalDebtAmount = this.totalDebtAmount;

        this.editOutgoingInvoice(false, itemId, quantityDiff, editOverlap);
        this.editingItem = undefined;
        this.resetItemForm();
    }

    validateAddForm() {
        var check = true;

        if (this.selectedCustomer == undefined) {
            check = false;
            this.showCustomerError = true;
        }
        else {
            this.showCustomerError = false;
        }

        if (this.addPaymentTypeId == 0) {
            $("#add_invoice_paymentTypeId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_paymentTypeId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.addWarehouseId == 0) {
            $("#add_invoice_warehouseId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_warehouseId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.addCurrencyId == 0) {
            $("#add_invoice_currencyId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_currencyId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    proceedToAddItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addInvoiceItemsTrigger").click();
    }

    proceedToEditItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addInvoiceItemsTrigger").click();
    }

    backToHeader() {
        $("#headerModalTrigger").click();
    }

    enteredPrice(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            if (this.iForm.basePrice.value != undefined)
                this.basePrice = parseFloat(this.iForm.basePrice.value.toFixed(6));
            else this.basePrice = undefined;

            if (this.basePrice == 0) {
                this.openFinalPriceModal.nativeElement.click();
                this.finalPrice = undefined;
            }
            else {
                this.recalculateBaseAndFinalPrice();
            }
        }
    }
    updateBasePrice() {
        if (this.iForm.basePrice.value != undefined)
            this.basePrice = parseFloat(this.iForm.basePrice.value.toFixed(6));
        else this.basePrice = undefined;
    }
    backToBase() {
        this.closeFinalPriceModal.nativeElement.click();
    }
    enteredFinal(event: KeyboardEvent) {
        if (event.key == 'Tab' || event.key == 'Enter') {
            event.preventDefault();
            this.recalculateBaseAndFinalPrice(false);
            this.backToBase();
            this._changeRef.detectChanges();
        }
    }
    recalculateBaseAndFinalPrice(fromBaseToFinal: boolean = true) {
        if(this.invoiceDiscount!=undefined && this.invoiceDiscount!=0){
            this.iForm.discount1.setValue(this.invoiceDiscount);
            this.iForm.discount2.setValue(0);
        }
        else{
            if (this.iForm.discount1.value == undefined)
                this.iForm.discount1.setValue(0);
            if (this.iForm.discount2.value == undefined)
                this.iForm.discount2.setValue(0);
        }

        if(this.taxes==undefined || this.taxes.length==0)
            return; 

        var taxToApply = this.taxes.find(x => x.id == this.addTaxId).amount;

        if (fromBaseToFinal) {
            if (this.basePrice == undefined)
                return;
            this.basePriceDisplay = parseFloat(this.basePrice.toFixed(2));
            var postDiscount1Price = this.basePrice * (1 - (this.iForm.discount1.value / 100));
            this.postDiscountPrice = parseFloat((postDiscount1Price * (1 - (this.iForm.discount2.value / 100))).toFixed(6));
            this.finalPrice = parseFloat((this.postDiscountPrice * (1 + taxToApply / 100)).toFixed(6));
        }
        else {
            this.postDiscountPrice = parseFloat((this.finalPrice / (1 + taxToApply / 100)).toFixed(6));
            var postDiscount1 = parseFloat((this.postDiscountPrice / (1 - (this.iForm.discount2.value / 100))).toFixed(6));
            this.basePrice = parseFloat((postDiscount1 / (1 - (this.iForm.discount1.value / 100))).toFixed(6));
            this.basePriceDisplay = parseFloat(this.basePrice.toFixed(2));
            this.iForm.basePrice.setValue(this.basePriceDisplay);
        }
    }

    async enteredDiscount(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            try {
                if(this.invoiceDiscount==undefined || this.invoiceDiscount==null)
                    this.invoiceDiscount = 0;
                this.creatingInvoice.invoiceDiscount = this.invoiceDiscount;
                this.creatingInvoice.totalAmount=0;
                this.creatingInvoice.totalBase=0;
                this.creatingInvoice.totalDebtAmount=0;
                this.creatingInvoice.totalDiscount=0;
                this.creatingInvoice.totalTaxAmount=0;

                this.iForm.discount1.setValue(this.invoiceDiscount);
                this.iForm.discount2.setValue(0);

                this.creatingInvoice.articles.forEach(x => {
                    x.discount1Percentage = this.invoiceDiscount;
                    x.discount2Percentage = 0;
                    this.basePrice = x.basePrice;
                    this.addTaxId = x.taxId;
                    this.recalculateBaseAndFinalPrice(true);
                    x.postDiscount1Price = this.postDiscountPrice;
                    x.postDiscountPrice = this.postDiscountPrice;
                    x.totalPostDiscount1Price = parseFloat((this.postDiscountPrice * x.quantity).toFixed(6))
                    x.totalPostDiscountPrice = x.totalPostDiscount1Price;
                    x.discount1Amount = this.basePrice - this.postDiscountPrice;
                    x.discount2Amount = x.discount1Amount;
                    x.combinedDiscountAmount = x.discount1Amount;
                    x.totalCombinedDiscountAmount = x.discount1Amount * x.quantity;
                    x.combinedDiscountPercentage = this.invoiceDiscount;
                    x.displayBase = this.basePriceDisplay;
                    x.displayFinal = parseFloat(x.finalPrice.toFixed(2));
                    x.displayTotal = x.displayBase * x.quantity;
                    x.finalPrice = this.finalPrice;
                    var taxToApply = this.taxes.find(y => y.id == x.taxId).amount;
                    x.taxAmount = parseFloat((this.postDiscountPrice * taxToApply / 100).toFixed(6));
                    x.totalTaxAmount = parseFloat((x.taxAmount * x.quantity).toFixed(6))
                    x.totalBasePrice = parseFloat((x.basePrice * x.quantity).toFixed(6));
                    x.totalPrice = parseFloat((x.finalPrice * x.quantity).toFixed(6));

                    this.creatingInvoice.totalAmount += x.totalPrice;
                    this.creatingInvoice.totalBase += x.totalBasePrice;
                    this.creatingInvoice.totalDiscount += x.totalCombinedDiscountAmount;
                    this.creatingInvoice.totalTaxAmount += x.totalTaxAmount;
                    this.creatingInvoice.totalDebtAmount += x.totalPrice;
                });
                if(this.creatingInvoice.totalDebtAmount < this.creatingInvoice.totalPaidAmount)
                    this.creatingInvoice.totalDebtAmount = 0;
                else 
                    this.creatingInvoice.totalDebtAmount -= this.creatingInvoice.totalPaidAmount;
                
                await this.outgoingInvoiceRest.editOutgoingInvoice(this.creatingInvoice);
                this.activeInvoicePreEdit = this.creatingInvoice;
                this.closeInvoiceDiscountModal.nativeElement.click();
                toastr.options = this.data.toastrOptions;
                toastr.success("Invoice discount updated!");
            } catch (err) {
                $("#discount1").val(0);
                toastr.options = this.data.toastrOptions;
                toastr.error(err.error['Message'], this.translate.instant("_Error"));
            }
        }
    }

    negateEnter(event: KeyboardEvent) {
        if (event.key == "Enter")
            event.preventDefault();
    }

    showList() {
        this.listTrigger.nativeElement.click();
    }

    editNumber() {
        if (!this.canEditNumber())
            return;

        this.invoiceNumberOpen.nativeElement.click();
        var input = $("#invoiceNumberInput");
        input.removeClass("is-invalid");

        if (this.creatingInvoice.documentNumber != null)
            input.val(this.creatingInvoice.documentNumber);
        else
            input.val(this.nextNumber);
    }
    canEditNumber() {
        if ((this.editEnabled && !this.startedEditing) || this.creatingInvoice.isConsolidated)
            return false;

        return true;
    }
    saveNumber() {
        var input = $("#invoiceNumberInput");
        if (input.val() == '' || input.val().includes('.')) {
            input.addClass("is-invalid");
            return;
        } else {
            input.removeClass("is-invalid");
            this.creatingInvoice.documentNumber = input.val();
            this.creatingInvoice.customDocumentNumber = this.creatingInvoice.documentNumber;
            this.creatingInvoice.outgoingInvoiceNumber = this.creatingInvoice.documentNumber + "/" + ((new Date()).getFullYear() % 100);
        }

        this.invoiceNumberClose.nativeElement.click();
    }

    switchToHeaderAndEnableAdding() {
        this.shouldActivateTrap=true;
        this.headerTrigger.nativeElement.click();
        this.enableAdding();
    }

    enableAdding(safeToTriggerTrap:boolean=false) {
        this.resetActiveInvoice();
        this.getNextNumber();
        this.resetForm();
        this.addEnabled = true;
        this.headerSaved = false;
        this.enableHeaderForm();
        this.firstHeaderFormElement.nativeElement.focus();
      
    }
    setPostAddingLayout() {
        this.addEnabled = false;
        this.editEnabled = true;
        this.startedEditing = true;
        this.disableHeaderForm(false);
        this.enableItemForm();
        this.shouldActivateTrap=true;
        this.itemsTrigger.nativeElement.click();
    }

    async addItem(save: boolean = false) {
        this.isSubmitted = false;
        if (this.addItemForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateItemForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted) {
            return;
        }

        var itemId = 0; var itemQuantity = 0;
        var existingPriceArticle = this.checkForSamePriceItem(this.selectedArticle);
        if (existingPriceArticle != null) {
            this.creatingInvoice.totalAmount -= existingPriceArticle.totalPrice;
            this.creatingInvoice.totalBase -= existingPriceArticle.totalBasePrice;
            this.creatingInvoice.totalTaxAmount -= existingPriceArticle.totalTaxAmount;
            this.creatingInvoice.totalDiscount -= existingPriceArticle.totalCombinedDiscountAmount;

            existingPriceArticle.quantity += parseFloat((this.iForm.quantity.value).toFixed(6));
            existingPriceArticle.totalBasePrice = parseFloat((existingPriceArticle.basePrice * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalPrice = parseFloat((existingPriceArticle.finalPrice * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalDiscount1Amount = parseFloat((existingPriceArticle.discount1Amount * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalDiscount2Amount = parseFloat((existingPriceArticle.discount2Amount * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalCombinedDiscountAmount = existingPriceArticle.totalDiscount1Amount + existingPriceArticle.totalDiscount2Amount;
            existingPriceArticle.totalPostDiscount1Price = parseFloat((existingPriceArticle.postDiscount1Price * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalPostDiscountPrice = parseFloat((existingPriceArticle.postDiscountPrice * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalTaxAmount = parseFloat((existingPriceArticle.taxAmount * existingPriceArticle.quantity).toFixed(6));

            this.creatingInvoice.totalAmount += existingPriceArticle.totalPrice;
            this.creatingInvoice.totalBase += existingPriceArticle.totalBasePrice;
            this.creatingInvoice.totalTaxAmount += existingPriceArticle.totalTaxAmount;
            this.creatingInvoice.totalDiscount += existingPriceArticle.totalCombinedDiscountAmount;

            itemId = existingPriceArticle.id;
            itemQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
        }
        else {
            var item = new SoldArticleModel();
            item.id = this.selectedArticle.id;
            item.code = this.selectedArticle.code;
            item.name = this.selectedArticle.name;
            item.quantity = parseFloat((this.iForm.quantity.value).toFixed(6));
            item.basePrice = this.basePrice;
            item.measureShort = this.selectedArticle.measureShort;

            item.discount1Percentage = parseFloat((this.iForm.discount1.value).toFixed(6));
            item.discount1Amount = parseFloat((item.basePrice * (item.discount1Percentage / 100)).toFixed(6));
            item.postDiscount1Price = parseFloat((item.basePrice * (1 - (item.discount1Percentage / 100))).toFixed(6));
            item.discount2Percentage = parseFloat((this.iForm.discount2.value).toFixed(6));
            item.discount2Amount = parseFloat((item.postDiscount1Price * (item.discount2Percentage / 100)).toFixed(6));
            item.postDiscountPrice = this.postDiscountPrice;
            item.combinedDiscountAmount = item.discount1Amount + item.discount2Amount;
            item.isNormative = this.selectedArticle.isNormative;
            item.g = this.selectedArticle.groupId;
            item.gt = this.selectedArticle.groupTypeId;

            item.taxId = this.addTaxId;
            item.taxName = this.taxes.find(x => x.id == this.addTaxId).name;
            item.taxPercentage = this.selectedInvoiceTax.amount;
            item.taxAmount = parseFloat((item.postDiscountPrice * (item.taxPercentage / 100)).toFixed(6));
            
            item.totalBasePrice = parseFloat((item.basePrice * item.quantity).toFixed(6));
            item.totalDiscount1Amount = parseFloat((item.discount1Amount * item.quantity).toFixed(6));
            item.totalDiscount2Amount = parseFloat((item.discount2Amount * item.quantity).toFixed(6));
            item.totalCombinedDiscountAmount = parseFloat((item.combinedDiscountAmount * item.quantity).toFixed(6));
            item.totalPostDiscount1Price = parseFloat((item.postDiscount1Price * item.quantity).toFixed(6));
            item.totalPostDiscountPrice = parseFloat((item.postDiscountPrice * item.quantity).toFixed(6));
            item.totalTaxAmount = parseFloat((item.taxAmount * item.quantity).toFixed(6));
            item.finalPrice = this.finalPrice;
            item.totalPrice = parseFloat((item.finalPrice * item.quantity).toFixed(6));
            item.nc = this.selectedArticle.nc;
            item.mpc = this.selectedArticle.mpc;

            this.creatingInvoice.articles.push(item);
            this.creatingInvoice.totalAmount += parseFloat((item.totalPrice).toFixed(6));
            this.creatingInvoice.totalBase += parseFloat((item.basePrice * item.quantity).toFixed(6));
            this.creatingInvoice.totalTaxAmount += item.totalTaxAmount;
            this.creatingInvoice.totalDiscount += item.totalCombinedDiscountAmount;
            

            itemId = item.id;
            itemQuantity = item.quantity;

            item.uniqueItemId = uuidv4();
        }

        this.selectedArticle = undefined;
        this.resetItemForm();

        this.totalDebtAmount = 0;
        this.creatingInvoice.articles.forEach(x=>this.totalDebtAmount+=x.totalPrice);
        this.totalDebtAmount -= this.totalPaymentsAmount;
        if(this.totalDebtAmount<0)
            this.totalDebtAmount=0;
        this.creatingInvoice.totalDebtAmount = this.totalDebtAmount;

        await this.editOutgoingInvoice(true, itemId, -itemQuantity);
    }

    checkForSamePriceItem(activeItem: ArticleModel = null, updating: boolean = false) {
        if (updating == false)
            return this.creatingInvoice.articles.find(x => x.id == activeItem.id  && x.basePrice == this.basePrice &&
                     x.finalPrice == this.finalPrice && x.discount1Percentage == this.iForm.discount1.value &&
                     x.discount2Percentage == this.iForm.discount2.value && x.taxId == this.addTaxId);
        else
            return this.creatingInvoice.articles.find(x => x.id == this.editingItem.id && x.basePrice == this.basePrice &&
                    x.finalPrice == this.finalPrice && x.discount1Percentage == this.iForm.discount1.value &&
                    x.discount2Percentage == this.iForm.discount2.value && x.taxId == this.addTaxId &&
                    this.creatingInvoice.articles.indexOf(x) != this.editingItemIndex);
    }

    resetItemForm() {
        this.finalPrice = undefined;
        this.basePrice = 0;
        $(this.finalPriceInput.nativeElement).val(undefined);
        $("#itemCodeInput").val(undefined);
        this.iForm.quantity.setValue(null);
        this.iForm.basePrice.setValue(null);
        this.iForm.discount1.setValue(this.creatingInvoice.invoiceDiscount);
        this.iForm.discount2.setValue(0);
        if(this.taxes!=undefined && this.taxes.length>0)
            $("#add_item_taxId").val(this.taxes[0].id).trigger("change");
        this.selectedArticle = undefined;
        this.editingItem = undefined;

        $("#itemCodeInput").removeClass('is-invalid');
        $("#add_item_taxId").nextAll('span').first().removeClass('is-invalid');
    }

    async removeItem(item: SoldArticleModel) {
        this.creatingInvoice.totalAmount -= item.totalPrice;
        this.creatingInvoice.totalBase -= item.totalBasePrice;
        this.creatingInvoice.totalTaxAmount -= item.totalTaxAmount;
        this.creatingInvoice.totalDiscount -= item.totalCombinedDiscountAmount;

        this.creatingInvoice.articles.forEach((element, index) => {
            if (element == item) this.creatingInvoice.articles.splice(index, 1);
        });

        this.totalDebtAmount = 0;
        this.creatingInvoice.articles.forEach(x=>this.totalDebtAmount+=x.totalPrice);
        this.totalDebtAmount -= this.totalPaymentsAmount;
        if(this.totalDebtAmount<0)
            this.totalDebtAmount=0;
        this.creatingInvoice.totalDebtAmount = this.totalDebtAmount;
        
        await this.editOutgoingInvoice(false, item.id, item.quantity);
    }

    //Payments
    addPayment() {
        if (this.paymentsToAdd == undefined) {
            this.paymentsToAdd = [];
            this.creatingInvoice.payments.forEach(x => this.paymentsToAdd.push(x));
        }

        if (this.newPaymentAmount <= 0 || this.newPaymentAmount == undefined) {
            $("#newPaymentAmount").addClass('is-invalid');
            return;
        } else {
            $("#newPaymentAmount").removeClass('is-invalid');
        }

        var newPayment = new InvoiceCustomerPaymentModel();
        newPayment.amount = this.newPaymentAmount;
        newPayment.date = this.newPaymentDate;
        newPayment.displayDate = this.datePipe.transform(this.newPaymentDate, 'yyyy-MM-dd');
        this.paymentsToAdd.push(newPayment);
        this.totalPaymentsAmount += newPayment.amount;
        this.totalDebtAmount -= newPayment.amount;
        if(this.totalDebtAmount < 0)
            this.totalDebtAmount = 0;
    }
    removePayment(payment: InvoiceCustomerPaymentModel) {
        if (this.paymentsToAdd == undefined) {
            this.paymentsToAdd = [];
            this.creatingInvoice.payments.forEach(x => this.paymentsToAdd.push(x));
        }

        this.paymentsToAdd.forEach((element, index) => {
            if (element == payment) this.paymentsToAdd.splice(index, 1);
        });

        this.totalPaymentsAmount -= payment.amount;
        if(this.totalPaymentsAmount<0)
            this.totalPaymentsAmount = 0;
        this.totalDebtAmount=this.creatingInvoice.totalAmount - this.totalPaymentsAmount;
        if(this.totalDebtAmount < 0)
            this.totalDebtAmount = 0;
    }
    async updatePaymentInfo() {
        var dto = new UpdateInvoicePaymentDTO();
        dto.invoiceId = this.creatingInvoice.id;
        dto.paidOff = this.addPaidOff;
        dto.payments = this.paymentsToAdd;
        dto.totalDebtAmount = this.totalDebtAmount;
        dto.totalPaidAmount = this.totalPaymentsAmount;

        try {
            await this.outgoingInvoiceRest.updateInvoicePaymentData(dto);
            this.creatingInvoice.payments = [];
            this.paymentsToAdd.forEach(x=>this.creatingInvoice.payments.push(x));
            this.creatingInvoice.paidOff = this.addPaidOff;
            this.creatingInvoice.totalPaidAmount = dto.totalPaidAmount;
            this.activeInvoicePreEdit = this.makePreEditObject();
            await this.fetchInvoices();
            this.closePaymentsModal.nativeElement.click();
            this.resetPaymentForm();
            toastr.options = this.data.toastrOptions;
            toastr.success("Payment info updated!");
        } catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }
    cancelPaymentChanges() {
        this.paymentsToAdd = [];
        if(this.creatingInvoice.payments!=undefined)
            this.creatingInvoice.payments.forEach(x => this.paymentsToAdd.push(x));
        this.totalPaymentsAmount=this.creatingInvoice.totalPaidAmount;
        this.totalDebtAmount = this.creatingInvoice.totalDebtAmount;
    }
    resetPaymentForm() {
        this.addPaidOff = this.activeInvoicePreEdit.paidOff;
        this.pForm.newPaymentAmount.setValue(0);
        this.newPaymentDate = new Date(),
            $("#kt_new_payment_add_picker").data('daterangepicker').setStartDate(new Date());
    }
    closePaymentModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closePaymentsModal.nativeElement.click();
                self.cancelPaymentChanges();
                self.resetPaymentForm();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    validateItemForm() {
        var check = true;

        if (this.selectedArticle == undefined) {
            check = false;
            $("#itemCodeInput").addClass('is-invalid');
        }
        else {
            $("#itemCodeInput").removeClass('is-invalid');
        }

        if (this.addTaxId == 0) {
            $("#add_item_taxId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_item_taxId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    async selectArticle(a: ArticleModel) {
        $("#itemCodeInput").val(a.code);
        this.selectedArticle = a;

        if (this.selectedArticle != undefined) {
            $("#itemCodeInput").removeClass('is-invalid');
            this.basePrice = 0;
            this.iForm.basePrice.setValue(undefined);

            var groupTaxId = this.groups.find(x => x.id == this.selectedArticle.groupId).taxId;
            $("#add_item_taxId").val(groupTaxId).trigger("change");
        }
    }
    selectArticleByCode(event: KeyboardEvent, c: string) {
        if (event.key != 'Enter')
            return;

        event.preventDefault();
        this.selectedArticle = this.itemLookupComponent.articles.find(x => x.code == c);

        if (this.selectedArticle != undefined) {
            $("#itemCodeInput").removeClass('is-invalid');
            this.iForm.basePrice.setValue(0);
            this.iForm.basePrice.setValue(undefined);
            var groupTaxId = this.groups.find(x => x.id == this.selectedArticle.groupId).taxId;
            $("#add_item_taxId").val(groupTaxId).trigger("change");
        }
    }
    enterOnRow(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            var element = event.target as HTMLElement;
            element.click();
        }
    }

    fiscalize() {
        if (this.data.getFiscalType() == undefined) {
            toastr.options = this.data.toastrOptions;
            toastr.error('_Fiscal_config_not_set', this.translate.instant("_Error"));
            return;
        }

        if (this.creatingInvoice.articles.length==0) {
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant('_No_articles_fiscalize_text'), this.translate.instant("_Error"));
            return;
        }

        var question_text = this.translate.instant("_Fiscalize_invoice_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            toastr.options = this.data.toastrOptions;
            if (result.isConfirmed) {
                var obj = { clientId: this.data.user.clientId, outgoingInvoiceId: this.creatingInvoice.id };
                try {
                    var response = await this.outgoingInvoiceRest.fiscalize(obj);
                    var extension = this.data.getFiscalType() == 'TRI' ? '.xml' : '.inp';
                    var codeTransform = this.creatingInvoice.outgoingInvoiceNumber.replace('/', '-');
                    this.outgoingInvoiceRest.saveFile(response, 'stampatifiskalniracun.' + codeTransform, extension);
                } catch (err) {

                    if (err.error != undefined) {
                        toastr.error(err.error['Message'], this.translate.instant("_Error"));
                    } else {
                        toastr.error(err.error['_Request_failed_text'], this.translate.instant("_Error"));
                    }
                }
                toastr.success(this.translate.instant('_Fiscalized_text'), this.translate.instant("_Success"));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    storn() {
        if (this.data.getFiscalType() == undefined) {
            toastr.options = this.data.toastrOptions;
            toastr.error('_Fiscal_config_not_set', this.translate.instant("_Error"));
            return;
        }

        var question_text = this.translate.instant("_Storn_invoice_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            toastr.options = this.data.toastrOptions;
            if (result.isConfirmed) {
                var obj = { clientId: this.data.user.clientId, outgoingInvoiceId: this.creatingInvoice.id };
                try {
                    var response = await this.outgoingInvoiceRest.annul(obj);
                    var extension = this.data.getFiscalType() == 'TRI' ? '.xml' : '.inp';
                    this.outgoingInvoiceRest.saveFile(response, 'Annulling Outgoing Invoice ' + this.creatingInvoice.outgoingInvoiceNumber, extension);
                } catch (err) {

                    if (err.error != undefined) {
                        toastr.error(err.error['Message'], this.translate.instant("_Error"));
                    } else {
                        toastr.error(err.error['_Request_failed_text'], this.translate.instant("_Error"));
                    }
                }
                toastr.success(this.translate.instant('_Storned_text'), this.translate.instant("_Success"));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchInvoices();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchInvoices();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchInvoices();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchInvoices();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchInvoices();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}