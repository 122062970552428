import { IncomingArticleModel } from "./incomingArticle.model";
import { InvoiceCustomerPaymentModel } from "./invoice-customer-payment.model";

export class IncomingInvoiceModel{
    id:string;
    clientId:number;
    incomingInvoiceNumber:string;
    billNumber:string;
    evidentialNumber:string;
    createdAt:Date;
    billDate:Date;
    arrivalDate:Date;
    documentNumber:number;
    customDocumentNumber:number;
    supplierId:number;
    supplierName:string;
    supplierIDNumber:string;
    supplierPDVNumber:string;
    supplierAddress:string;
    paymentTypeId:number;
    currencyId:number;
    warehouseId:number;
    note:string;
    amountWithoutTax:number;
    taxAmount:number;
    articles:IncomingArticleModel[];
    paidOff:boolean;
    totalDebtAmount:number;
    totalPaidAmount:number;
    payments:InvoiceCustomerPaymentModel[];
    invoiceDiscount:number;

    totalNetoNC:number;
    totalNC:number;
    totalVPC:number;
    totalMPC:number;
    totalMarginAmount:number;
    totalTaxAmount:number;
    totalDiscountAmount:number;
}