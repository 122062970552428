import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleComponent } from './components/article/article.component';
import { GroupComponent } from './components/group/group.component';
import { GroupTypeComponent } from './components/groupType/groupType.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RetailPlaceComponent } from './components/retailPlace/retailPlace.component';
import { UserAccountComponent } from './components/userAccount/userAccount.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CustomerSuppliersComponent } from './components/customer-suppliers/customer-suppliers.component';
import { OutgoingInvoiceComponent } from './components/documents/outgoing-invoice/outgoing-invoice.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { TaxComponent } from './components/tax/tax.component';
import { IncomingInvoiceComponent } from './components/documents/incoming-invoice/incoming-invoice.component';
import { WarehouseStateComponent } from './components/warehouse-state/warehouse-state.component';
import { RetailBillComponent } from './components/reports/retail-bill/retail-bill.component';
import { WarehouseTransferComponent } from './components/documents/warehouse-transfer/warehouse-transfer.component';
import { DispatchNoteComponent } from './components/documents/dispatch-note/dispatch-note.component';
import { ProductionDocumentComponent } from './components/documents/production-document/production-document.component';
import { PaymentTypeComponent } from './components/payment-type/payment-type.component';
import { StocktakingComponent } from './components/documents/stocktaking/stocktaking.component';
import { RecapitulationOutgoingComponent } from './components/reports/recapitulation-outgoing/recapitulation-outgoing.component';
import { RecapitulationIncomingComponent } from './components/reports/recapitulation-incoming/recapitulation-incoming.component';
import { RecapitulationDispatchComponent } from './components/reports/recapitulation-dispatch/recapitulation-dispatch.component';
import { RecapitulationTransferComponent } from './components/reports/recapitulation-transfer/recapitulation-transfer.component';
import { RecapitulationProductionComponent } from './components/reports/recapitulation-production/recapitulation-production.component';
import { ArticleCardComponent } from './components/reports/article-card/article-card.component';
import { SalesArticleComponent } from './components/reports/sales-article/sales-article.component';
import { SalesOperatorComponent } from './components/reports/sales-operator/sales-operator.component';
import { SalesOperatorDateComponent } from './components/reports/sales-operator-date/sales-operator-date.component';
import { SalesCustomerComponent } from './components/reports/sales-customer/sales-customer.component';
import { WarehouseDischargeComponent } from './components/reports/warehouse-discharge/warehouse-discharge.component';
import { ShiftClosingComponent } from './components/reports/shift-closing/shift-closing.component';
import { FiscalizationSettingsComponent } from './components/fiscalization-settings/fiscalization-settings.component';
import { StocktakingDifferenceComponent } from './components/documents/stocktaking-difference/stocktaking-difference.component';
import { PrintFiscalReportComponent } from './components/reports/fiscalization/print-fiscal-report/print-fiscal-report.component';
import { MoneyDepositComponent } from './components/reports/fiscalization/money-deposit/money-deposit.component';
import { MoneyWithdrawalComponent } from './components/reports/fiscalization/money-withdrawal/money-withdrawal.component';
import { PrintDuplicateComponent } from './components/reports/fiscalization/print-duplicate/print-duplicate.component';
import { MeasureComponent } from './components/measure/measure.component';
import { FiscalReportsComponent } from './components/fiscal-reports/fiscal-reports.component';
import { SalesOperatorPdfViewerComponent } from './components/reports/sales-operator/sales-operator-pdf/sales-operator-pdf.component';
import { SalesCustomerPdfViewerComponent } from './components/reports/sales-customer/sales-customer-pdf/sales-customer-pdf.component';
import { RecapitulationIncomingPdfViewerComponent } from './components/reports/recapitulation-incoming/recapitulation-incoming-pdf/recapitulaltion-incoming-pdf.component';
import { ArticleCardPdfViewerComponent } from './components/reports/article-card/article-card-pdf/article-card-pdf.component';
import { RecapitulationOutgoingPdfViewerComponent } from './components/reports/recapitulation-outgoing/recapitulation-outgoing-pdf/recapitulation-outgoing-pdf.component';
import { RecapitulationDispatchPdfViewerComponent } from './components/reports/recapitulation-dispatch/recapitulation-dispatch-pdf/recapitulation-dispatch-pdf.component';
import { RecapitulationTransferPdfViewerComponent } from './components/reports/recapitulation-transfer/recapitulation-transfer-pdf/recapitulation-transfer-pdf.component';
import { RecapitulationProductionPdfViewerComponent } from './components/reports/recapitulation-production/recapitulation-production-pdf/recapitulation-production-pdf.component';
import { DispatchNotePdfViewerComponent } from './components/documents/dispatch-note/dispatch-note-pdf/dispatch-note-pdf.component';
import { DispatchNoteWithoutPricesPdfViewerComponent } from './components/documents/dispatch-note/without-prices-pdf/dispatch-note-without-prices-pdf.component';
import { IncomingInvoicePdfViewerComponent } from './components/documents/incoming-invoice/incoming-invoice-pdf/incoming-invoice-pdf.component';
import { OutgoingInvoicePdfViewerComponent } from './components/documents/outgoing-invoice/outgoing-invoice-pdf/outgoing-invoice-pdf.component';
import { DispatchNoteFromOutgoingInvoicePdfViewerComponent } from './components/documents/outgoing-invoice/dispatch-note-pdf/dispatch-note-pdf.component';
import { ProductionDocumentPdfViewerComponent } from './components/documents/production-document/production-document-pdf/production-document-pdf.component';
import { StocktakingDifferencePdfViewerComponent } from './components/documents/stocktaking-difference/stocktaking-difference-pdf/stocktaking-difference-pdf.component';
import { WarehouseTransferPdfViewerComponent } from './components/documents/warehouse-transfer/warehouse-transfer-pdf/warehouse-transfer-pdf.component';
import { SignOffModel } from './models/documents/signOff.model';
import { SignOffComponent } from './components/documents/sign-off/sign-off.component';
import { RecapitulationSignOffComponent } from './components/reports/recapitulation-sign-off/recapitulation-sign-off.component';
import { RecapitulationSignOffArticlesComponent } from './components/reports/recapitulation-sign-off-articles/recapitulation-sign-off-articles.component';
import { RecapitulationStocktakingDiffComponent } from './components/reports/recapitulation-stocktaking-diff/recapitulation-stocktaking-diff.component';
import { ConsolidatedInvoiceComponent } from './components/documents/consolidated-invoice/consolidated-invoice.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },

  //client management
  { path: 'users', component: UserAccountComponent },
  { path: 'retail-place', component: RetailPlaceComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'fiscalization-settings', component: FiscalizationSettingsComponent },

  //general data
  { path: 'articles', component: ArticleComponent },
  { path: 'groups', component: GroupComponent },
  { path: 'group-types', component: GroupTypeComponent },
  { path: 'measures', component: MeasureComponent },
  { path: 'customer-supplier', component: CustomerSuppliersComponent},
  { path: 'warehouse', component: WarehouseComponent},
  { path: 'tax', component: TaxComponent},
  { path: 'payment-type', component: PaymentTypeComponent},

  //documents
  { path: 'outgoing-invoice', component: OutgoingInvoiceComponent},
  { path: 'outgoing-invoice-pdf', component: OutgoingInvoicePdfViewerComponent},
  { path: 'outgoing-invoice-dispatch-note-pdf', component: DispatchNoteFromOutgoingInvoicePdfViewerComponent},

  { path: 'incoming-invoice', component: IncomingInvoiceComponent},
  { path: 'incoming-invoice-pdf', component: IncomingInvoicePdfViewerComponent},
  { path: 'dispatch-note', component: DispatchNoteComponent},
  { path: 'dispatch-notes-pdf', component: DispatchNotePdfViewerComponent},
  { path: 'dispatch-notes-without-prices-pdf', component: DispatchNoteWithoutPricesPdfViewerComponent},
  { path: 'warehouse-transfer', component: WarehouseTransferComponent},
  { path: 'warehouse-transfer-pdf', component: WarehouseTransferPdfViewerComponent},

  { path: 'production-document', component: ProductionDocumentComponent},
  { path: 'production-document-pdf', component: ProductionDocumentPdfViewerComponent},

  { path: 'stocktaking', component: StocktakingComponent},
  { path: 'stocktaking-difference', component: StocktakingDifferenceComponent},
  { path: 'stocktaking-difference-pdf', component: StocktakingDifferencePdfViewerComponent},

  { path: 'sign-off', component: SignOffComponent},


  //reports
  { path: 'warehouse-state', component: WarehouseStateComponent},
  { path: 'retail-bill', component: RetailBillComponent},
  { path: 'recapitulation-outgoing', component: RecapitulationOutgoingComponent},
  { path: 'recapitulation-outgoing-pdf', component: RecapitulationOutgoingPdfViewerComponent},
  { path: 'recapitulation-incoming', component: RecapitulationIncomingComponent},
  { path: 'recapitulation-incoming-pdf', component: RecapitulationIncomingPdfViewerComponent},
  { path: 'recapitulation-dispatch', component: RecapitulationDispatchComponent},
  { path: 'recapitulation-dispatch-pdf', component: RecapitulationDispatchPdfViewerComponent},
  { path: 'recapitulation-transfer', component: RecapitulationTransferComponent},
  { path: 'recapitulation-transfer-pdf', component: RecapitulationTransferPdfViewerComponent},
  { path: 'recapitulation-production', component: RecapitulationProductionComponent},
  { path: 'recapitulation-production-pdf', component: RecapitulationProductionPdfViewerComponent},
  { path: 'recapitulation-sign-off', component: RecapitulationSignOffComponent},
  { path: 'recapitulation-sign-off-articles', component: RecapitulationSignOffArticlesComponent},
  { path: 'recapitulation-stocktaking-difference', component: RecapitulationStocktakingDiffComponent},
  { path: 'consolidated-invoice', component: ConsolidatedInvoiceComponent},

  { path: 'article-card', component: ArticleCardComponent},
  { path: 'article-card-pdf', component: ArticleCardPdfViewerComponent},
  { path: 'sales-article', component: SalesArticleComponent},
  { path: 'sales-operator', component: SalesOperatorComponent},
  { path: 'sales-operator-pdf', component: SalesOperatorPdfViewerComponent },
  { path: 'sales-operator-date', component: SalesOperatorDateComponent},
  { path: 'sales-customer', component: SalesCustomerComponent},
  { path: 'sales-customer-pdf', component: SalesCustomerPdfViewerComponent },
  { path: 'warehouse-discharge', component: WarehouseDischargeComponent},
  { path: 'shift-closing', component: ShiftClosingComponent},
  { path: 'print-fiscal-report', component: PrintFiscalReportComponent},
  { path: 'money-deposit', component: MoneyDepositComponent},
  { path: 'money-withdrawal', component: MoneyWithdrawalComponent},
  { path: 'print-duplicate', component: PrintDuplicateComponent},
  { path: 'fiscal-reports', component: FiscalReportsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }