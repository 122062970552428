import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { ClientModel } from "../models/client.model";
import { LoginModel } from "../models/login.model";
import { DataService } from "./data.service";
import { GeneralUserSettingsModel } from "../models/generalUserSettings.model";

@Injectable()
export class ClientRestService {
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async login(obj: LoginModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ClientUserAccount/Login", obj).toPromise() as any;
    }

    updateClient(obj: ClientModel) {
        throw new Error('Method not implemented.');
    }

    async getUserSettings(clientUserAccountId:number){
        var config = environment.apiUrl;
        var params = "?ClientUserAccountId="+clientUserAccountId;
        return await this.http.get(config + "api/ClientUserAccount/GetGeneralUserSettings" + params,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

    async updateUserSettings(obj:any){
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ClientUserAccount/UpdateGeneralUserSettings", obj,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

}