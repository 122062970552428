import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleRestService } from 'src/app/services/article.service';
import { CustomerSupplierRestService } from 'src/app/services/customerSupplier.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { OutgoingInvoiceRestService } from 'src/app/services/outgoing-invoice.service';
import { PaymentTypeRestService } from 'src/app/services/payment-type.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { GroupModel } from 'src/app/models/group.model';
import { TranslateService } from 'src/app/translate';
import { ArticleModel } from 'src/app/models/article.model';
import { TaxModel } from 'src/app/models/tax.model';
import { PaymentTypeModel } from 'src/app/models/paymentType.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { CustomerSuppliersModel } from 'src/app/models/customerSupplier.model';
import toastr from 'toastr';
import { OutgoingInvoiceModel } from 'src/app/models/documents/outgoingInvoice.model';
import { SoldArticleModel } from 'src/app/models/documents/soldArticle.model';
import { CurrencyModel } from 'src/app/models/currency.model';
import { CurrencyRestService } from 'src/app/services/currency.service';
import { InvoiceCustomerPaymentModel } from 'src/app/models/documents/invoice-customer-payment.model';
import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import { ReportRestService } from 'src/app/services/reports.service';
import { RecapitulationOutgoingModel } from 'src/app/models/reports/recapitulationOutgoing.model';
import { Router } from '@angular/router';
import { DocumentPdfComponent } from '../../documents/document-pdf/document-pdf.component';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;
@Component({
  selector: 'app-recapitulation-stocktaking-diff',
  templateUrl: './recapitulation-stocktaking-diff.component.html',
})
export class RecapitulationStocktakingDiffComponent  {
  public selectedRow: string = undefined;
  public stocktakingDiffs: RecapitulationOutgoingModel[];
  public groups: GroupModel[];
  public customers: CustomerSuppliersModel[];
  public taxes: TaxModel[];
  public currencies: CurrencyModel[];
  public paymentTypes: PaymentTypeModel[];
  public warehouses: WarehouseModel[];
  public retailPlaces: RetailPlaceModel[];
  public emptyData: boolean = false;
  public searchForm: UntypedFormGroup;
  public addForm: UntypedFormGroup;
  dateFrom: string;
  dateTo: string;
  customerName: string = '';
  public pdfSource: any;
  public isSubmitted: boolean;
  public selectedInvoice: RecapitulationOutgoingModel;
  public selectedInvoiceTax: TaxModel;
  public selectedCurrency: string;
  public headerFormEnabled: boolean;
  tableSetUp: boolean = false;

  @ViewChild('headerTrigger') headerTrigger: ElementRef;
  @ViewChild('listTrigger') listTrigger: ElementRef;
  @ViewChild('firstHeaderForm') firstHeaderFormElement: ElementRef;
  @ViewChild('selectFilterCustomerId') selectFilterCustomerId: ElementRef;

  public onHeaderTab: boolean = false;
  public filterCustomerId: any = undefined;
  @ViewChild("selectFilterWarehouseId") selectFilterWarehouseId: ElementRef;
  public warehouseIds: number[];
  @ViewChild(DocumentPdfComponent) documentPdfComponent: DocumentPdfComponent;
  public showPdf:boolean=false;

  //pagination
  public page: number = 1;
  public perPage: number;
  public totalData: number;
  public maxPage: number;
  public pages: number[] = [];
  public offset: number = 5;
  public firstPage: number = 1;
  public lastPage: number;
  public counter: number = 0;
  public firstLoad: boolean = true;
  public resizeObserver: ResizeObserver;

  constructor(
      private reportRestService: ReportRestService,
      private customerRestService: CustomerSupplierRestService,
      private currencyRestService: CurrencyRestService,
      private datePipe: DatePipe,
      private _changeRef: ChangeDetectorRef,
      public data: DataService,
      private formBuilder: UntypedFormBuilder,
      private translate: TranslateService,
      private warehouseRestService: WarehouseRestService,
      private router: Router
  ) {
      this.page = 1;
      this.perPage = 50;
  }

  ngOnInit(): void {
      KTMenu.createInstances();
      this.fetchStocktakingDiffs();
      this.fetchCustomers();
      this.fetchCurrencies();
      this.createSearchForm();
      this.fetchWarehouses();
  }

  async fetchWarehouses() {
      try {
          this.warehouses = await this.warehouseRestService.getWarehousesForClient(this.data.user.clientId);
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  formatSelectValue(val:string){
      if(val=='')
          return null;
      var split = (val.split(':')[1]).trim();
      return parseInt(split);
    }

  async createSearchForm() {
      this.searchForm = this.formBuilder.group({
          filterCustomerId: [null],
          filterWarehouseId: [null],
      })

      $("#filter_warehouseId").select2();
      $('#filter_warehouseId').on("change", function () {
          //   self.selectFilterWarehouseId.nativeElement.value = $(this).val();
          var values = $(this).val();
          self.warehouseIds = [];
          if (values != undefined && values.length > 0) {
              values.forEach(x => {
                  var val = self.formatSelectValue(x);
                  if(val!=null && !isNaN(val))
                      self.warehouseIds.push(val);
              });
          }
      });

      $("#recapOutgoing_filter_customerId").select2();
      $('#recapOutgoing_filter_customerId').on("change", function () {
          self.selectFilterCustomerId.nativeElement.value = $(this).val();
          if ($(this).val() == '')
              self.filterCustomerId = null;
          else
              self.filterCustomerId = $(this).val();
      });

      var start = new Date(new Date().getFullYear(), 0, 1);
      var end = new Date();
      var self = this;
      $("#kt_daterangepicker_filter").daterangepicker({
          startDate: start,
          endDate: end,
          autoApply: true,
          autoUpdateInput: true,
          locale: {
              format: 'DD/MM/YYYY',
              separator: '-'
          }
      });
      $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
          self.dateFrom = picker.startDate.format('YYYY-MM-DD');
          self.dateTo = picker.endDate.format('YYYY-MM-DD');
          $("#searchByCustomerInput").focus();
          self.fetchStocktakingDiffs();
      });
      $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
          picker.setStartDate(new Date());
          picker.setEndDate(new Date());
          self.dateFrom = picker.startDate.format('YYYY-MM-DD');
          self.dateTo = picker.endDate.format('YYYY-MM-DD');
          self.fetchStocktakingDiffs();
      });
      var im = new Inputmask("99/99/9999-99/99/9999");
      im.mask($("#kt_daterangepicker_filter"));
  }

  async resetFilter() {
      this.searchForm.reset();
      $("#recapOutgoing_filter_customerId").val(null).trigger("change");
      $("#filter_warehouseId").val(null).trigger("change");
      var pickerElement = $("#kt_daterangepicker_filter");
      var today = new Date();
      $(pickerElement).data('daterangepicker').setStartDate(new Date(today.getFullYear(), 0, 1, 0, 0 ,0));
      $(pickerElement).data('daterangepicker').setEndDate(new Date());
      $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
  }

  filterByDate(event: KeyboardEvent) {
      if (event.key == "Enter" || event.key == "Tab") {
          var pickerElement = $("#kt_daterangepicker_filter");
          $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
      }
  }

  async fetchCustomers() {
      try {
          var response = await this.customerRestService.getCustomerSuppliers(this.data.user.clientId, '', null, null, true, null, null, 1, 999999);
          this.customers = response.data;
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  async fetchCurrencies() {
      try {
          this.currencies = await this.currencyRestService.getCurrencies();
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  async fetchStocktakingDiffs() {
      try {
          this.emptyData = false;
          if (this.dateFrom == undefined)
              this.dateFrom = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
          if (this.dateTo == undefined)
              this.dateTo = this.datePipe.transform((new Date()), 'yyyy-MM-dd');

          var response = await this.reportRestService.getRecapitulationsStocktakingDifference(this.data.user.clientId, this.warehouseIds, this.dateFrom, this.dateTo, this.page, this.perPage);
          this.stocktakingDiffs = response.data
          this.totalData = response.totalCount;
          if (this.totalData == 0)
              this.emptyData = true;
          else
              this.emptyData = false

          if (this.firstLoad) {
              this.maxPage = response.totalPages;
              this.maxPage = Math.floor(this.maxPage);
              this.firstPage = 1;
              if (this.maxPage > 5)
                  this.lastPage = 5;
              else
                  this.lastPage = this.maxPage;
              this.enablePrevious();

              this.firstLoad = false;
          }
          this.initPages();
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  async searchInvoices(customerName: string = '') {
      this.customerName = customerName;
      this.firstLoad = true;
      this.page = 1;
      this.fetchStocktakingDiffs();
  }

  toggleDocNav(toggle: boolean) {
      this.onHeaderTab = toggle;
  }

  selectInvoice(invoice: RecapitulationOutgoingModel, switchToHeaderTab: boolean = true) {
      if (switchToHeaderTab)
          this.headerTrigger.nativeElement.click();

      this.selectedInvoice = invoice;
      // this.selectedCurrency = this.currencies.find(x=>x.id==invoice.currencyId).code;
  }

  async selectNext() {
      var index = this.stocktakingDiffs.findIndex(x => x.documentId == this.selectedInvoice.documentId);

      if (index == 0) {
          if (this.page == 1)
              return;

          await this.prevpage();
          await this.fetchStocktakingDiffs();


          if (this.stocktakingDiffs.length > 0) {
              this.selectInvoice(this.stocktakingDiffs[this.stocktakingDiffs.length - 1]);
          }
      }
      else {
          this.selectInvoice(this.stocktakingDiffs[index - 1]);
      }
  }

  async selectPrevious() {
      var index = this.stocktakingDiffs.findIndex(x => x.documentId == this.selectedInvoice.documentId);
      if (index == (this.stocktakingDiffs.length - 1)) {
          if (this.page == this.maxPage)
              return;

          await this.nextPage();
          await this.fetchStocktakingDiffs();

          if (this.stocktakingDiffs.length > 0) {
              this.selectInvoice(this.stocktakingDiffs[0]);
          }
      }
      else {
          this.selectInvoice(this.stocktakingDiffs[index + 1]);
      }
  }

  exitDetails(redirectToHeader: boolean = false) {
      if (redirectToHeader)
          this.headerTrigger.nativeElement.click();
  }

  manualInputDate(id: string) {
      var pickerElement = $("#" + id);
      $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
  }

  selectOnFocus(id: string) {
      $("#" + id).select();
  }

  backToHeader() {
      $("#headerModalTrigger").click();
  }

  negateEnter(event: KeyboardEvent) {
      if (event.key == "Enter")
          event.preventDefault();
  }

  showList() {
      this.listTrigger.nativeElement.click();
  }

  async fetchStocktakingDiffsPDF() {
      try {
          var response = await this.reportRestService.getRecapitulationsStoctakingDifferencePDF(this.data.user.clientId, this.warehouseIds, this.dateFrom, this.dateTo, this.page, this.perPage);
          this.pdfSource = window.URL.createObjectURL(response);
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
          else {
              toastr.options = this.data.toastrOptions;
              toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
          }
      }
  }

  async exportPDF() {
      await this.fetchStocktakingDiffsPDF();
      if (this.pdfSource){
        //   this.router.navigate(['/recapitulation-outgoing-pdf'], { queryParams: { pdfSrc: this.pdfSource } });
          this.showPdf=true;
          this.documentPdfComponent.setSource(this.pdfSource);
      }
  }
  
  closePDF(){
      this.showPdf=false;
  }

  //pagination
  initPages() {
      this.pages = [];
      for (var i = this.firstPage; i <= this.lastPage; i++) {
          this.pages.push(i);
      }
  }

  nextPage() {
      if ($("#kt_customers_table_next").hasClass("disabled"))
          return;

      if (this.page == 1 && this.firstPage == 1) {
          this.page++;
      }
      else {
          this.page++;
          if (this.counter == 1) {
              if (this.maxPage > (this.lastPage + this.offset)) {
                  this.firstPage++;
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }
              else if (this.maxPage > (this.firstPage + this.offset)) {
                  this.firstPage++;
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }
              else if (this.maxPage) {
                  this.firstPage = this.maxPage - this.offset;
                  this.lastPage = this.maxPage;
              }
              this.counter = 0;
          }
          else {
              if (this.maxPage > (this.lastPage + this.offset)) {
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else if (this.maxPage > (this.firstPage + this.offset)) {
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else {
                  this.firstPage = this.maxPage - this.offset;
                  this.lastPage = this.maxPage;
              }
          }
          this.counter++;
      }
      this.fetchStocktakingDiffs();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  prevpage() {
      if ($("#kt_customers_table_previous").hasClass("disabled"))
          return;

      this.page--;
      if (this.counter == 1) {
          if (this.maxPage > this.lastPage + this.offset) {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          else {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          this.counter = 0;
      }
      else {
          if (this.maxPage > this.lastPage + this.offset) {
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          else {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
      }
      this.counter++;
      this.fetchStocktakingDiffs();
      this.enablePrevious();
      this._changeRef.detectChanges();

  }

  goToPage(p: number) {
      this.page = p;
      this.fetchStocktakingDiffs();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  morePages() {
      if ($("#kt_customers_table_morePages").hasClass("disabled"))
          return;
      if (this.maxPage > this.lastPage + this.offset) {
          this.firstPage = this.lastPage;
          this.lastPage = this.firstPage + this.offset;
      }
      else {
          this.firstPage = this.maxPage - this.offset;
          this.lastPage = this.maxPage;
      }
      this.page = this.firstPage;
      this.fetchStocktakingDiffs();
      this.initPages();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  perPageHandler() {
      this.firstLoad = true;
      this.page = 1;
      this.fetchStocktakingDiffs();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  enablePrevious() {
      if (this.page > 1) {
          $(document).ready(function () {
              $("#kt_customers_table_previous").removeClass("disabled");
          })
      }
      else if (this.page == 1) {
          $(document).ready(function () {
              $("#kt_customers_table_previous").addClass("disabled");
          })
      }

      if (this.page > 1 && this.page != this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_next").removeClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
          })
      }

      if (this.maxPage == 0) {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
              $("#morePages").addClass("disabled");
              $("#kt_customers_table_previous").addClass("disabled");
          })
      }

      if (this.page == this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_next").removeClass("disabled");
          })
      }

      if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_morePages").addClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_morePages").removeClass("disabled");
          })
      }
  }
}