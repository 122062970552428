import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DataService } from "./data.service";

@Injectable()
export class CurrencyRestService{
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async getCurrencies(){
        var config = environment.apiUrl;
        return await this.http.get(config + "api/PaymentType/GetCurrencies",
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }
}