export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
'_Login_text': 'Login to the system',
'_Username': 'Username',
'_Password': 'Password',
'_Login': 'Log in',
'_Error': 'Error',
'_General_data': 'General data',
'_Countries': 'Countries',
'_Home': 'Home',
'_Add_country': 'Add country',
'_Country_name': 'Country name',
'_Actions': 'Actions',
'_Edit': 'Edit',
'_No_results': 'No results',
'_No_results_text': 'It seems we cannot find items matching your search. Try adjusting your search or filter to find what you are looking for.',
'_Submit': 'Save',
'_Discard': 'Discard',
'_Added_country_text': 'You have successfully added a new country!',
'_Edit_country': 'Edit country',
'_Updated_country_text': 'You have successfully edited the selected country!',
'_Towns': 'Towns',
'_Added_town_text': 'You have successfully added a new town!',
'_Updated_town_text': 'You have successfully edited the selected town!',
'_Add_town': 'Add town',
'_City_name': 'City name',
'_Country': 'Country',
'_Select_country': 'Select country',
'_Group_types': 'Group types',
'_Add_group_type': 'Add group type',
'_Group_type_name': 'Group type name',
'_Article_Name': 'Article name',
'_Client_name': 'Client name',
'_Filter_options': 'Filter',
'_Client': 'Client',
'_Articles': 'Articles',
'_Groups': 'Groups',
'_Dashboard': 'Dashboard',
'_User_management': 'User management',
'_Admins': 'Administrators',
'_Clients': 'Clients',
'_Users': 'Users',
'_Customers_suppliers': 'Customers and suppliers',
'_Reset': 'Reset',
'_Apply': 'Search',
'_Success': 'Success',
'_Edit_town': 'Edit town',
'_Added_group_type_text': 'You have successfully added a new group type!',
'_Edit_group_type': 'Edit group type',
'_Updated_group_text':'You have successfully edited the selected group!',
'_Updated_group_type_text': 'You have successfully edited the selected group type!',
'_Company_name': 'Company',
'_Address': 'Address',
'_Town': 'Town',
'_Phone_number': 'Phone number',
'_Email': 'Email',
'_Pdv_number': 'VAT number',
'_Price': 'Price',
'_Licence_expiration_date': 'License expiration',
'_Is_active': 'Status',
'_Add_client': 'Add client',
'_Active': 'Active',
'_Inactive': 'Inactive',
'_Point_of_sale': 'Cash registers',
'_Retail_place': 'Retail locations',
'_Details': 'Details',
'_Search_clients': 'Search clients',
'_Date_from': 'Date from',
'_Date_to': 'Date to',
'_Client_deactivation_question_text': 'Do you really want to deactivate the selected client? Their account and access will be suspended!',
'_Yes_text': 'Yes',
'_No_text': 'No',
'_Client_deactivated_text': 'You have successfully deactivated the selected client. Their accounts and access have been suspended!',
'_Deactivation_client_cancelled_text': 'You have canceled the deactivation of the selected client. The action was not performed!',
'_Warning': 'Warning',
'_Client_activation_question_text': 'Do you really want to activate the selected client?',
'_Client_activated_text': 'You have successfully activated the selected client. Their accounts and access have been activated!',
'_Activation_cancelled_text': 'You have canceled the activation of the selected client. The action was not performed!',
'_Licence_information': 'License information',
'_Bank_information': 'Bank information',
'_Order_number': 'Order number',
'_Number1': 'Number 1',
'_Number2': 'Number 2',
'_First_name': 'First name',
'_Last_name': 'Last name',
'_User_Role': 'User role',
'_Status': 'Status',
'_Add_admin': 'Add administrator',
'_Group_name': 'Group name',
'_Add_group': 'Add group',
'_Group_type': 'Group type',
'_Added_group_text': 'You have successfully added a new group!',
'_Search_groups': 'Search groups',
'_Search_admins': 'Search administrators',
'_Admin_role': 'Administrator role',
'_Admin_deactivation_question_text': 'Do you really want to deactivate the selected administrator? Their account and access will be suspended!',
'_Admin_deactivated_text': 'You have successfully deactivated the selected administrator. Their accounts and access have been suspended!',
'_Deactivation_admin_cancelled_text': 'You have canceled the deactivation of the selected administrator. The action was not performed!',
'_Admin_activation_question_text': 'Do you really want to activate the selected administrator?',
'_Admin_activated_text': 'You have successfully activated the selected administrator. Their accounts and access have been activated!',
'_Activation_admin_cancelled_text': 'You have canceled the activation of the selected administrator. The action was not performed!',
'_Middle_name': 'Middle name',
'_Repeat_password': 'Repeat password',
'_Added_admin_text': 'You have successfully added a new administrator!',
'_Updated_admin_text': 'You have successfully edited the selected administrator!',
'_Edit_admin': 'Edit administrator',
'_Added_client_text': 'You have successfully added a new client!',
'_Edit_client': 'Edit client',
'_Updated_client_text': 'You have successfully edited the selected client!',
'_Select_all': 'Select all',
'_User_role': 'User role',
'_Add_user': 'Add user',
'_User_deactivation_question_text': 'Do you really want to deactivate the selected user? Their account and access will be suspended!',
'_User_deactivated_text': 'You have successfully deactivated the selected user. Their accounts and access have been suspended!',
'_Deactivation_user_cancelled_text': 'You have canceled the deactivation of the selected user. The action was not performed!',
'_User_activation_question_text': 'Do you really want to activate the selected user?',
'_User_activated_text': 'You have successfully activated the selected user. Their accounts and access have been activated!',
'_Activation_user_cancelled_text': 'You have canceled the activation of the selected user. The action was not performed!',
'_Edit_user': 'Edit user',
'_Search_users': 'Search users',
'_Retail_name': 'Retail location name',
'_Search_retail_places': 'Search retail locations',
'_Add_retail_place': 'Add retail location',
'_Client_management': 'Client management',
'_Added_retailPlace_text': 'You have successfully added a new retail location!',
'_Edit_retail_place': 'Edit retail location',
'_Updated_retailPlace_text': 'You have successfully edited the selected retail location!',
'_Updated_user_text': 'You have successfully edited the selected user!',
'_Added_user_text': 'You have successfully added a new user!',
'_Search_towns': 'Search towns',
'_Search_countries': 'Search countries',
'_Add_point_of_sale': 'Add point of sale',
'_Point_of_sale_name': 'Point of sale name',
'_Is_mobile': 'Is mobile',
'_Added_pointOfSale_text': 'You have successfully added a new point of sale!',
'_Quick_links': 'Quick access',
'_Manage_clients': 'Manage clients',
'_Manage': 'View',
'_English': 'English',
'_Bosnia_and_Herzegovine': 'Bosnian',
'_Cancel_question_text': 'Are you sure you want to close?',
'_Yes_cancel_text': 'Yes, close',
'_No_return_text': 'No, go back',
'_Sign_out': 'Sign out',
'Language': 'Language',
'My profile': 'My profile',
'_Edit_point_of_sale': 'Edit point of sale',
'_Updated_pointOfSale_text': 'You have successfully edited the selected point of sale!',
'_Search_point_of_sale': 'Search points of sale',
'_Account_settings': 'Account settings',
'_Profile_details': 'Profile details',
'_First_Last_name': 'First and last name',
'_Sign_in_method': 'Login information',
'_Discard_changes_question_text': 'Are you sure you want to discard the changes?',
'_Yes_discard_text': 'Yes, discard',
'_Updated_profile_text': 'You have successfully updated your user details!',
'_Add_article': 'Add article',
'_Article_name': 'Article name',
'_Code': 'Code',
'_Group': 'Group',
'_Measure': 'Unit',
'_Description': 'Description',
'_NC': 'NC',
'_MPC': 'MPC',
'_VPC': 'VPC',
'_RUC': 'RUC',
'_Margin': 'Margin',
'_Min_amount': 'Minimum amount',
'_Amount_article': 'Quantity',
'_Happy_hour_price': 'Happy hour price',
'_Is_visible': 'Visibility',
'_Visible': 'Visible',
'_Hidden': 'Hidden',
'_Is_normative': 'Requires standardization',
'_Normative_data': 'Normative data',
'_Add_normative': 'Add normative',
'_Normative_name': 'Normative name',
'_Is_half_product': 'Semi-product',
'_Bar_code': 'Bar code',
'_Client_data_management': 'Client data management',
'_Repeat_PIN': 'Repeat PIN',
'_Edit_group': 'Edit group',
'_Tax': 'Tax',
'_Retail_place_name': 'Retail location name',
'_Article_active_changed': 'The active status of the article has been changed.',
'_Article_visibility_question_text': 'Do you want to change the visibility of the article?',
'_Article_visibility_changed': 'The visibility of the article has been changed.',
'_Visibility_article_cancelled_text': 'The change in the visibility of the article was canceled.',
'_Visibility': 'Visibility',
'_Standardize': 'Standardize',
'_Priority': 'Priority',
'_Remove': 'Remove',
'_Half_product_name': 'Semi-product name',
'_Add_half_product': 'Add semi-product',
'_Half_product_data': 'Semi-product data',
'_Added_article_text': 'You have successfully added a new article!',
'_Article_details': 'Article details',
'_Edit_article': 'Edit article',
'_Edited_article_text': 'Article changed',
'_Add_barcode': 'Add barcode',
'Duplicate barcode': 'Barcode already exists',
'Barcode must be between 12 and 15 digits': 'Barcode must be between 12 and 15 digits',
'_Article_deactivated_text': 'Article has been successfully deactivated.',
'_Article_activated_text': 'Article has been successfully activated.',
'_Deactivation_article_cancelled_text': 'Deactivation of the article has been canceled. The article is still active.',
'_Activation_article_cancelled_text': 'Activation of the article has been canceled. The article is still inactive.',
'_Article_deactivation_question_text': 'Are you sure you want to deactivate the article?',
'_Article_activation_question_text': 'Are you sure you want to activate the article?',
'_Article_Code': 'Code',
'_Password_changed_text': 'Password has been changed.',
'_PIN_changed_text': 'PIN has been changed.',
'_Change_PIN': 'Change PIN',
'_Customers': 'Customers',
'_Search_customers': 'Search customers',
'_Add_customer': 'Add customer',
'_Edit_customer': 'Edit customer',
'_ID_Number': 'ID number',
'_Customer_name': 'Customer name',
'_Suppliers': 'Suppliers',
'_Search_Suppliers': 'Search suppliers',
'_Add_Supplier': 'Add supplier',
'_Edit_Supplier': 'Edit supplier',
'_PDV_number': 'VAT number',
'_Card_number': 'Card number',
'_Name': 'Name',
'_CustomersAndSuppliers': 'Customers and suppliers',
'_Add_legal': 'Add legal entity',
'_Add_physical': 'Add natural person',
'_JMBG': 'JMBG',
'_Is_Supplier': 'Supplier',
'_Is_Customer': 'Customer',
'_Phone': 'Phone',
'_Employer': 'Employer',
'_Documents': 'Documents',
'_Retail_bills': 'Retail bills',
'_Bill_number': 'Bill number',
'_Date': 'Date',
'_Total': 'Total',
'_Search_bills': 'Search by customer',
'_Add_bill': 'Add bill',
'_Total_price': 'Total amount',
'_Customer': 'Customer',
'_Payment_type': 'Payment method',
'_Payment_types': 'Payment methods',
'_Add_payment_type': 'Add payment method',
'_Edit_payment_type': 'Edit payment method',
'_FIS_number': 'FIS number',
'_Warehouse': 'Warehouse',
'_Discount': 'Discount',
'_Header': 'Header',
'_Items': 'Items',
'_Added_retail_bill_text': 'Bill added',
'_Bill_details': 'Bill details',
'_Outgoing_invoices': 'Outgoing invoices',
'_Search_by_customer': 'Search by customer',
'_Bill_date': 'Bill date',
'_Arrival_date': 'Due date',
'_Outgoing_invoice_details': 'Outgoing invoice details',
'_Submit_document': 'Save document',
'_Discard_document': 'Discard document',
'_Add_items': 'Add items',
'_Base_price': 'Base price',
'_Final_price': 'Final price',
'_Cancel_question_text_document': 'Closing will discard the document. Are you sure you want to close?',
'_Back_to_header': 'Back to header',
'_Add_outgoing_invoice': 'Add outgoing invoice',
'_Edit_outgoing_invoice': 'Edit outgoing invoice',
'_Deleted_outgoing_invoice_text': 'Outgoing invoice deleted',
'_Paid_off': 'Paid off',
'_Payments': 'Payments',
'_Amount': 'Amount',
'_Add_payment': 'Add payment',
'_Note': 'Note',
'_View': 'View',
'_Select_customer': 'Select customer',
'_Add_header': 'Add header',
'_Select_article': 'Select article',
'_Article_state': 'Article state',
'_Article_info': 'Article information',
'_Article': 'Article',
'_Back': 'Back',
'_Created_date': 'Creation date',
'_Invoice_discount': 'Invoice discount',
'_Invoice_number': 'Invoice number',
'_Currency': 'Currency',
'_Selected_customer': 'Selected customer',
'_Selected_article': 'Selected article',
'_Adding_items_to_invoice_number': 'Adding items to invoice no.',
'_for_customer': 'for customer',
'_Save_header': 'Save header',
'_Save_items': 'Save items',
'_Edit_header': 'Edit header',
'_Edit_items': 'Edit items',
'_Previous': 'Previous',
'_Next': 'Next',
'_Total_invoice_amount': 'Total invoice amount',
'_Paid_amount': 'Paid amount',
'_Save_item': 'Save item',
'_Add_item': 'Add item',
'_Delete': 'Delete',
'_Delete_invoice_text': 'Are you sure you want to delete the invoice? This process will affect the warehouse.',
'_Save_changes': 'Save changes',
'_Discard_added_items': 'Discard new items',
'_Discard_item_changes': 'Discard item changes',
'_Warehouses': 'Warehouses',
'_Search_by_name': 'Search by name',
'_Add_warehouse': 'Add warehouse',
'_Edit_warehouse': 'Edit warehouse',
'_Added_warehouse_text': 'Warehouse successfully added',
'_Deleted_warehouse_transfer_text': 'Warehouse transfer deleted',
'_Taxes': 'Taxes',
'_Add_tax': 'Add tax',
'_Edit_tax': 'Edit tax',
'_PostDiscountPrice': 'Invoice price',
'_Added_outgoing_invoice_text': 'You have added an outgoing invoice',
'_Edited_outgoing_invoice_text': 'Changes saved',
'_Search_articles': 'Search articles',
'_Incoming_invoices': 'Incoming invoices',
'_Add_incoming_invoice': 'Add incoming invoice',
'_Added_incoming_invoice_text': 'You have added an incoming invoice',
'_Deleted_incoming_invoice_text': 'Incoming invoice deleted',
'_Evidential_number': 'Evidential number',
'_Incoming_invoice_number': 'Incoming invoice number',
'_for_supplier': 'for supplier',
'_Margin_amount': 'Margin (amount)',
'_Unit_price': 'Unit price',
'_Amount_without_tax': 'Amount without tax',
'_Tax_amount': 'Tax amount',
'_Supplier': 'Supplier',
'_Select_supplier': 'Select supplier',
'_Selected_supplier': 'Selected supplier',
'_Search_by_supplier': 'Search by supplier',
'_Search_suppliers': 'Search suppliers',
'_BUF': 'BUF',
'_Supplier_name': 'Supplier name',
'_Edited_incoming_invoice_text': 'You have edited the incoming invoice',
'_Reports': 'Reports',
'_Warehouse_state': 'Warehouse state',
'_State': 'State',
'_Incoming': 'Incoming',
'_Outgoing': 'Outgoing',
'_Search_by_client': 'Search by client',
'_FIS': 'FIS',
'_Discounted_amount': 'Discount amount',
'_Exit_details': 'Exit details',
'_Remember_price_as': 'Remember price as',
'_Average': 'Average',
'_Last': 'Last',
'_Affect_price': 'Affects price',
'_Apply_invoice_prices': 'Apply prices',
'_Prices_updated_text': 'Prices changed',
'_Entry_price': 'Entry price',
'_Invoice_amount_not_matching_text': 'Invoice amount does not match the amount of items!',
'_Is_raw_group': 'Raw material group',
'_Updated_customer_text': 'You have successfully edited the selected customer.',
'_Updated_warehouse_text': 'You have successfully edited the selected warehouse.',
'_Updated_tax_text': 'You have successfully edited the selected tax.',
'_Added_tax_text': 'You have added a new tax.',
'_Added_warehouse_transfer_text': 'You have added a warehouse transfer',
'_Edited_warehouse_transfer_text': 'You have edited the warehouse transfer',
'_Delete_warehouse_transfer_text': 'Are you sure you want to delete the warehouse transfer? This process will affect the warehouses.',
'_Warehouse_transfers': 'Warehouse transfers',
'_Warehouse_from': 'Source warehouse',
'_Warehouse_to': 'Destination warehouse',
'_Transfer_number': 'Warehouse transfer number',
'_Add_warehouse_transfer': 'Add warehouse transfer',
'_Adding_items_to_warehouse_transfer_number': 'Adding items to warehouse transfer no. ',
'_Normative_data_singular': 'Normative data',
'_Add_normative_data': 'Add/view normative data',
'_Edit_normative_data': 'Edit/view normative data',
'_Add_text': 'Add',
'_Quantity': 'Quantity',
'_JM': 'JM',
'_NV': 'NV',
'_Set_as_NC': 'Set as NC',
'_Set_price_message': 'Price set',
'_Dispatch_notes': 'Dispatch notes',
'_Add_dispatch_note': 'Add dispatch note',
'_DispatchNote_number': 'Dispatch note number',
'_Affect_warehouse': 'Affects warehouse',
'_Without_prices': 'Without prices',
'_Added_dispatch_note_text': 'You have successfully added a dispatch note',
'_Edited_dispatch_note_text': 'You have successfully edited the dispatch note',
'_DispatchNote_discount': 'Dispatch note discount',
'_Delete_dispatch_note_text': 'Do you want to delete the dispatch note?',
'_Deleted_dispatch_note_text': 'Dispatch note deleted',
'_Duplicate_normative_text': 'Raw material has already been added.',
'_Production_documents': 'Production orders',
'_Production_document_number': 'Production order number',
'_Add_production_document': 'Add production order',
'_Deleted_production_document_text': 'Production order deleted',
'_Adding_items_to_production_document_number': 'Adding items to production order no.',
'_Add_product_to_produce': 'Add item to produce',
'_Article_to_produce': 'Item to produce',
'_Article_raw': 'Raw material',
'_Quantity_short': 'Qty.',
'_Add_raw': 'Add raw material',
'_Raw': 'Raw material',
'_Selected_raw': 'Selected raw material',
'_Add_half_product_data': 'Add/view half-product data',
'_Edit_half_product_data': 'Edit/view half-product data',
'_Duplicate_half_product_text': 'Duplicate half-product',
'_Fetch_normative_fail': 'Fetching normative data failed',
'_Fetch_half_product_fail': 'Fetching half-product data failed',
'_Apply_text': 'Apply',
'_Stocktakings': 'Inventory counts',
'_Add_stocktaking': 'Add inventory count',
'_Stocktaking_number': 'Inventory count number',
'_Add_sources': 'Add sources',
'_UI_Control': 'UI Control',
'_Filter_groups': 'Filter groups',
'_Added_stocktaking_text': 'Inventory count added',
'_Delete_stocktaking_text': 'Are you sure you want to delete the inventory count?',
'_Added_sources_text': 'Sources added to inventory count',
'_Real_state': 'Actual state',
'_VPV': 'VPV',
'_MPV': 'MPV',
'_Set_filters': 'Set filters',
'_Edited_stocktaking_text': 'Inventory count edited',
'_Deleted_stocktaking_text': 'Inventory count deleted',
'_Date_source': 'Source date',
'_Hide_entered': 'Hide entered',
'_Group_sort': 'Sort alphabetically by group',
'_Jump_last': 'Focus last',
'_Jump_not_included': 'The last changed item is not included in the filters',
'_Control_updated_text': 'UI control changed',
'_Standard_insert': 'Standard input',
'_Search_input': 'Barcode input',
'_Barcode': 'Barcode',
'_Selected_barcode': 'Entered barcode',
'_Sources_timeline': 'Source addition timeline',
'_Source': 'Source',
'_Time': 'Time',
'_Update_rs_failed': 'Entry failed for item ',
'_Enter_barcode': 'Enter barcode',
'_Error_logs': 'Error logs',
'_Attempted_entry': 'Failed entry',
'_Recapitulation_Outgoing': 'Recapitulation of outgoing invoice',
'_Recapitulation_Incoming': 'Recapitulation of incoming invoice',
'_Supply_amount': 'Purchase amount',
'_PDV': 'VAT',
'_Amount_with_tax': 'Amount with VAT',
'_VP_amount': 'VP amount',
'_MP_amount': 'MP amount',
'_Margin_amount_r': 'Margin amount',
'_Invoice_price': 'Invoice price',
'_NC_total': 'Total NC',
'_Document_number': 'Document number',
'_Doc_number': 'Doc number',
'_Recapitulation_Production': 'Recapitulation of production document',
'_Recapitulation_Transfer': 'Recapitulation of warehouse transfer',
'_Recapitulation_Dispatch': 'Recapitulation of dispatch note',
'_Recapitulations': 'Recapitulations',
'_NC_total_used': 'Total NC used',
'_VPC_total_used': 'Total VP used',
'_MPC_total_used': 'Total MP used',
'_NC_total_produced': 'Total NC produced',
'_VPC_total_produced': 'Total VP produced',
'_MPC_total_produced': 'Total MP produced',
'_Add': 'Add',
'_Article_card': 'Item card',
'_Document': 'Document',
'_Customer/Supplier': 'Customer / Supplier',
'_Enter_article_code': 'Enter item code',
'_Sales_by': 'Sales',
'_Sales_by_article': 'Sales by item',
'_Sales_by_operator': 'Sales by operator',
'_Sales_by_operator_date': 'Sales by operator and date',
'_Sales_with_discount': 'Sales with discount',
'_Sales_without_tax': 'Sales without tax',
'_Operator': 'Operator',
'_Sales_by_customer': 'Sales by customer',
'_Warehouse_discharge': 'Warehouse discharge',
'_Total_all': 'Total NC for all',
'_Sales_by_payment': 'Sales by payment method',
'_Shift_closings': 'Shift closings',
'_Closing_number': 'Closing number',
'_Closing_date': 'Date and time',
'_Operator_name': 'Operator name',
'_Bill_number_from': 'From bill number',
'_Bill_number_to': 'To bill number',
'_Amount_by_payment_type': 'Amount by payment method',
'_Fiscalization_settings': 'Fiscalization settings',
'_Updated_fiscal_config_text': 'Fiscalization settings successfully changed',
'_Fiscal_type': 'Fiscal type',
'_Operator_code': 'Operator code',
'_Fiscal_device_id': 'Fiscal device ID',
'_Fiscalize': 'Fiscalize',
'_Storn': 'Cancel',
'_Fiscalize_invoice_text': 'Do you want to fiscalize the outgoing invoice?',
'_Fiscalized_text': 'Fiscalization successfully completed',
'_Request_failed_text': 'Request failed. Please contact the administrator.',
'_Fiscal_config_not_set': 'Fiscalization settings are not configured!',
'_Storn_invoice_text': 'Do you want to cancel the outgoing invoice?',
'_Storned_text': 'Cancellation completed successfully',
'_Create_stocktaking_difference': 'Create inventory difference',
'_Created_stocktaking_difference': 'You have successfully created an inventory difference',
'_Confirm_stocktaking_diff_text': 'Do you want to create an inventory difference?',
'_All_states_text': 'All states are not entered',
'_Stocktaking_differences': 'Inventory differences',
'_Difference': 'Difference',
'_Sources': 'Sources',
'_Stocktaking_difference_number': 'Inventory difference number',
'_Stocktaking_date': 'Inventory date',
'_Stocktaking_difference_date': 'Inventory difference date',
'_Warehouse_value': 'Warehouse value',
'_No_RS_entered': 'No actual states entered',
'_Print_fiscal_report': 'Print fiscal report',
'_Fiscal_report': 'Fiscal report',
'_Periodic_report': 'Periodic report',
'_Current_state_report': 'Current state report',
'_Daily_report': 'Daily report',
'_Enter_dates': 'Enter dates',
'_Money_deposit': 'Money deposit',
'_Money_withdrawal': 'Money withdrawal',
'_Print_duplicate_report': 'Print duplicate report',
'_Fiscal_bill': 'Fiscal bill',
'_Cancelled_fiscal_bill': 'Cancelled fiscal bill',
'_Fiscal_reports': 'Fiscal reports',
'_JMBG_13_chars': 'JMBG must be 13 characters long',
'_ID_13_chars': 'ID number must be 13 characters long',
'_No_new_sources_selected': 'Select sources to add',
'_Updated_measure_text': 'Unit of measure changed',
'_Added_measure_text': 'Unit of measure added',
'_Add_Measure': 'Add unit of measure',
'_Short': 'Abbreviation',
'_Edit_Measure': 'Edit unit of measure',
'_Measures': 'Units of measure',
'_Type': 'Type',
'_Physical': 'Natural person',
'_Legal': 'Legal entity',
'_View_incoming_invoice': 'View incoming invoices',
'_Apply_zero_profit_price': 'No profit',
'_Customer_doesnt_exist': 'Customer not found',
'_Mode': 'Operating mode',
'_Cash': 'Cash',
'_Vir': 'Bank transfer',
'_Settings': 'Settings',
'_Fiscalization': 'Fiscalization',
'_Skip_after_MPC': 'When adding/editing items, skip all fields after MPC',
'_Updated_user_settings_text': 'General settings changed',
'_General_settings': 'General settings',
'_Product_raws': 'Defined raw materials',
'_VP_total': 'Total VP',
'_View_stocktaking_difference': 'View inventory differences',
'_View_stocktaking': 'View inventories',
'_View_production_documents': 'View production documents',
'_No_data': 'No data for export to PDF',
'_View_PDF': 'View PDF',
'_Download': 'Download document',
'_View_outgoing_invoice': 'View outgoing invoices',
'_View_warehouse_transfers': 'View warehouse transfers',
'_View_dispatch_notes': 'View dispatch notes',
'_View_PDF_without_prices': 'View PDF (without prices)',
'No data to showcase': 'No data to display',
'_View_Dispatch_Note_From_Outgoing_PDF': 'View dispatch note from outgoing invoice',
'_Add_product': 'Add product',
'_Print': 'Print',
'_Supplier_not_selected': 'Supplier not selected',
'_Invalid_code': 'Invalid code',
'_Document_type': 'Document type',
'_Most_sold_articles': 'Best-selling items',
'_Most_sold_articles_description': 'Best-selling items in the selected period',
'_Techical_support': 'Technical support',
'_Back_to_general_info': 'Back to general information',
'_Reset_normative_data': 'Reset normative data',
'_No_article_chosen': 'No item chosen',
'_Editing_article': 'Editing item',
'_Reset_half_product_data': 'Reset half-product data',
'_Edit_image':'Change image',
'_Changed_image':'Image changed successfully',
'_Total_NV':'Total import value',
'_Min_amount_WH':'Min. warehouse state',
'_No_articles_fiscalize_text':'Invoice must contain articles',
'_Payment_info_updated':'Payment info successfully updated',
'_Recapitulation_Sign_off_articles':'Recapitulation of signed off articles',
'_Total_quantity':'Total quantity',
'_Payment_big_error':"Paid amount can't be greater than debt amount",
'_Remaining_debt':'Remaining debt',
'_Quick_add_message':'Note: You are performing a quick-create of a new article. For more options go to',
'_page.':'page.',
'_Recapitulation_stocktaking_diff':'Rekapitulacija inventurne razlike',
'_Change_login_image':'Edit login image',
'_Change_memorandum':'Edit memorandum image',
'_Edit_images':'Edit images',
'_Memorandum_image':'Memorandum image',
'_Login_image':'Login image',
'_Image_changed':'Image successfully changed',
'_Created_at':'Created at',
'_Modified_at':'Modified at',
'_Total_VPV':'Total VPV',
'_Total_MPV':'Total MPV',
'_shouldAddArticlesWithoutState':"When adding sources, add articles that aren't in warehouse",
'_Company_details':'Company details',
'_Web_address':'Web address',
'_Bank_name':'Bank name',
'_Bank_account_number':'Bank account number',
'_Bank_details':'Bank details',
'_Consolidated_invoices':'Consolidated invoices',
'_Create_consolidated_invoice':'Create consolidated invoice',
'_Customer_not_selected':'Customer not selected',
'_Foter_details':'Footer',
'_Header:':'Header',
'_Number':'Number',
'_Added_paymentType_text':'New payment type has been created',
'_Edited_estimate_text':'Succesfully edited the estimate',
'_Deleted_estimate_text':'You have deleted the estimate',
'_Estimate':'Estimates',
'_Add_estimate':'Add estimate',
'_View_estimates':'View estimates',
'_Estimate_number':'Estimate number',
'_Estimate_date':'Estimate date',
'_Estimate_discount':'Estimate discount',
'_Delete_estimate_text':'Estimate has been deleted',
'_Invoice_discount_updated':'Invoice discount updated',
'_Estimate_discount_updated':'Estimate discount updated',
'_Cancellations':'Cancellations',
'_Bill/Item':'Bill / Item',
'_RF/FIS':'RF / FIS number',
'_Original':'Original',
'_Item':'Item',
'_Order_time':'Order time',
'_Cancel_time':'Cancel time',
'_Show_original':'Show original',
'_Show_cancellation':'Show cancelled',
'_Original_bill_details':'Original bill details',
'_Cancelled_bill_details':'Cancelled bill details',
'_Storn_articles_error':"Invoice without items can't be cancelled",
'_Bill':'Bill',

}
