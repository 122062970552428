<div class="toolbar" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1" style="font-size:22px!important;">
                {{'_Articles' | translate}}</h1>
            <span class="h-20px border-gray-600 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/home']" class="text-gray-600 text-hover-primary"
                        style="font-size:16px!important">{{'_Home' | translate}}</a>
                </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-600 w-5px h-2px" style="font-size:16px!important"></span>
                </li>
                <li class="breadcrumb-item text-gray-600" style="font-size:16px!important">{{'_Articles' | translate}}
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl" style="padding:0px;margin-top:25px;margin-bottom:25px;">
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title">
                    <div class="d-flex align-items-center position-relative my-1">
                        <span class="svg-icon svg-icon-1 position-absolute ms-6" *ngIf="data.currentMode=='light'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="black" />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="black" />
                            </svg>
                        </span>
                        <span class="svg-icon svg-icon-1 position-absolute ms-6" *ngIf="data.currentMode=='dark'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="gray" />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="gray" />
                            </svg>
                        </span>
                        <input type="text" class="form-control form-control-solid w-250px ps-15" placeholder=""
                            name="name" placeholder="Naziv artikla" #searchArticleName
                            (change)="searchArticles(searchArticleName.value)" />
                    </div>
                    <div class="mx-2" style="min-width:250px">
                        <select #selectFilterGroupId class="form-select form-select-solid fw-bold"
                            data-kt-select2="true" data-placeholder="Odaberite grupu" id="article_filter_groupId"
                            data-allow-clear="true" data-dropdown-parent=".card-title" [(ngModel)]="groupId">
                            <option></option>
                            <option *ngFor="let g of groups" [value]="g.id">{{g.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-group-type-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary me-3 filter-button"
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <span class="svg-icon svg-icon-2" *ngIf="data.currentMode=='light'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                        fill="black"></path>
                                </svg>
                            </span>
                            <span class="svg-icon svg-icon-2" *ngIf="data.currentMode=='dark'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                        fill="white"></path>
                                </svg>
                            </span>
                            Filter
                        </button>
                        <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true"
                            id="kt-toolbar-filter">
                            <form [formGroup]="searchForm" class="d-flex">
                                <div class="px-7 py-5">
                                    <div class="fs-4 text-dark fw-bolder">{{'_Filter_options' | translate}}</div>
                                </div>
                                <div class="separator border-gray-200"></div>
                                <div class="px-7 py-5">
                                    <div class="mb-10">
                                        <label class="form-label fs-5 fw-bold mb-3">{{'_Measure' | translate}}:</label>
                                        <select #selectFilterMeasureId class="form-select form-select-solid fw-bolder"
                                            data-kt-select2="true" data-placeholder="Odaberite jedinicu"
                                            data-allow-clear="true" data-kt-group-type-table-filter="month"
                                            id="article_filter_measureId" data-dropdown-parent="#kt-toolbar-filter"
                                            formControlName="measureId" [(ngModel)]="measureId" name="measureId">
                                            <option></option>
                                            <option *ngFor="let m of measures" [value]="m.id">{{m.short}}</option>
                                        </select>
                                    </div>
                                    <div class="row fv-row mb-15">
                                        <label class="fs-5 fw-bold form-label mb-5">{{'_Status' | translate}}:</label>
                                        <div class="d-flex flex-column">
                                            <label
                                                class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                <input class="form-check-input" type="radio" value="null"
                                                    formControlName="isActive" [(ngModel)]="isActive" checked
                                                    name="isActive" />
                                                <span class="form-check-label text-gray-600 fw-bold"> {{'_Select_all' |
                                                    translate}}</span>
                                            </label>
                                            <label
                                                class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                <input class="form-check-input" type="radio" value="true"
                                                    formControlName="isActive" [(ngModel)]="isActive" name="isActive" />
                                                <span class="form-check-label text-gray-600 fw-bold">{{'_Active' |
                                                    translate}}</span>
                                            </label>
                                            <label
                                                class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                <input class="form-check-input" type="radio" value="false"
                                                    formControlName="isActive" [(ngModel)]="isActive" name="isActive" />
                                                <span class="form-check-label text-gray-600 fw-bold">{{'_Inactive' |
                                                    translate}}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button type="reset" class="btn btn-light btn-active-light-primary me-2"
                                            data-kt-menu-dismiss="true" data-kt-group-type-table-filter="reset"
                                            (click)="resetFilter()">{{'_Reset' | translate}}</button>
                                        <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true"
                                            data-kt-group-type-table-filter="filter" #submitFilter
                                            (click)="searchArticles(searchArticleName.value)">{{'_Apply' |
                                            translate}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <button class="btn btn-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                            data-bs-toggle="modal" data-bs-target="#kt_modal_add_article" (click)="startAddingArticle()"><i
                                class="fas fa-plus"></i>{{'_Add_article' | translate}}</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_group-types_table"
                    *ngIf="!emptyData">
                    <thead>
                        <tr class="text-start text-gray-900 fw-bolder fs-6 text-uppercase gs-0">
                            <th style="width:8%;font-size:14px;padding-left:10px;">{{'_Code' | translate}}</th>
                            <th style="width:12%;font-size:14px;">{{'_Article_name' | translate}}</th>
                            <th style="width:12%;font-size:14px;">{{'_Group' | translate}}</th>
                            <th style="width:8%;font-size:14px;">{{'_JM' | translate}}</th>
                            <th style="width:8%;font-size:14px;">{{'_MPC' | translate}}</th>
                            <th style="width:8%;font-size:14px;">{{'_VPC' | translate}}</th>
                            <th style="width:8%;font-size:14px;">{{'_NC' | translate}}</th>
                            <th style="width:8%;font-size:14px;">{{'_Margin' | translate}}</th>
                            <th style="width:8%;font-size:14px;">{{'_Happy_hour_price' | translate}}</th>
                            <th style="width:8%;font-size:14px;">{{'_FIS_number' | translate}}</th>
                            <th style="width:8%;font-size:14px;">{{'_Is_active' | translate}}</th>
                            <th style="width:12%;font-size:14px;" class="text-center">{{'_Actions' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-1000">
                        <tr *ngFor="let a of articles;let r=index"
                            [ngClass]="{ 'evenTableRowColor': r%2==0, 'oddTableRowColor':r%2!=0 }">
                            <td style="width:8%;padding-left:10px;padding-top:8px;padding-bottom:8px;">{{a.code}}</td>
                            <td style="width:12%;padding-top:8px;padding-bottom:8px;">{{a.name}}</td>
                            <td style="width:12%;padding-top:8px;padding-bottom:8px;">{{a.groupName}}</td>
                            <td style="width:8%;padding-top:8px;padding-bottom:8px;">{{a.measureName}}</td>
                            <td style="width:8%;padding-top:8px;padding-bottom:8px;">{{a.mpc | number:'1.2-2'}}</td>
                            <td style="width:8%;padding-top:8px;padding-bottom:8px;">{{a.vpc | number:'1.2-2'}}</td>
                            <td style="width:8%;padding-top:8px;padding-bottom:8px;">{{a.nc | number:'1.2-2'}}</td>
                            <td style="width:8%;padding-top:8px;padding-bottom:8px;">{{a.margin | number:'1.2-2'}}</td>
                            <td style="width:8%;padding-top:8px;padding-bottom:8px;">{{a.happyHourPrice |
                                number:'1.2-2'}}</td>
                            <td style="width:8%;padding-top:8px;padding-bottom:8px;">{{a.fisNumber}}</td>
                            <td style="width:8%;padding-top:8px;padding-bottom:8px;">
                                <div
                                    class="form-check form-switch form-switch-sm form-check-custom form-check-solid form-toggle">
                                    <input class="form-check-input" type="checkbox" [id]="a.id"
                                        name="activityToggle" (click)="toggleArticleActivity(a)"
                                        [checked]="a.isActive" />
                                </div>
                            </td>
                            <td class="text-center" style="width:12%;padding-top:8px;padding-bottom:8px;">
                                <div class="actionsWrapper">
                                    <popper-content #popperEditImage>
                                        {{'_Edit_image'|translate}}
                                    </popper-content>
                                    <div [popper]="popperEditImage"
                                        [popperShowOnStart]="false"
                                        [popperTrigger]="'hover'"
                                        popperHideOnClickOutside
                                        [popperHideOnMouseLeave]="true"
                                        [popperHideOnScroll]="true"
                                        [popperPlacement]="'top'">
                                        <a (click)="selectImageFromListView(a.id)" class="btn btn-icon btn-info fs-8 mx-1"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" fill="white" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
                                        </a>
                                    </div>
                                    <popper-content #popperEditItem>
                                        {{'_Edit_article'|translate}}
                                    </popper-content>
                                    <div [popper]="popperEditItem"
                                        [popperShowOnStart]="false"
                                        [popperTrigger]="'hover'"
                                        popperHideOnClickOutside
                                        [popperHideOnMouseLeave]="true"
                                        [popperHideOnScroll]="true"
                                        [popperPlacement]="'top'">
                                        <a (click)="selectArticle(a)" class="btn btn-icon btn-warning fs-8 me-1"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                            data-bs-toggle="modal" data-bs-target="#kt_modal_edit_article">
                                            <i class="fa fa-pencil-alt fs-4" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                    <popper-content #popperDetails>
                                        {{'_Details'|translate}}
                                    </popper-content>
                                    <div [popper]="popperDetails"
                                        [popperShowOnStart]="false"
                                        [popperTrigger]="'hover'"
                                        popperHideOnClickOutside
                                        [popperHideOnMouseLeave]="true"
                                        [popperHideOnScroll]="true"
                                        [popperPlacement]="'top'">
                                        <a (click)="selectArticle(a)" class="btn btn-icon btn-primary fs-8"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                            data-bs-toggle="modal" data-bs-target="#kt_modal_article_details">
                                            <i class="fa fa-info-circle fs-4" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row" *ngIf="!emptyData">
                    <div
                        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="dataTables_length" id="kt_customers_table_length"><label><select
                                    name="kt_customers_table_length" aria-controls="kt_customers_table"
                                    class="form-select form-select-sm form-select-solid" [(ngModel)]="perPage"
                                    (change)="perPageHandler()">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select></label></div>
                    </div>
                    <div
                        class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div class="dataTables_paginate paging_simple_numbers" id="kt_customers_table_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous disabled"
                                    id="kt_customers_table_previous"><a (click)="prevpage()"
                                        aria-controls="kt_customers_table" data-dt-idx="0" tabindex="0"
                                        class="page-link" style="cursor:pointer"><i class="previous"></i></a></li>

                                <div *ngFor="let p of pages">
                                    <li class="paginate_button page-item active" *ngIf="page==p">
                                        <a aria-controls="kt_customers_table" data-dt-idx="2" tabindex="0"
                                            class="page-link " (click)="goToPage(p)" style="cursor:pointer">{{p}}</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="page!=p">
                                        <a aria-controls="kt_customers_table" data-dt-idx="2" tabindex="0"
                                            class="page-link" (click)="goToPage(p)" style="cursor:pointer">{{p}}</a>
                                    </li>
                                </div>
                                <li class="paginate_button page-item disabled" id="kt_customers_table_morePages"
                                    *ngIf="maxPage>offset"><a (click)="morePages()" aria-controls="kt_customers_table"
                                        data-dt-idx="0" tabindex="0" class="page-link" style="cursor:pointer">...</a>
                                </li>
                                <li class="paginate_button page-item next" id="kt_customers_table_next"><a
                                        (click)="nextPage()" aria-controls="kt_customers_table" data-dt-idx="5"
                                        tabindex="0" class="page-link" style="cursor:pointer"><i class="next"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="emptyData">
                    <div class="text-center">
                        <h1 class="fw-bolder mb-5">{{'_No_results' | translate}}</h1>
                        <div class="separator separator-dashed border-danger opacity-25 mb-5"></div>
                        <div class="mb-9 text-dark">
                            {{'_No_results_text' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<input #listViewImageUpload type="file" (change)="editArticleImageFromListView($event)" accept=".png, .jpg, .jpeg" hidden>

<div class="modal fade" id="kt_modal_article_details" tabindex="-1" aria-hidden="true" #closeArticleDetailsModal>
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <div class="modal-header" id="kt_modal_article_details_header">
                <h2 class="fw-bolder">{{'_Article_details' | translate}}</h2>
                <div id="kt_modal_article_details_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                    data-bs-dismiss="modal">
                    <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="black" />
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                fill="black" />
                        </svg>
                    </span>
                </div>
            </div>
            <div *ngIf="selectedArticle!=undefined" class="modal-body py-10 px-lg-17">
                <div class="scroll-y me-n7 pe-7" id="kt_modal_article_details_scroll" data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_article_details_header"
                    data-kt-scroll-wrappers="#kt_modal_article_details_scroll" data-kt-scroll-offset="300px">
                    <div class="fv-row mb-5" *ngIf="selectedArticle.picture!=undefined && selectedArticle.picture!=null"
                        style="border-bottom:1px dashed #eee">
                        <img style="max-width: 300px;height:auto;padding-bottom:8px;"
                            [src]="imagesUrl + clientId + '/' + selectedArticle.picture" />
                    </div>
                    <div class="row fv-row mb-5" style="border-bottom:1px dashed #eee;padding-bottom:12px;">
                        <div class="col-md-6">
                            <span class="fs-4 text-uppercase mb-2" style="padding-bottom:8px;">{{'_Article_Code' |
                                translate}}: <b>{{selectedArticle.code}}</b></span>
                        </div>
                        <div class="col-md-6">
                            <span class="fs-4 text-uppercase mb-2" style="padding-bottom:8px;">{{'_Article_Name' |
                                translate}}: <b>{{selectedArticle.name}}</b></span>
                        </div>
                    </div>
                    <div class="row fv-row mb-5" style="border-bottom:1px dashed #eee;">
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2">{{'_Bar_code' | translate}}:</span>
                                <span *ngIf="selectedArticle.barcodes.length>0" class="fs-4 text-uppercase"
                                    style="padding-bottom:8px;"><b>{{selectedArticle.barcodes[0]}}</b></span>
                                <span *ngFor="let b of displaySecondaryBarcodes" class="fs-4 text-uppercase"
                                    style="padding-bottom:8px;"><b>, {{b}}</b></span>
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2" style="padding-bottom:8px;">{{'_FIS_number' |
                                    translate}}: <b>{{selectedArticle.fisNumber}}</b></span></p>
                        </div>
                    </div>
                    <div class="row fv-row mb-5" style="border-bottom:1px dashed #eee;">
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2" style="padding-bottom:8px;">{{'_Group' |
                                    translate}}: <b>{{selectedArticle.groupName}}</b></span></p>
                        </div>
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2" style="padding-bottom:8px;">{{'_Measure' |
                                    translate}}: <b>{{selectedArticleMeasure}}</b></span> </p>
                        </div>
                    </div>
                    <div class="row fv-row mb-5" style="border-bottom:1px dashed #eee;">
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2">{{'_NC' | translate}}: <b>{{selectedArticle.nc |
                                        number:'1.2-2'}}</b></span></p>
                        </div>
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2">{{'_MPC' | translate}}: <b>{{selectedArticle.mpc |
                                        number:'1.2-2'}}</b></span></p>
                        </div>
                    </div>
                    <div class="row fv-row mb-5" style="border-bottom:1px dashed #eee;">
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2">{{'_VPC' | translate}}: <b>{{selectedArticle.vpc |
                                        number:'1.2-2'}}</b></span></p>
                        </div>
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2">{{'_Margin' | translate}}:
                                    <b>{{selectedArticle.margin | number:'1.2-2'}}</b></span></p>
                        </div>
                    </div>
                    <div class="row fv-row mb-5" style="border-bottom:1px dashed #eee;">
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2">{{'_Happy_hour_price' | translate}}:
                                    <b>{{selectedArticle.happyHourPrice | number:'1.2-2'}}</b></span>
                            <p>
                        </div>
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2">{{'_Min_amount_WH' | translate}}:
                                <b>{{selectedArticle.minAmount | number:'1.2-2'}}</b></span></p>
                        </div>
                    </div>
                    <div class="row fv-row mb-5" style="border-bottom:1px dashed #eee;">
                        <div class="col-md-6">
                            <p><span class="fs-4 text-uppercase mb-2">{{'_Description' | translate}}: 
                                <b>{{selectedArticle.description}}</b></span></p>
                        </div>
                       <div class="col-md-6">
                        <label *ngIf="selectedArticle.isActive"><span class="fs-4 text-uppercase mb-2">{{'_Status' |
                            translate}}: <b>{{'_Active'|translate}}</b></span></label>
                    <label *ngIf="!selectedArticle.isActive"><span class="fs-4 text-uppercase mb-2">{{'_Status'
                            | translate}}: <b>{{'_Inactive'|translate}}</b></span></label>
                       </div>
                    </div>
                    <div class="row fv-row mb-5" style="border-bottom:1px dashed #eee;padding-bottom:12px">
                        <div class="col-md-6">
                            <label *ngIf="selectedArticle.isNormative"><span
                                    class="fs-4 text-uppercase mb-2">{{'_Is_normative' | translate}}:
                                <b>{{'_Yes_text'|translate}}</b></span></label>
                            <label *ngIf="!selectedArticle.isNormative"><span
                                    class="fs-4 text-uppercase mb-2">{{'_Is_normative' | translate}}:
                                <b>{{'_No_text'|translate}}</b></span></label>
                        </div>
                        <div class="col-md-6">
                            <label *ngIf="selectedArticle.shouldProduce"><span
                                class="fs-4 text-uppercase mb-2">{{'_Is_half_product' | translate}}:
                            <b>{{'_Yes_text'|translate}}</b></span></label>
                        <label *ngIf="!selectedArticle.shouldProduce"><span
                                class="fs-4 text-uppercase mb-2">{{'_Is_half_product' | translate}}:
                            <b>{{'_No_text'|translate}}</b></span></label>
                        </div>
                    </div>
                    <div *ngIf="selectedArticle.isNormative" class="fw-bolder fs-3 rotate collapsible mb-2 text-uppercase"
                        data-bs-toggle="collapse" href="#kt_modal_article_details_normative_data" role="button"
                        aria-expanded="false" aria-controls="kt_modal_article_details_normative_data" style="margin-top:16px;">
                        {{'_Normative_data' | translate}}
                        <span class="ms-2 rotate-180">
                            <span class="svg-icon svg-icon-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                        fill="black" />
                                </svg>
                            </span>
                        </span>
                    </div>
                    <div *ngIf="selectedArticle.isNormative" id="kt_modal_article_details_normative_data"
                        class="collapse show">
                        <div class="row mb-7">
                            <div class="row fv-row mb-15">
                                <div class="d-flex flex-column">
                                    <table class="table align-middle table-row-dashed fs-6 gy-5">
                                        <thead>
                                            <tr class="fs-5 fw-bolder text-uppercase form-label mb-5 bg-gray-100">
                                                <th style="padding-left:10px;">{{'_Normative_name'|translate}}</th>
                                                <th>{{'_Amount_article'|translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let n of selectedArticle.normativeItems">
                                                <td style="padding-left:10px;">{{n.name}}</td>
                                                <td>{{n.amount}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedArticle.shouldProduce" class="fw-bolder fs-3 rotate collapsible mb-2 text-uppercase"
                        data-bs-toggle="collapse" href="#kt_modal_article_details_halfproduct_data" role="button"
                        aria-expanded="false" aria-controls="kt_modal_article_details_halfproduct_data" style="margin-top:16px;">
                        {{'_Half_product_data' | translate}}
                        <span class="ms-2 rotate-180">
                            <span class="svg-icon svg-icon-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                        fill="black" />
                                </svg>
                            </span>
                        </span>
                    </div>
                    <div *ngIf="selectedArticle.shouldProduce" id="kt_modal_article_details_halfproduct_data"
                        class="collapse show">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_half_product_scroll" data-kt-scroll="true"
                            data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                            data-kt-scroll-dependencies="#kt_modal_half_product_data_header"
                            data-kt-scroll-wrappers="#kt_modal_half_product_scroll" data-kt-scroll-offset="300px">
                            <div class="row fv-row mb-15">
                                <div class="d-flex flex-column">
                                    <table class="table align-middle table-row-dashed fs-6 gy-5"
                                        *ngIf="selectedArticle.halfProductData!=undefined && selectedArticle.halfProductData.length>0">
                                        <thead>
                                            <tr class="fs-5 fw-bolder text-uppercase form-label mb-5 bg-gray-100">
                                                <th style="padding-left:10px;">{{'_Half_product_name'|translate}}</th>
                                                <th>{{'_Amount_article'|translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let h of selectedArticle.halfProductData">
                                                <td style="padding-left:10px;">{{h.name}}</td>
                                                <td>{{h.quantity}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_add_article" tabindex="-1" aria-hidden="true" #closeAddArticleModal
    >
    <div class="modal-dialog modal-dialog-centered superWideModal" style="max-width:70%">
        <div class="modal-content">
            <form class="form" action="#" id="kt_modal_add_article_form" [formGroup]="formGroup">
                <div class="modal-header" id="kt_modal_add_article_header">
                    <h2 class="fw-bolder">{{'_Add_article' | translate}}</h2>
                    <div id="kt_modal_add_article_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                        (click)="closeAddModal()">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-0 px-lg-7 bg-gray-200">
                    <div class="scroll-y me-n7 pe-7 superWideElements" id="kt_modal_add_article_scroll"
                        data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_edit_group_type_header"
                        data-kt-scroll-wrappers="#kt_modal_add_article_scroll" data-kt-scroll-offset="300px">
                        <div class="row fv-row my-10">
                            <div class="col">
                                <div class="card mb-2">
                                    <div class="card-body px-7">
                                        <div class="fv-row mb-2">
                                            <label class="fs-6 fw-bold mb-2">{{'_Article_Code' | translate}}</label>
                                            <input type="text" class="form-control form-control-solid" placeholder=""
                                                name="code" value="" formControlName="code" tabindex="1" id="codeInput"
                                                [ngClass]="{ 'is-invalid': isSubmitted && f.code.errors }" />
                                        </div>
                                        <div class="fv-row mb-2">
                                            <label class="required fs-6 fw-bold mb-2">{{'_Article_Name' |
                                                translate}}</label>
                                            <input type="text" class="form-control form-control-solid" placeholder=""
                                                name="name" value="" formControlName="name" required tabindex="1"
                                                [ngClass]="{ 'is-invalid': isSubmitted && f.name.errors }" />
                                        </div>
                                        <div class="row fv-row">
                                            <div class="col-xl-6">
                                                <div class="row">
                                                    <label class="required form-label fs-6 fw-bold mb-2">{{'_Group' |
                                                        translate}}:</label>
                                                    <select #selectAddArticleGroupId
                                                        class="form-select form-select-solid fw-bolder"
                                                        data-placeholder="Odaberite grupu" tabindex="1"
                                                        data-allow-clear="true" data-kt-group-type-table-filter="month"
                                                        id="article_add_groupId"
                                                        data-dropdown-parent="#kt_modal_add_article_form"
                                                        formControlName="addEditGroupId" [(ngModel)]="addEditGroupId"
                                                        name="addEditGroupId">
                                                        <option></option>
                                                        <option *ngFor="let g of groups" [value]="g.id">{{g.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="fv-row">
                                                    <label class="required form-label fs-5 fw-bold mb-2">{{'_Measure' |
                                                        translate}}:</label>
                                                    <select #selectAddMeasureId
                                                        class="form-select form-select-solid fw-bolder" tabindex="1"
                                                        data-placeholder="Odaberite jedinicu" data-allow-clear="true"
                                                        data-kt-group-type-table-filter="month"
                                                        id="article_add_measureId"
                                                        data-dropdown-parent="#kt_modal_add_article_form"
                                                        formControlName="addEditMeasureId"
                                                        [(ngModel)]="addEditMeasureId" name="addEditMeasureId">
                                                        <option></option>
                                                        <option *ngFor="let m of measures" [value]="m.id">{{m.short}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-body p-7">
                                        <div class="row fv-row">
                                            <div class="col-xl-4">
                                                <div class="fv-row mb-2">
                                                    <label class="fs-6 fw-bold mb-2">{{'_MPC' | translate}}</label>
                                                    <input type="number" min="0" class="form-control form-control-solid"
                                                        placeholder="" name="mpc" value="" formControlName="mpc"
                                                        [(ngModel)]="mpc" (change)="calculateMargin()"
                                                        (change)="calculatePrices()"
                                                        [ngClass]="{ 'is-invalid': isSubmitted && f.mpc.errors }"
                                                        [tabIndex]="skipAfterMPC?1:null" />
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="fv-row mb-2">
                                                    <label class="fs-6 fw-bold mb-2">{{'_NC' | translate}}</label>
                                                    <input type="number" min="0" class="form-control form-control-solid"
                                                        placeholder="" name="nc" value="" [(ngModel)]="nc"
                                                        formControlName="nc" (change)="calculateMargin()"
                                                        [ngClass]="{ 'is-invalid': isSubmitted && f.nc.errors }" />
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="fv-row mb-2">
                                                    <label class="fs-6 fw-bold mb-2">{{'_VPC' | translate}}</label>
                                                    <input type="number" min="0" class="form-control form-control-solid"
                                                        placeholder="" name="vpc" formControlName="vpc"
                                                        [(ngModel)]="vpc"
                                                        [ngClass]="{ 'is-invalid': isSubmitted && f.vpc.errors }" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="fv-row mb-2">
                                            <label class="fs-6 fw-bold mb-2">{{'_Margin' | translate}}</label>
                                            <input type="number" min="0" class="form-control form-control-solid"
                                                placeholder="" name="margin" value="" formControlName="margin"
                                                [(ngModel)]="margin"
                                                [ngClass]="{ 'is-invalid': isSubmitted && f.margin.errors }" />
                                        </div>

                                        <div class="row fv-row">
                                            <div class="col-xl-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold mb-2">{{'_Happy_hour_price' |
                                                        translate}}</label>
                                                    <input type="number" min="0" class="form-control form-control-solid"
                                                        placeholder="" name="happyHourPrice" value=""
                                                        formControlName="happyHourPrice" [(ngModel)]="hh"
                                                        [ngClass]="{ 'is-invalid': isSubmitted && f.happyHourPrice.errors }" />
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold mb-2">{{'_Min_amount_WH' |
                                                        translate}}</label>
                                                    <input type="number" min="0" class="form-control form-control-solid"
                                                        placeholder="" name="minAmount" value=""
                                                        formControlName="minAmount"
                                                        [ngClass]="{ 'is-invalid': isSubmitted && f.minAmount.errors }" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card mb-3">
                                    <div class="card-body px-7">
                                        <div class="fv-row mb-2">
                                            <label class="fs-6 fw-bold mb-2">{{'_Description' | translate}}</label>
                                            <textarea rows="4" class="form-control form-control-solid" placeholder=""
                                                name="description" value="" formControlName="description"></textarea>
                                        </div>
                                        <div class="fv-row mb-2">
                                            <label class="fs-6 fw-bold mb-2">{{'_Bar_code' | translate}}</label>
                                            <div [hidden]="barcodesList.length==0" class="d-flex px-6 flex-column">
                                                <table class="table align-middle table-row-dashed fs-6">
                                                    <tbody>
                                                        <tr *ngFor="let b of barcodesList">
                                                            <td>{{b}}</td>
                                                            <td><button type="button" class="btn btn-primary"
                                                                    (click)="removeBarcode(b)">{{'_Remove'|translate}}</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="row fv-row mb-2">
                                                <div class="col-md-6 fv-row">
                                                    <input type="number" id="newBarcodeAdd"
                                                        (change)="validateBarcode('#newBarcodeAdd', true)"
                                                        class="form-control form-control-solid" [(ngModel)]="newBarcode"
                                                        [ngModelOptions]="{standalone:true}" />
                                                    <p *ngIf="showBarcodeErrorAdd" style="color:red">
                                                        {{barcodeErrorTextAdd|translate}}</p>
                                                </div>
                                                <div class="col-md-6 fv-row">
                                                    <button type="button" class="btn btn-light-primary"
                                                        (click)="addBarcode('#newBarcodeAdd', true)">{{'_Add_barcode'|translate}}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row fv-row">
                                            <div class="col-xl-6">
                                                <div class="row fv-row mb-7">
                                                    <label class="fs-5 fw-bold form-label mb-5">{{'_Is_normative' |
                                                        translate}}:</label>
                                                    <div class="d-flex flex-column">
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="true"
                                                                formControlName="isNormative" [(ngModel)]="isNormative"
                                                                name="isNormative" (change)="negateHalfProduct()" />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_Yes_text'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="false"
                                                                formControlName="isNormative" [(ngModel)]="isNormative"
                                                                name="isNormative" />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_No_text'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row fv-row mb-10" *ngIf="isNormative">
                                                    <button class="btn btn-info" data-bs-target="#normativeDataModal"
                                                        data-bs-toggle="modal">{{'_Add_normative_data'|
                                                        translate}}</button>
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="row fv-row mb-10">
                                                    <label class="fs-5 fw-bold form-label mb-5">{{'_Is_half_product' |
                                                        translate}}:</label>
                                                    <div class="d-flex flex-column">
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="true"
                                                                formControlName="shouldProduce"
                                                                [(ngModel)]="shouldProduce" name="shouldProduce"
                                                                (change)="negateNormative()" />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_Yes_text'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="false"
                                                                formControlName="shouldProduce"
                                                                [(ngModel)]="shouldProduce" name="shouldProduce" />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_No_text'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row fv-row mb-10" *ngIf="shouldProduce">
                                                    <button class="btn btn-info" data-bs-target="#halfProductDataModal"
                                                        data-bs-toggle="modal">{{'_Add_half_product_data'|translate}}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row fv-row">
                                            <div class="col-xl-6">
                                                <div class="row fv-row mb-2">
                                                    <label class="fs-5 fw-bold form-label mb-5">{{'_Status' |
                                                        translate}}:</label>
                                                    <div class="d-flex flex-column">
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="true"
                                                                formControlName="addEditIsActive"
                                                                [(ngModel)]="addEditIsActive" name="addEditIsActive"
                                                                checked />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_Active'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="false"
                                                                formControlName="addEditIsActive"
                                                                [(ngModel)]="addEditIsActive" name="addEditIsActive" />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_Inactive'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div id="kt_image_input_add" class="image-input image-input-empty"
                                                    style="background-image: url(/assets/media/svg/avatars/blank.svg)">
                                                    <!--begin::Image preview wrapper-->
                                                    <div class="image-input-wrapper w-125px h-125px"
                                                        id="imagePreviewAdd"></div>
                                                    <!--end::Image preview wrapper-->

                                                    <!--begin::Edit button-->
                                                    <label
                                                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="change" data-bs-toggle="tooltip"
                                                        data-bs-dismiss="click" title="Change image">
                                                        <i class="bi bi-pencil-fill fs-7"></i>

                                                        <!--begin::Inputs-->
                                                        <input type="file" name="avatar"
                                                            (change)="onFileSelected($event)"
                                                            accept=".png, .jpg, .jpeg" />
                                                        <input type="hidden" name="avatar_remove" />
                                                        <!--end::Inputs-->
                                                    </label>
                                                    <!--end::Edit button-->

                                                    <!--begin::Cancel button-->
                                                    <span
                                                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="cancel" data-bs-toggle="tooltip"
                                                        data-bs-dismiss="click" title="Cancel" #imageCancelAdd>
                                                        <i class="bi bi-x fs-2"></i>
                                                    </span>
                                                    <!--end::Cancel button-->

                                                    <!--begin::Remove button-->
                                                    <span
                                                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="remove" data-bs-toggle="tooltip"
                                                        data-bs-dismiss="click" title="Remove">
                                                        <i class="bi bi-x fs-2"></i>
                                                    </span>
                                                    <!--end::Remove button-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer flex-center">
                    <button type="button" id="kt_modal_add_article_cancel" class="btn btn-light me-3"
                        (click)="closeAddModal()">
                        {{'_Discard' | translate}}</button>
                    <button type="button" id="kt_modal_add_article_submit" class="btn btn-primary"
                        (click)="addArticle()" [tabIndex]="skipAfterMPC?2:1">
                        <span class="indicator-label">{{'_Submit' | translate}}</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_edit_article" tabindex="-1" aria-hidden="true" #closeEditArticleModal>
    <div class="modal-dialog modal-dialog-centered superWideModal" style="max-width:70%">
        <div class="modal-content">
            <form class="form" action="#" id="kt_modal_edit_article_form" [formGroup]="formGroup">
                <div class="modal-header" id="kt_modal_edit_article_header">
                    <h2 class="fw-bolder">{{'_Edit_article' | translate}}</h2>
                    <div id="kt_modal_edit_article_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                (click)="closeEditModal()">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-0 px-lg-7 bg-gray-200">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_add_article_scroll" data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_edit_group_type_header"
                        data-kt-scroll-wrappers="#kt_modal_add_article_scroll" data-kt-scroll-offset="300px">
                        <div class="row fv-row my-10">
                            <div class="col">
                                <div class="card mb-2">
                                    <div class="card-body px-7">
                                        <div class="fv-row mb-7">
                                            <label class="fs-6 fw-bold mb-2">{{'_Article_Code' | translate}}</label>
                                            <input type="text" class="form-control form-control-solid" placeholder=""
                                                name="code" value="" formControlName="code"
                                                [ngClass]="{ 'is-invalid': isSubmitted && f.code.errors }" />
                                        </div>
                                        <div class="fv-row mb-2">
                                            <label class="required fs-6 fw-bold mb-2">{{'_Article_Name' |
                                                translate}}</label>
                                            <input type="text" class="form-control form-control-solid" placeholder=""
                                                name="name" value="" formControlName="name" required
                                                [ngClass]="{ 'is-invalid': isSubmitted && f.name.errors }" />
                                        </div>
                                        <div class="fv-row mb-2" *ngIf="selectedArticle!=undefined">
                                            <label class="fs-6 fw-bold mb-2">{{'_FIS_number' | translate}}</label>
                                            <input type="text" class="form-control form-control-solid" placeholder=""
                                                [value]="selectedArticle.fisNumber" disabled />
                                        </div>
                                        <div class="row fv-row">
                                            <div class="col-xl-6">
                                                <div class="row">
                                                    <label class="required form-label fs-6 fw-bold mb-2">{{'_Group' |
                                                        translate}}:</label>
                                                    <select #selectEditArticleGroupId
                                                        class="form-select form-select-solid fw-bolder"
                                                        data-placeholder="Odaberite grupu" data-allow-clear="true"
                                                        data-kt-group-type-table-filter="month"
                                                        id="article_edit_groupId"
                                                        data-dropdown-parent="#kt_modal_edit_article_form"
                                                        formControlName="addEditGroupId" [(ngModel)]="addEditGroupId"
                                                        name="addEditGroupId">
                                                        <option></option>
                                                        <option *ngFor="let g of groups" [value]="g.id">{{g.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="fv-row">
                                                    <label class="required form-label fs-5 fw-bold mb-3">{{'_Measure' |
                                                        translate}}:</label>
                                                    <select #selectEditMeasureId
                                                        class="form-select form-select-solid fw-bolder"
                                                        data-placeholder="Odaberite jedinicu" data-allow-clear="true"
                                                        data-kt-group-type-table-filter="month"
                                                        id="article_edit_measureId"
                                                        data-dropdown-parent="#kt_modal_edit_article_form"
                                                        formControlName="addEditMeasureId"
                                                        [(ngModel)]="addEditMeasureId" name="addEditMeasureId">
                                                        <option></option>
                                                        <option *ngFor="let m of measures" [value]="m.id">{{m.short}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-body p-7">
                                        <div class="row fv-row">
                                            <div class="col-xl-4">
                                                <div class="fv-row mb-2">
                                                    <label class="fs-6 fw-bold mb-2">{{'_MPC' | translate}}</label>
                                                    <input type="number" min="0" class="form-control form-control-solid"
                                                        placeholder="" name="mpc" value="" formControlName="mpc"
                                                        [(ngModel)]="mpc" (change)="calculateMargin()"
                                                        (change)="calculatePrices()"
                                                        [ngClass]="{ 'is-invalid': isSubmitted && f.mpc.errors }"
                                                        [tabIndex]="skipAfterMPC?1:null" />
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="fv-row mb-2">
                                                    <label class="fs-6 fw-bold mb-2">{{'_NC' | translate}}</label>
                                                    <input type="number" min="0" class="form-control form-control-solid"
                                                        placeholder="" name="nc" value="" formControlName="nc"
                                                        [(ngModel)]="nc" (change)="calculateMargin()"
                                                        [ngClass]="{ 'is-invalid': isSubmitted && f.nc.errors }" />
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="fv-row mb-2">
                                                    <label class="fs-6 fw-bold mb-2">{{'_VPC' | translate}}</label>
                                                    <input type="number" min="0" class="form-control form-control-solid"
                                                        placeholder="" name="vpc" value="" formControlName="vpc"
                                                        [(ngModel)]="vpc" (change)="calculateMargin()"
                                                        (change)="calculatePrices()"
                                                        [ngClass]="{ 'is-invalid': isSubmitted && f.vpc.errors }" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="fv-row mb-2">
                                            <label class="fs-6 fw-bold mb-2">{{'_Margin' | translate}}</label>
                                            <input type="number" min="0" class="form-control form-control-solid"
                                                placeholder="" name="margin" value="" formControlName="margin"
                                                [(ngModel)]="margin"
                                                [ngClass]="{ 'is-invalid': isSubmitted && f.margin.errors }" />
                                        </div>

                                        <div class="row fv-row">
                                            <div class="col-xl-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold mb-2">{{'_Happy_hour_price' |
                                                        translate}}</label>
                                                    <input type="number" min="0" class="form-control form-control-solid"
                                                        placeholder="" name="happyHourPrice" value=""
                                                        formControlName="happyHourPrice" [(ngModel)]="hh"
                                                        [ngClass]="{ 'is-invalid': isSubmitted && f.happyHourPrice.errors }" />
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold mb-2">{{'_Min_amount_WH' |
                                                        translate}}</label>
                                                    <input type="number" min="0" class="form-control form-control-solid"
                                                        placeholder="" name="minAmount" value=""
                                                        formControlName="minAmount"
                                                        [ngClass]="{ 'is-invalid': isSubmitted && f.minAmount.errors }" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card mb-3">
                                    <div class="card-body px-7">
                                        <div class="fv-row mb-2">
                                            <label class="fs-6 fw-bold mb-2">{{'_Description' | translate}}</label>
                                            <textarea rows="4" class="form-control form-control-solid" placeholder=""
                                                name="description" value="" formControlName="description"></textarea>
                                        </div>
                                        <div class="fv-row">
                                            <label class="fs-6 fw-bold mb-2">{{'_Bar_code' | translate}}</label>
                                            <div [hidden]="barcodesList.length==0" class="d-flex px-6 flex-column">
                                                <table class="table align-middle table-row-dashed fs-6">
                                                    <tbody>
                                                        <tr *ngFor="let b of barcodesList">
                                                            <td>{{b}}</td>
                                                            <td><button class="btn btn-primary"
                                                                    (click)="removeBarcode(b)">{{'_Remove'|translate}}</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="row fv-row mb-2">
                                                <div class="col-md-6 fv-row">
                                                    <input type="number" id="newBarcodeEdit"
                                                        (change)="validateBarcode('#newBarcodeEdit', false)"
                                                        class="form-control form-control-solid" [(ngModel)]="newBarcode"
                                                        [ngModelOptions]="{standalone:true}" />
                                                    <p *ngIf="showBarcodeErrorEdit" style="color:red">
                                                        {{barcodeErrorTextEdit|translate}}</p>
                                                </div>
                                                <div class="col-md-6 fv-row">
                                                    <button class="btn btn-light-primary"
                                                        (click)="addBarcode('#newBarcodeEdit', false)">{{'_Add_barcode'|translate}}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row fv-row">
                                            <div class="col-xl-6">
                                                <div class="row fv-row mb-10">
                                                    <label class="fs-5 fw-bold form-label mb-5">{{'_Is_normative' |
                                                        translate}}:</label>
                                                    <div class="d-flex flex-column">
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="true"
                                                                formControlName="isNormative" [(ngModel)]="isNormative"
                                                                name="isNormative" (change)="negateHalfProduct()" />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_Yes_text'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="false"
                                                                formControlName="isNormative" [(ngModel)]="isNormative"
                                                                name="isNormative" />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_No_text'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row fv-row mb-10" *ngIf="isNormative">
                                                    <button class="btn btn-info" data-bs-target="#normativeDataModal" data-bs-toggle="modal"
                                                        #editNormativeDataTrigger>{{'_Edit_normative_data'|translate}}</button>
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="row fv-row mb-10">
                                                    <label class="fs-5 fw-bold form-label mb-5">{{'_Is_half_product' |
                                                        translate}}:</label>
                                                    <div class="d-flex flex-column">
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="true"
                                                                formControlName="shouldProduce"
                                                                [(ngModel)]="shouldProduce" name="shouldProduce"
                                                                (change)="negateNormative()" />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_Yes_text'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="false"
                                                                formControlName="shouldProduce"
                                                                [(ngModel)]="shouldProduce" name="shouldProduce" />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_No_text'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row fv-row mb-10" *ngIf="shouldProduce">
                                                    <button class="btn btn-info" data-bs-target="#halfProductDataModal" data-bs-toggle="modal"
                                                        #editHalfProductDataTrigger>{{'_Edit_half_product_data'|translate}}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row fv-row">
                                            <div class="col-xl-6">
                                                <div class="row fv-row mb-2">
                                                    <label class="fs-5 fw-bold form-label mb-5">{{'_Status' |
                                                        translate}}:</label>
                                                    <div class="d-flex flex-column">
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="true"
                                                                formControlName="addEditIsActive"
                                                                [(ngModel)]="addEditIsActive" name="addEditIsActive"
                                                                checked />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_Active'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                        <label
                                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" [value]="false"
                                                                formControlName="addEditIsActive"
                                                                [(ngModel)]="addEditIsActive" name="addEditIsActive" />
                                                            <span
                                                                class="form-check-label text-gray-600 fw-bold">{{'_Inactive'
                                                                |
                                                                translate}}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div id="kt_image_input_edit" class="image-input image-input-empty"
                                                    style="background-image: url(/assets/media/svg/avatars/blank.svg)">
                                                    <!--begin::Image preview wrapper-->
                                                    <div class="image-input-wrapper w-125px h-125px"
                                                        id="imagePreviewEdit"></div>
                                                    <!--end::Image preview wrapper-->

                                                    <!--begin::Edit button-->
                                                    <label
                                                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="change" data-bs-toggle="tooltip"
                                                        data-bs-dismiss="click" title="Change image">
                                                        <i class="bi bi-pencil-fill fs-7"></i>

                                                        <!--begin::Inputs-->
                                                        <input type="file" name="avatar"
                                                            (change)="onFileSelected($event)"
                                                            accept=".png, .jpg, .jpeg" />
                                                        <input type="hidden" name="avatar_remove" />
                                                        <!--end::Inputs-->
                                                    </label>
                                                    <!--end::Edit button-->

                                                    <!--begin::Cancel button-->
                                                    <span
                                                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="cancel" data-bs-toggle="tooltip"
                                                        data-bs-dismiss="click" title="Cancel">
                                                        <i class="bi bi-x fs-2"></i>
                                                    </span>
                                                    <!--end::Cancel button-->

                                                    <!--begin::Remove button-->
                                                    <span
                                                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="remove" data-bs-toggle="tooltip"
                                                        data-bs-dismiss="click" title="Remove">
                                                        <i class="bi bi-x fs-2"></i>
                                                    </span>
                                                    <!--end::Remove button-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button type="button" id="kt_modal_edot_article_cancel" class="btn btn-light me-3"
                        (click)="closeEditModal()">
                        {{'_Discard' | translate}}</button>
                    <button type="button" id="kt_modal_edit_article_submit" class="btn btn-primary"
                        (click)="editArticle()" [tabIndex]="skipAfterMPC?2:1">
                        <span class="indicator-label">{{'_Submit' | translate}}</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="normativeDataModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalToggleLabel2">{{'_Normative_data_singular' | translate}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" hidden></button>
            </div>
            <div class="modal-body">
                <div class="fv-row mb-2">
                    <div class="d-flex align-items-center position-relative my-1">
                        <span class="svg-icon svg-icon-1 position-absolute ms-6" *ngIf="data.currentMode=='light'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="black" />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="black" />
                            </svg>
                        </span>
                        <span class="svg-icon svg-icon-1 position-absolute ms-6" *ngIf="data.currentMode=='dark'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="gray" />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="gray" />
                            </svg>
                        </span>
                        <input (change)="searchForNormative()" tabindex="1" (focus)="showTable()" #normativeSearch
                            type="text" data-kt-customer-table-filter="search"
                            class="form-control form-control-solid w-250px ps-15"
                            placeholder="{{'_Search_articles' | translate}}" />
                    </div>
                </div>
                <div class="fv-row mb-7" id="normativeTableWrapper" (blur)="hideTable()">
                    <div class="fv-row mb-1 d-flex justify-content-end">
                        <div tabindex="1" id="normativeTableClose" class="btn btn-icon btn-sm btn-active-icon-primary"
                            (click)="hideTable()">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="black" />
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="black" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="table-window" style="border: dashed #eee 1px;">
                        <table class="table align-middle fs-6 gy-5" style="margin-bottom:0px;">
                            <div class="bg-gray-200">
                                <thead class="scroll-items-header">
                                    <tr class="fs-5 fw-bold form-label" style="display: table;
                            width: 100%;
                            table-layout: fixed;">
                                        <th style="padding-left:20px;font-weight: bold;width:40%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">
                                            {{'_Code'|translate}}</th>
                                        <th style="font-weight: bold;width:40%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">
                                            {{'_Article_name'|translate}}</th>
                                        <th style="font-weight: bold;width:20%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">
                                            {{'_NC'|translate}}</th>
                                    </tr>
                                </thead>
                            </div>
                            <div class="hover-scroll-overlay-y scroll-items" (scroll)="checkBottom(normativeScroll)"
                                id="normativeScroll" #normativeScroll style=" margin-top: 0%;
                        padding-top: 0%;
                        max-height: 20em;
                        width: 100%;
                       overflow-y: scroll; ">
                                <tbody>
                                    <tr tabindex="1" *ngFor="let a of normativeSelection" class="fs-5 clickableRow"
                                        (click)="selectForNormative(a)" (keydown)="enterOnRow($event)" [hidden]="this.selectedArticle?.id==a.id"
                                        style="border-bottom: #eee dashed 1px !important;">
                                        <td
                                            style="padding-left:20px;width:40%;font-size:14px;padding-top:8px;padding-bottom:8px;">
                                            {{a.code}}</td>
                                        <td style="width:40%;font-size:14px;padding-top:8px;padding-bottom:8px;">
                                            {{a.name}}</td>
                                        <td style="width:20%;font-size:14px;padding-top:8px;padding-bottom:8px;">
                                            {{a.nc}} {{selectedCurrency}}</td>
                                    </tr>
                                </tbody>
                            </div>
                        </table>
                        <br>
                    </div>
                </div>

                <div class="row g-9 mb-5" style="margin-top:10px;border-top:1px dashed #eee">
                    <div class="fv-row mb-2" *ngIf="articleForNormative!=undefined">
                        <p class="mb-0 fs-4 text-uppercase">
                            <span>{{'_Selected_article' | translate}}:</span> <span
                                class="mx-3"><b>{{articleForNormative.name}}</b></span>
                        </p>
                        <p class="mb-0 fs-4 text-uppercase">
                            <span>{{'_Measure' | translate}}:</span> <span><b>{{articleForNormative.measureName}}</b></span>
                        </p>
                    </div>
                    <div class="fv-row mb-2" *ngIf="editingNormativeItem!=undefined">
                        <p class="mb-0 fs-4 text-uppercase">
                            <span>{{'_Editing_article' | translate}}:</span> <span
                                class="mx-3"><b>{{editingNormativeItem?.name}}</b></span>
                        </p>
                        <p class="mb-0 fs-4 text-uppercase">
                            <span>{{'_Measure' | translate}}:</span> <span><b>{{editingNormativeItem?.measure}}</b></span>
                        </p>
                    </div>
                    <label class="fs-5 fw-bolder mb-1" style="margin-left:4px;">{{'_Quantity' | translate}}</label>
                    <div class="col-md-6 fv-row" style="margin-top:8px;">
                        <input type="number" min="0" class="form-control form-control-solid" placeholder=""
                            name="normativeAmount" [(ngModel)]="normativeAmount" id="normativeAmount" tabindex="1" />
                    </div>
                    <div class="col-md-6 fv-row" style="margin-top:8px;padding-left:0px;">
                        <button type="button" tabindex="1" class="btn btn-primary" (click)="addNormative()"
                            *ngIf="editingNormativeItem==undefined">{{'_Add_text'|translate}}</button>
                        <button type="button" tabindex="1" class="btn btn-primary" (click)="updateNormativeAmount()"
                            *ngIf="editingNormativeItem!=undefined">{{'_Apply_text'|translate}}</button>
                        <button type="button" tabindex="1" class="btn btn-danger mx-2" (click)="cancelEditingNormativeAmount()"
                            *ngIf="editingNormativeItem!=undefined">{{'_Discard'|translate}}</button>
                    </div>
                </div>
                <div class="fv-row mb-7">
                    <table class="table table-row-dashed">
                        <thead class="bg-gray-100">
                            <tr class="text-uppercase text-gray-900 fw-bolder">
                                <th style="font-size:14px;width:20%">{{'_Article_name' | translate}}</th>
                                <th style="font-size:14px;width:20%">{{'_Quantity' | translate}}</th>
                                <th style="font-size:14px;width:15%">{{'_JM' | translate}}</th>
                                <th style="font-size:14px;width:15%">{{'_NC' | translate}}</th>
                                <th style="font-size:14px;width:15%">{{'_NV' | translate}}</th>
                                <th style="font-size:14px;width:15%">{{'_Actions' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let a of workingNormativeData" class="text-gray-1000"
                                style="vertical-align: middle;border-bottom:1px dashed #eee">
                                <td style="width:20%">{{a.name}}</td>
                                <td style="width:20%">{{a.amount| number:'1.6-6'}}</td>
                                <td style="width:15%">{{a.measure}}</td>
                                <td style="width:15%">{{a.nc | number:'1.2-2'}}</td>
                                <td style="width:15%">{{a.nc*a.amount | number:'1.2-2'}}</td>
                                <td style="width:15%">
                                    <a (click)="startEditingNormativeAmount(a)" class="btn btn-icon btn-warning fs-8"
                                        *ngIf="editingNormativeItem==undefined" style="margin-right:4px;"><i
                                            class="fa fa-pencil-alt fs-4" aria-hidden="true"></i></a>
                                    <a (click)="deleteNormative(a)" class="btn btn-icon btn-danger fs-8"
                                        *ngIf="editingNormativeItem==undefined"><i class="fa fa-times fs-4"
                                            aria-hidden="true"></i></a>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td class="fs-3 text-uppercase">{{'_Total_NV'|translate}}:
                                    <b>{{normativeTotal|number:'1.2-2'}} BAM</b></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="modal-footer flex-center" *ngIf="selectedArticle==undefined && editingNormativeItem==undefined">
                <button type="button" id="addNormativeDataCancel" class="btn btn-danger me-3" (click)="resetNormativeData()">
                    <i class="bi bi-arrow-clockwise fs-2"></i>
                    {{'_Reset_normative_data' | translate}}</button>
                <button tabindex="1" type="submit" id="addNormativeDataSubmit" class="btn btn-primary"
                    data-bs-target="#kt_modal_add_article" data-bs-toggle="modal">
                    <i class="bi bi-arrow-left-circle fs-2"></i>
                    <span class="indicator-label">{{'_Back_to_general_info' | translate}}</span>
                    <span class="indicator-progress">Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
            </div>
            <div class="modal-footer flex-center" *ngIf="selectedArticle!=undefined && editingNormativeItem==undefined">
                <button type="button" id="addNormativeDataCancel" class="btn btn-danger me-3" (click)="resetNormativeData()">
                    <i class="bi bi-arrow-clockwise fs-2"></i>
                    {{'_Reset_normative_data' | translate}}</button>
                <button type="submit" id="addNormativeDataSubmit" class="btn btn-primary"
                    data-bs-target="#kt_modal_edit_article" data-bs-toggle="modal">
                    <i class="bi bi-arrow-left-circle fs-2"></i>
                    <span class="indicator-label">{{'_Back_to_general_info' | translate}}</span>
                    <span class="indicator-progress">Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="halfProductDataModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalToggleLabel2">{{'_Half_product_data' | translate}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" hidden></button>
            </div>
            <div class="modal-body">
                <div class="fv-row mb-2">
                    <div class="d-flex align-items-center position-relative my-1">
                        <span class="svg-icon svg-icon-1 position-absolute ms-6" *ngIf="data.currentMode=='light'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="black" />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="black" />
                            </svg>
                        </span>
                        <span class="svg-icon svg-icon-1 position-absolute ms-6" *ngIf="data.currentMode=='dark'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="gray" />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="gray" />
                            </svg>
                        </span>
                        <input (change)="searchForHalfProduct()" tabindex="1" (focus)="showTableHalfProduct()"
                            #halfProductSearch type="text" data-kt-customer-table-filter="search"
                            class="form-control form-control-solid w-250px ps-15" id="halfProductSearch"
                            placeholder="{{'_Search_articles' | translate}}" />
                    </div>
                </div>
                <div class="fv-row mb-7" id="halfProductTableWrapper" (blur)="hideTableHalfProduct()">
                    <div class="fv-row mb-1 d-flex justify-content-end">
                        <div tabindex="1" id="halfProductTableClose" class="btn btn-icon btn-sm btn-active-icon-primary"
                            (click)="hideTableHalfProduct()">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="black" />
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="black" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="table-window" style="border: dashed #eee 1px;">
                        <table class="table align-middle fs-6 gy-5" style="margin-bottom:0px;">
                            <div class="bg-gray-200">
                                <thead class="scroll-items-header">
                                    <tr class="fs-5 fw-bold form-label" style="display: table;
                            width: 100%;
                            table-layout: fixed;">
                                        <th style="padding-left:20px;font-weight: bold;width:40%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">
                                            {{'_Code'|translate}}</th>
                                        <th style="font-weight: bold;width:40%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">
                                            {{'_Article_name'|translate}}</th>
                                        <th style="font-weight: bold;width:20%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">
                                            {{'_NC'|translate}}</th>
                                    </tr>
                                </thead>
                            </div>
                            <div class="hover-scroll-overlay-y scroll-items" (scroll)="checkBottom(halfProductScroll)"
                                id="halfProductScroll" #halfProductScroll style=" margin-top: 0%;
                        padding-top: 0%;
                        max-height: 20em;
                        width: 100%;
                       overflow-y: scroll; ">
                                <tbody>
                                    <tr tabindex="1" *ngFor="let a of halfProductSelection" class="fs-5 clickableRow"
                                        (click)="selectForHalfProduct(a)" (keydown)="enterOnRow($event)" [hidden]="this.selectedArticle?.id==a.id"
                                        style="border-bottom: #eee dashed 1px !important;">
                                        <td
                                            style="padding-left:20px;width:40%;font-size:14px;padding-top:8px;padding-bottom:8px;">
                                            {{a.code}}</td>
                                        <td style="width:40%;font-size:14px;padding-top:8px;padding-bottom:8px;">
                                            {{a.name}}</td>
                                        <td style="width:20%;font-size:14px;padding-top:8px;padding-bottom:8px;">
                                            {{a.nc}} {{selectedCurrency}}</td>
                                    </tr>
                                </tbody>
                            </div>
                        </table>
                        <br>
                    </div>
                </div>
                <div class="fv-row mb-2" *ngIf="articleForHalfProduct!=undefined">
                    <p class="mb-0 fs-4 text-uppercase">
                        <span>{{'_Selected_article' | translate}}:</span> <span
                            class="mx-3"><b>{{articleForHalfProduct.name}}</b></span>
                    </p>
                    <p class="mb-0 fs-4 text-uppercase">
                        <span>{{'_Measure' | translate}}:</span> <span><b>{{articleForHalfProduct.measureName}}</b></span>
                    </p>
                </div>
                <div class="fv-row mb-2" *ngIf="editingHalfProductItem!=undefined">
                    <p class="mb-0 fs-4 text-uppercase">
                        <span>{{'_Editing_article' | translate}}:</span> <span
                            class="mx-3"><b>{{editingHalfProductItem?.name}}</b></span>
                    </p>
                    <p class="mb-0 fs-4 text-uppercase">
                        <span>{{'_Measure' | translate}}:</span> <span><b>{{editingHalfProductItem?.measure}}</b></span>
                    </p>
                </div>
                <div class="row g-9 mb-5" style="margin-top:10px;border-top:1px dashed #eee">
                    <label class="fs-5 fw-bolder mb-1" style="margin-left:4px;">{{'_Quantity' | translate}}</label>
                    <div class="col-md-6 fv-row" style="margin-top:8px;">
                        <input type="number" min="0" class="form-control form-control-solid" placeholder=""
                            name="halfProductAmount" [(ngModel)]="halfProductAmount" id="halfProductAmount"
                            tabindex="1" />
                    </div>
                    <div class="col-md-6 fv-row" style="margin-top:8px;padding-left:0px;">
                        <button type="button" tabindex="1" class="btn btn-primary" (click)="addHalfProduct()"
                            *ngIf="editingHalfProductItem==undefined">{{'_Add_text'|translate}}</button>
                        <button type="button" tabindex="1" class="btn btn-primary" (click)="updateHalfProductAmount()"
                            *ngIf="editingHalfProductItem!=undefined">{{'_Apply_text'|translate}}</button>
                        <button type="button" tabindex="1" class="btn btn-danger mx-2" (click)="cancelEditingHalfProductAmount()"
                            *ngIf="editingHalfProductItem!=undefined">{{'_Discard'|translate}}</button>
                    </div>
                </div>
                <div class="fv-row mb-7">
                    <table class="table table-row-dashed">
                        <thead class="bg-gray-100">
                            <tr class="text-uppercase text-gray-900 fw-bolder">
                                <th style="font-size:14px;width:20%">{{'_Article_name' | translate}}</th>
                                <th style="font-size:14px;width:20%">{{'_Quantity' | translate}}</th>
                                <th style="font-size:14px;width:15%">{{'_JM' | translate}}</th>
                                <th style="font-size:14px;width:15%">{{'_NC' | translate}}</th>
                                <th style="font-size:14px;width:15%">{{'_NV' | translate}}</th>
                                <th style="font-size:14px;width:15%">{{'_Actions' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let a of workingHalfProductData" class="text-gray-1000"
                                style="vertical-align: middle;border-bottom:1px dashed #eee">
                                <td style="width:20%">{{a.name}}</td>
                                <td style="width:20%">{{a.quantity| number:'1.6-6'}}</td>
                                <td style="width:15%">{{a.measure}}</td>
                                <td style="width:15%">{{a.nc | number:'1.2-2'}}</td>
                                <td style="width:15%">{{a.nc*a.quantity | number:'1.2-2'}}</td>
                                <td style="width:15%">
                                    <a (click)="startEditingHalfProductAmount(a)" class="btn btn-icon btn-warning fs-8"
                                        *ngIf="editingHalfProductItem==undefined" style="margin-right:4px;"><i
                                            class="fa fa-pencil-alt fs-4" aria-hidden="true"></i></a>
                                    <a (click)="deleteHalfProduct(a)" class="btn btn-icon btn-danger fs-8"
                                        *ngIf="editingHalfProductItem==undefined && editingHalfProductItem==null"><i
                                            class="fa fa-times fs-4" aria-hidden="true"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer flex-center" *ngIf="selectedArticle==undefined && editingHalfProductItem==undefined">
                <button type="button" id="addHalfProductDataCancel" class="btn btn-danger me-3" (click)="resetHalfProductData()">
                    <i class="bi bi-arrow-clockwise fs-2"></i>
                    {{'_Reset_half_product_data' | translate}}
                </button>
                <button tabindex="1" type="submit" id="addHalfProductDataSubmit" class="btn btn-primary"
                    data-bs-target="#kt_modal_add_article" data-bs-toggle="modal">
                    <i class="bi bi-arrow-left-circle fs-2"></i>
                    <span class="indicator-label">{{'_Back_to_general_info' | translate}}</span>
                    <span class="indicator-progress">Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
            </div>
            <div class="modal-footer flex-center" *ngIf="selectedArticle!=undefined && editingNormativeItem==undefined">
                <button type="button" id="addHalfProductDataCancel" class="btn btn-danger me-3" (click)="resetHalfProductData()">
                    <i class="bi bi-arrow-clockwise fs-2"></i>
                    {{'_Reset_half_product_data' | translate}}
                </button>
                <button type="submit" id="addNormativeDataSubmit" class="btn btn-primary"
                    data-bs-target="#kt_modal_edit_article" data-bs-toggle="modal">
                    <i class="bi bi-arrow-left-circle fs-2"></i>
                    <span class="indicator-label">{{'_Back_to_general_info' | translate}}</span>
                    <span class="indicator-progress">Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
            </div>
        </div>
    </div>
</div>

<ng-http-loader [spinner]="'sk-wave'"></ng-http-loader>