import { SoldArticleModel } from "./soldArticle.model";

export class EstimateModel{
    id:string;
    clientId:number;
    estimateNumber:string;
    documentNumber:number;
    customDocumentNumber:number;
    customerId:number;
    customerName:string;
    customerIDNumber:string;
    customerPDVNumber:string;
    customerAddress:string;
    createdAt:Date;
    estimateDate:Date;
    arrivalDate:Date;
    paymentTypeId:number;
    currencyId:number;
    warehouseId:number;
    taxId:number;
    note:string;
    estimateDiscount:number;
    totalAmount:number;
    totalBase:number;
    totalDiscount:number;
    totalTaxAmount:number;
    articles:SoldArticleModel[];
}