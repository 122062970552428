import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleRestService } from 'src/app/services/article.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { WarehouseTransferRestService } from 'src/app/services/warehouse-transfer.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { GroupModel } from 'src/app/models/group.model';
import { TranslateService } from 'src/app/translate';
import { ArticleModel } from 'src/app/models/article.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import toastr from 'toastr';
import { WarehouseTransferModel } from 'src/app/models/documents/warehouseTransfer.model';
import { MovingArticleModel } from 'src/app/models/documents/movingArticle.model';
import { minValidator } from 'src/app/validators/minValidator';
import { WarehouseTransferSaveModel } from 'src/app/models/documents/pageSaveModels/warehouseTransferSave.model';
import { TaxModel } from 'src/app/models/tax.model';
import { CurrencyModel } from 'src/app/models/currency.model';
import { CurrencyRestService } from 'src/app/services/currency.service';
import { ItemLookupComponent } from '../item-lookup/item-lookup.component';
import { Router } from '@angular/router';
import { DocumentPdfComponent } from '../document-pdf/document-pdf.component';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;

@Component({
    selector: 'app-warehouse-transfer',
    templateUrl: './warehouse-transfer.component.html',
})
export class WarehouseTransferComponent implements OnInit {
    public selectedRow: string = undefined;
    public warehouseTransfers: WarehouseTransferModel[];
    public groups: GroupModel[];
    public warehouses: WarehouseModel[];
    public emptyData: boolean = false;
    public searchForm: UntypedFormGroup;
    public addForm: UntypedFormGroup;
    dateFrom: string;
    dateTo: string;
    customerName: string = '';
    public isSubmitted: boolean;
    public selectedTransfer: WarehouseTransferModel;
    public selectedTransferWarehouse: WarehouseModel;
    public addWarehouseFromId: number = 0;
    public addWarehouseToId: number = 0;
    public addDate: Date = new Date();
    public filterWarehouseFromId: number;
    public filterWarehouseToId: number;
    public headerForm: UntypedFormGroup;
    get hForm() { return this.headerForm.controls; }

    public headerFormEnabled: boolean;
    public itemFormEnabled: boolean;

    public activeTransfer: WarehouseTransferModel;
    public activeTransferPreEdit: WarehouseTransferModel;
    public selectedArticle: ArticleModel;
    public totalAmount: number = 0;
    public taxes: TaxModel[];
    public selectedInvoiceTax: TaxModel;
    public addTaxId: number = 0;
    public nc: number;
    public ncDisplay: number;
    public postDiscountPrice: number;
    public discount: number;
    public addItemForm: UntypedFormGroup;
    public selectedCurrency: string;
    get iForm() { return this.addItemForm.controls; }
    selectionModalTimer: any;
    enterCount: number = 0;
    creationInProgress: boolean = false;
    public previousWarehouseId: number;
    public pdfSource: any;

    @ViewChild('closeAddTransferHeaderModal') closeAddTransferHeaderModal: ElementRef;
    @ViewChild('closeAddTransferItemsModal') closeAddTransferItemsModal: ElementRef;
    @ViewChild('closeEditTransferHeaderModal') closeEditTransferHeaderModal: ElementRef;
    @ViewChild('closeEditTransferItemsModal') closeEditTransferItemsModal: ElementRef;
    @ViewChild('selectAddTransferWarehouseFromId') selectAddTransferWarehouseFromId: ElementRef;
    @ViewChild('selectAddTransferWarehouseToId') selectAddTransferWarehouseToId: ElementRef;
    @ViewChild('selectTaxId') selectTaxId: ElementRef;
    @ViewChild('selectAddTransferCurrencyId') selectAddTransferCurrencyId: ElementRef;
    @ViewChild('firstHeaderForm') firstHeaderFormElement: ElementRef;

    @ViewChild('closeSelectItemModal') closeSelectItemModal: ElementRef;
    @ViewChild('headerTrigger') headerTrigger: ElementRef;
    @ViewChild('itemsTrigger') itemsTrigger: ElementRef;
    @ViewChild('listTrigger') listTrigger: ElementRef;
    @ViewChild('documentNumberOpen') documentNumberOpen: ElementRef;
    @ViewChild('documentNumberClose') documentNumberClose: ElementRef;
    @ViewChild('itemCodeInput') itemCodeInput: ElementRef;
    @ViewChild('documentNumberInput') documentNumberInput: ElementRef;
    @ViewChild('selectFilterWarehouseFromId') selectFilterWarehouseFromId: ElementRef;
    @ViewChild('selectFilterWarehouseToId') selectFilterWarehouseToId: ElementRef;
    @ViewChild('addItemsButton') addItemsButton: ElementRef;
    @ViewChild('saveHeaderAddButton') saveHeaderAddButton: ElementRef;
    @ViewChild('saveHeaderEditButton') saveHeaderEditButton: ElementRef;
    @ViewChild('addItemButton') addItemButton: ElementRef;
    @ViewChild('amountInput') amountInput: ElementRef;
    @ViewChild('saveEditItemButton') saveEditItemButton: ElementRef;
    @ViewChild(ItemLookupComponent) itemLookupComponent: ItemLookupComponent;
    @ViewChild(DocumentPdfComponent) documentPdfComponent: DocumentPdfComponent;

    public showItems: boolean = false;
    public setUpComplete: boolean = false;
    public editEnabled: boolean = false;
    public startedEditing: boolean = false;
    public nextNumber: number;
    public addEnabled: boolean = false;
    public headerSaved: boolean = false;
    public editingItems: boolean = false;
    public newTransferActive: boolean = false;
    public editingItem: MovingArticleModel;
    public editingItemIndex: number;
    public addCurrencyId: number = 0;
    public currencies: CurrencyModel[];
    public documentArticles: ArticleModel[];
    public onHeaderTab: boolean = false;
    public shiftPressed:boolean=false;
    public showPdf:boolean=false;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;
    public resizeObserver: ResizeObserver;

    constructor(
        private warehouseTransferRest: WarehouseTransferRestService,
        private articleRestService: ArticleRestService,
        private groupRestService: GroupRestService,
        private warehouseRestService: WarehouseRestService,
        private datePipe: DatePipe,
        private _changeRef: ChangeDetectorRef,
        public data: DataService,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private currencyRestService: CurrencyRestService,
        private router: Router
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    @HostListener('document:keydown', ['$event'])
    checkForShiftPress(event: KeyboardEvent) {
      if(event.key=='Shift'){
        this.shiftPressed=true;
      }
    }
    @HostListener('document:keyup', ['$event'])
    checkForShiftRelease(event: KeyboardEvent) {
      if(event.key=='Shift'){
        this.shiftPressed=false;
      }
    }
    public loopHeaderTab(event: KeyboardEvent, lastElement:boolean){
        if(lastElement){
            if(event.key=='Tab' && !this.shiftPressed){
                event.preventDefault();
                this.firstHeaderFormElement.nativeElement.focus();
            }
        }else{
            if(event.key=='Tab' && this.shiftPressed){
                event.preventDefault();
                if(this.startedEditing)
                    this.saveHeaderEditButton.nativeElement.focus();
                else
                    this.saveHeaderAddButton.nativeElement.focus();
            }
        }
    }
    public loopItemTab(event: KeyboardEvent, lastElement:boolean, potentialLimit:boolean=false){
        if(lastElement){
            if(event.key=='Tab' && !this.shiftPressed){
                event.preventDefault();
                if(this.editingItem==null || this.editingItem==undefined)
                    this.itemCodeInput.nativeElement.focus();
                else 
                    this.amountInput.nativeElement.focus();
            }
        }else{
            if(event.key=='Tab' && this.shiftPressed){
                if(potentialLimit){
                    if(this.editingItem!=null && this.editingItem!=undefined){
                        event.preventDefault();
                        this.saveEditItemButton.nativeElement.focus();
                    }
                }else{
                    event.preventDefault();
                    if(this.editingItem==null || this.editingItem==undefined)
                        this.addItemButton.nativeElement.focus();
                    else 
                        this.saveEditItemButton.nativeElement.focus();
                }
            }
        }
    }

    ngOnInit(): void {
        KTMenu.createInstances();
        this.fetchTransfers();
        this.fetchGroups();
        this.fetchTaxes();
        this.fetchWarehouses();
        this.createSearchForm();
        this.createHeaderForm();
        this.createAddItemForm();
        this.fetchCurrencies();
    }

    ngAfterViewInit() {
        Object.keys(this.headerForm.controls).forEach(name => {
            let control = this.headerForm.get(name);

            control.valueChanges.subscribe(value => this.updateCreatingTransfer(name, value));
        })

        $(this.headerTrigger.nativeElement).on('shown.bs.tab', () => {
            this.selectAddTransferWarehouseFromId.nativeElement.focus();
        });

        $(this.itemsTrigger.nativeElement).on('shown.bs.tab', () => {
            if(this.editingItem==null)
                this.itemCodeInput.nativeElement.focus();
            else 
                this.amountInput.nativeElement.focus();
        });

        $("#kt_modal_invoice_number").on('shown.bs.modal', () => {
            this.documentNumberInput.nativeElement.focus();
        });
        $("#kt_modal_invoice_number").on('hidden.bs.modal', () => {
            this.firstHeaderFormElement.nativeElement.focus();
        });
    }

    valuesMatch() {
        var check = true;

        Object.keys(this.headerForm.controls).forEach(name => {
            let control = this.headerForm.get(name);
            if (control.value != this.activeTransfer[name]) {
                check = false;
            }
            else {
            }
        });

        return check;
    }

    async createSearchForm() {
        this.searchForm = this.formBuilder.group({
            filterWarehouseFromId: [null],
            filterWarehouseToId: [null],
        })

        $("#transfer_filter_warehouseFromId").select2();
        $('#transfer_filter_warehouseFromId').on("change", function () {
            self.selectFilterWarehouseFromId.nativeElement.value = $(this).val();
            self.filterWarehouseFromId = $(this).val();
        });

        $("#transfer_filter_warehouseToId").select2();
        $('#transfer_filter_warehouseToId').on("change", function () {
            self.selectFilterWarehouseToId.nativeElement.value = $(this).val();
            self.filterWarehouseToId = $(this).val();
        });

        var start = new Date(new Date().getFullYear(), 0, 1);
        var end = new Date();
        var self = this;
        $("#kt_daterangepicker_filter").daterangepicker({
            startDate: start,
            endDate: end,
            autoApply: true,
            autoUpdateInput: true,
            locale: {
                format: 'DD/MM/YYYY',
                separator: '-'
            }
        });
        $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            $("#searchByCustomerInput").focus();
            self.fetchTransfers();
        });
        $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
            picker.setStartDate(new Date());
            picker.setEndDate(new Date());
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            self.fetchTransfers();
        });
        var im = new Inputmask("99/99/9999-99/99/9999");
        im.mask($("#kt_daterangepicker_filter"));
    }

    async resetFilter() {
        this.searchForm.reset();
        $("#transfer_filter_warehouseFromId").val(null).trigger("change");
        $("#transfer_filter_warehouseToId").val(null).trigger("change");
    }

    filterByDate(event: KeyboardEvent) {
        if (event.key == "Enter" || event.key == "Tab") {
            var pickerElement = $("#kt_daterangepicker_filter");
            $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        }
    }

    async fetchGroups() {
        try {
            var response = await this.groupRestService.getGroups('', null, null, 1, 100000);
            this.groups = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchWarehouses() {
        try {
            this.warehouses = await this.warehouseRestService.getWarehousesForClient(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchTaxes() {
        try {
            this.taxes = await this.groupRestService.getTaxesByClientId(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchCurrencies() {
        try {
            this.currencies = await this.currencyRestService.getCurrencies();
            if (this.activeTransfer != undefined && this.activeTransfer.currencyId != undefined)
                this.selectedCurrency = this.currencies.find(x => x.id == this.activeTransfer.currencyId).code;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchTransfers() {
        try {
            this.emptyData = false;
            if (this.dateFrom == undefined)
                this.dateFrom = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
            if (this.dateTo == undefined)
                this.dateTo = this.datePipe.transform((new Date()), 'yyyy-MM-dd');
            var response = await this.warehouseTransferRest.getWarehouseTransfers(this.filterWarehouseFromId, this.filterWarehouseToId, this.dateFrom, this.dateTo, this.data.user.clientId, this.page, this.perPage);
            this.warehouseTransfers = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchInvoicePdf(id: string) {
        try{
        var response = await this.warehouseTransferRest.getWarehouseTransfersPDF(this.data.user.clientId, id);
        this.pdfSource = window.URL.createObjectURL(response);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
            else {
                toastr.options = this.data.toastrOptions;
                toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
            }
        }
    }

    async exportPDF() {
        await this.fetchInvoicePdf(this.activeTransfer.id);
        if (this.pdfSource){
            // this.router.navigate(['/warehouse-transfer-pdf'], { queryParams: { pdfSrc: this.pdfSource } });
            this.showPdf=true;
            this.documentPdfComponent.setSource(this.pdfSource);
        }
        else {
            toastr.options = this.data.toastrOptions;
            toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
        }
    }
    closePDF(){
        this.showPdf=false;
    }

    async getNextNumber() {
        this.nextNumber = await this.warehouseTransferRest.getNextNumber(this.data.user.clientId);
        if (this.activeTransfer.documentNumber == undefined) {
            this.activeTransfer.documentNumber = this.nextNumber;
            this.activeTransfer.warehouseTransferNumber = this.nextNumber + '/' + ((new Date()).getFullYear() - 2000);
        }
    }

    resetActiveTransfer() { 
        this.activeTransfer = new WarehouseTransferModel();
        this.activeTransfer.clientId=this.data.user.clientId;
        this.activeTransfer.articles = [];
        this.activeTransfer.totalAmount=0;
        this.activeTransfer.totalDiscount=0;
        this.activeTransferPreEdit = this.activeTransfer;
    }

    switchToArticles() {
        this.itemsTrigger.nativeElement.click();
        this.startAddingItems();
    }

    switchToHeader() {
        this.headerTrigger.nativeElement.click();
    }

    updateCreatingTransfer(name: string, value: any) {
        this.activeTransfer[name] = value;
        if (name == 'warehouseFromId')
            this.activeTransfer.warehouseFromName = this.warehouses.find(x => x.id == value).name;
        if (name == 'warehouseToId')
            this.activeTransfer.warehouseToName = this.warehouses.find(x => x.id == value).name;
    }

    async searchTransfers() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchTransfers();
    }

    openItemsOnEmpty($event: KeyboardEvent = null) {
        var fieldValue = $("#itemCodeInput").val();
        if (!this.itemFormEnabled || (fieldValue != '' && fieldValue != undefined))
            return;

        if ($event != null) {
            if ($event.key == "Enter") {
                this.enterCount++;
                clearTimeout(this.selectionModalTimer);
                this.selectionModalTimer = setTimeout(() => {
                    this.enterCount = 0;
                }, 500);

                if (this.enterCount != 2)
                    return;

                this.itemLookupComponent.openItemsModal();
            }
        } else {
            this.itemLookupComponent.openItemsModal();
        }
    }

    toggleItems(toggle: boolean) {
        this.showItems = toggle;
    }
    toggleDocNav(toggle: boolean) {
        this.onHeaderTab = toggle;
    }

    viewDocuments() {
        this.toggleItems(false);
        this.toggleDocNav(false);
        this.resetForm();
        this.resetItemForm();
        this.disableForm();
        this.headerSaved = false;
        this.startedEditing = false;
        this.editEnabled = false;
        this.addEnabled = false;
        this.editingItems = false;
    }

    selectTransfer(transfer: WarehouseTransferModel, switchToHeaderTab: boolean = true) {
        if (switchToHeaderTab)
            this.headerTrigger.nativeElement.click();

        this.activeTransfer = transfer;
        this.selectedCurrency = this.currencies.find(x => x.id == transfer.currencyId).code;

        $('#add_transfer_warehouseFromId').val(this.activeTransfer.warehouseFromId).trigger("change");
        $('#add_transfer_warehouseToId').val(this.activeTransfer.warehouseToId).trigger("change");
        $('#add_transfer_currencyId').val(this.activeTransfer.currencyId).trigger("change");
        $("#kt_date_add_picker").data('daterangepicker').setStartDate(new Date(this.activeTransfer.date));
        this.hForm.date.setValue(this.activeTransfer.date);
        this.addEnabled = false;
        this.editEnabled = true;
        this.startedEditing = false;
        this.activeTransferPreEdit = this.makePreEditObject();
        this.hForm.note.setValue(this.activeTransfer.note);
        this.selectedArticle = null;

        if (this.addWarehouseFromId != this.previousWarehouseId) {
            this._changeRef.detectChanges();
            this.previousWarehouseId = this.addWarehouseFromId;
            this.itemLookupComponent.fetchArticles(true);
        }

        this.headerSaved = true;
        this.disableForm();
    }

    showList() {
        this.listTrigger.nativeElement.click();
    }

    async selectNext() {
        var index = this.warehouseTransfers.findIndex(x => x.id == this.activeTransfer.id);

        if (index == 0) {
            if (this.page == 1)
                return;

            await this.prevpage();
            await this.fetchTransfers();

            if (this.warehouseTransfers.length > 0) {
                this.selectTransfer(this.warehouseTransfers[this.warehouseTransfers.length - 1]);
            }
        }
        else {
            this.selectTransfer(this.warehouseTransfers[index - 1]);
        }
    }

    async selectPrevious() {
        var index = this.warehouseTransfers.findIndex(x => x.id == this.activeTransfer.id);
        if (index == (this.warehouseTransfers.length - 1)) {
            if (this.page == this.maxPage)
                return;

            await this.nextPage();
            await this.fetchTransfers();

            if (this.warehouseTransfers.length > 0) {
                this.selectTransfer(this.warehouseTransfers[0]);
            }
        }
        else {
            this.selectTransfer(this.warehouseTransfers[index + 1]);
        }
    }

    startEditing(header: boolean) {
        this.enableHeaderForm(false);
        this.disableItemForm();
        this.resetItemForm();
        this.startedEditing = true;
        if (this.editingItems)
            this.editingItems = false;
        if (header) {
            this.headerSaved = false;
            this._changeRef.detectChanges();
            this.firstHeaderFormElement.nativeElement.focus();
        }
    }
    startAddingItems() {
        if (this.itemLookupComponent.articles.length == 0)
            this.itemLookupComponent.fetchArticles();
        this.enableItemForm();
        this.startedEditing = true;
        this.editingItems = true;
    }

    cancelEditing(header: boolean = true) {
        this.selectTransfer(this.activeTransferPreEdit, false);
        this.disableForm();
        if (!header) {
            this.resetItemForm();
        }
        this.startedEditing = false;
        this.editEnabled = true;
        this.addEnabled = false;
        this.editingItems = false;
        if (this.activeTransfer.id != undefined)
            this.headerSaved = true;
    }

    cancelAdding() {
        this.disableForm();
        this.resetActiveTransfer();
        this.addEnabled=false;
        this.resetForm();
    }

    manualInputDate(id: string) {
        var pickerElement = $("#" + id);
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }

    selectOnFocus(id: string) {
        $("#" + id).select();
    }

    async createHeaderForm() {
        this.headerForm = this.formBuilder.group({
            warehouseFromId: [0, Validators.required],
            warehouseToId: [0, Validators.required],
            currencyId: [0, Validators.required],
            date: [],
            note: [''],
        });

        var self = this;
        $("#add_transfer_warehouseFromId").select2();
        $('#add_transfer_warehouseFromId').on("change", function () {
            self.selectAddTransferWarehouseFromId.nativeElement.value = $(this).val();
            self.addWarehouseFromId = $(this).val();
        });
        $("#add_transfer_warehouseToId").select2();
        $('#add_transfer_warehouseToId').on("change", function () {
            self.selectAddTransferWarehouseToId.nativeElement.value = $(this).val();
            self.addWarehouseToId = $(this).val();
        });

        $("#add_transfer_currencyId").select2();
        $('#add_transfer_currencyId').on("change", function () {
            self.selectAddTransferCurrencyId.nativeElement.value = $(this).val();
            self.addCurrencyId = $(this).val();
        });

        var start = (new Date()).setDate((new Date()).getDate() - 29);
        var end = new Date();
        $("#kt_date_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: start,
            endDate: end,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });
        $("#kt_date_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.addDate = picker.startDate._d;
            self.headerForm.controls.date.setValue(self.addDate);
        });
        var im = new Inputmask("99/99/9999");
        im.mask($("#kt_date_add_picker"));

        if (this.editEnabled && !this.startedEditing)
            this.disableForm();
    }

    createAddItemForm() {
        this.addItemForm = this.formBuilder.group({
            quantity: [null, [Validators.required, Validators.min(0.000001)]],
        });
    }

    disableForm() {
        this.disableHeaderForm(false);
        this.disableItemForm();
    }
    disableHeaderForm(emit: boolean = true) {
        if (emit == false) {
            this.headerForm.disable({ emitEvent: false });
        } else {
            this.headerForm.disable();
        }

        this.headerFormEnabled = false;
        $("#kt_date_add_picker").prop("disabled", true);
    }
    disableItemForm() {
        this.addItemForm.disable();
        if (this.itemFormEnabled) {
            this.itemFormEnabled = false;
            setTimeout(() => {
                this.addItemsButton.nativeElement.focus();
            }, 0);
        }
        $("#itemCodeInput").prop("disabled", true);
        $("#itemLookup").addClass("disabled");
    }

    enableForm() {
        this.enableHeaderForm();
        this.enableItemForm();
    }
    enableHeaderForm(emit: boolean = true) {
        if (emit == false)
            this.headerForm.enable({ emitEvent: false });
        else
            this.headerForm.enable();
        this.headerFormEnabled = true;
        $("#kt_date_add_picker").prop("disabled", false);
    }
    enableItemForm() {
        this.addItemForm.enable();
        this.itemFormEnabled = true;
        $("#itemCodeInput").prop("disabled", false);
        $("#itemLookup").removeClass("disabled");
    }

    async resetForm() {
        if(this.warehouses){
            $('#add_transfer_warehouseFromId').val(this.warehouses[0].id).trigger("change");
            $('#add_transfer_warehouseToId').val(this.warehouses[0].id).trigger("change");
        }
        if(this.currencies)
            $('#add_transfer_currencyId').val(this.currencies[0].id).trigger("change");
        if(this.taxes)
            $('#add_item_taxId').val(this.taxes[0].id).trigger("change");
        $("#kt_date_add_picker").data('daterangepicker').setStartDate(new Date());
        this.addDate = new Date();
        this.hForm.date.setValue(this.addDate);
        this.headerForm.controls.note.setValue(null);

        $('#itemCodeInput').val(null);
        this.selectedArticle = undefined;
        this.addItemForm.controls.quantity.setValue(null);

        this.addEnabled = false;
        this.editEnabled = false;
        this.startedEditing = false;

        this.headerFormEnabled = false;
        this.itemFormEnabled = false;
    }

    async addWarehouseTransfer() {
        if (this.creationInProgress){
            return;
        }
        this.creationInProgress = true;
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            this.creationInProgress=false;
            return;
        }

        this.activeTransfer.clientId=this.data.user.clientId;
        try {
            await this.warehouseTransferRest.createWarehouseTransfer(this.activeTransfer);
            this.activeTransfer.id = await this.getNewlyCreatedId();
            this.activeTransferPreEdit = this.makePreEditObject();
            this.headerSaved = true;
            this.editingItems = true;
            this.setPostAddingLayout();

            if (this.addWarehouseFromId != this.previousWarehouseId) {
                this.previousWarehouseId = this.addWarehouseFromId;
                this.itemLookupComponent.fetchArticles(true);
            }

            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_warehouse_transfer_text'), this.translate.instant("_Success"));
            this.fetchTransfers();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }

        this.creationInProgress = false;
    }
    makePreEditObject() {
        var obj = new WarehouseTransferModel();
        Object.keys(this.activeTransfer).forEach(key => {
            if (key != 'articles') {
                let value = this.activeTransfer[key];
                obj[key] = value;
            }
        })
        obj.articles = [];
        this.activeTransfer.articles.forEach(x => {
            var itemCopy = new MovingArticleModel();
            Object.keys(x).forEach(key => {
                let value = x[key];
                itemCopy[key] = value;
            })
            obj.articles.push(itemCopy);
        });
        return obj;
    }
    async getNewlyCreatedId() {
        var response = await this.warehouseTransferRest.getWarehouseTransfers(this.filterWarehouseFromId, this.filterWarehouseToId, this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.data.user.clientId, 1, 1)
        var id = response.data[0].id;
        return id;
    }

    async editWarehouseTransfer(instantReverse: boolean = false) {
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        try {
            await this.warehouseTransferRest.editWarehouseTransfer(this.activeTransfer);
            this.activeTransferPreEdit = this.makePreEditObject();
            this.startedEditing = false;
            this.editingItems = false;
            this.headerSaved = true;
            this.resetItemForm();
            this.disableForm();

            if (this.addWarehouseFromId != this.previousWarehouseId) {
                this.previousWarehouseId = this.addWarehouseFromId;
                this.itemLookupComponent.fetchArticles(true);
            }

            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Edited_warehouse_transfer_text'), this.translate.instant("_Success"));
            this.fetchTransfers();
        }
        catch (err) {
            if (instantReverse)
                this.activeTransfer = this.activeTransferPreEdit;

            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    async deleteTransfer(id: string) {
        var self = this;
        var question_text = this.translate.instant("_Delete_warehouse_transfer_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.warehouseTransferRest.deleteWarehouseTransfer(id);
                if (this.activeTransfer!=undefined && this.activeTransfer.id == id) {
                    this.resetActiveTransfer();
                    this.disableForm();
                    this.startedEditing = false;
                    this.editEnabled = true;
                    this.addEnabled = false;
                    this.editingItems = false;
                    this.resetForm();
                }
                await this.fetchTransfers();
                toastr.options = this.data.toastrOptions;
                toastr.success(this.translate.instant('_Deleted_warehouse_transfer_text'), this.translate.instant("_Success"));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }


    shouldFetchArticleState(id: number) {
        if (this.itemLookupComponent.articles.find(x => x.id == id) != undefined)
            return false;
        if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == id) != undefined)
            return false;

        return true;
    }
    async selectItem(item: MovingArticleModel) {
        if (this.shouldFetchArticleState(item.id)) {
            try {
                var articlesToFetch = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.activeTransfer.warehouseFromId, 1, 99999, '', false, item.id);
                if (this.documentArticles == undefined)
                    this.documentArticles = [];
                this.documentArticles.push(articlesToFetch[0]);
                this.selectedArticle = articlesToFetch[0];
            } catch (err: any) {
                toastr.options = this.data.toastrOptions;
                toastr.error(err.error['Message'], this.translate.instant("_Error"));
            }
        } else {
            if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == item.id))
                this.selectedArticle = this.documentArticles.find(x => x.id == item.id);
            else
                this.selectedArticle = this.itemLookupComponent.articles.find(x => x.id == item.id);
        }

        this.enableItemForm();
        $("#quantity").val(item.quantity);
        this.iForm.quantity.setValue(item.quantity);
        this.startedEditing = true;
        this.editingItems = true;
        this.editingItem = item;
        this.editingItemIndex = this.activeTransfer.articles.indexOf(item);
        this.itemsTrigger.nativeElement.click();
        this.amountInput.nativeElement.focus();
    }
    editItem() {
        this.isSubmitted = false;
        if (this.addItemForm.invalid) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted) {
            return;
        }

        var itemId = 0; var itemQuantity = 0;
        var existingPriceArticle = this.checkForSamePriceItem(null, true);
        if (existingPriceArticle != null) {
            existingPriceArticle.quantity += parseFloat((this.iForm.quantity.value).toFixed(6));
            existingPriceArticle.totalFinalPrice = parseFloat((existingPriceArticle.finalPrice * existingPriceArticle.quantity).toFixed(6));

           
            this.activeTransfer.totalAmount = 0;
            this.activeTransfer.articles.forEach(x => {
                this.activeTransfer.totalAmount += item.totalFinalPrice;
            });
            itemId = existingPriceArticle.id;
            itemQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));

            this.activeTransfer.articles.forEach((element, index) => {
                if (element == this.editingItem) this.activeTransfer.articles.splice(index, 1);
            });
        }
        else {
            var newQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
            var quantityDiff = this.editingItem.quantity - newQuantity;
            this.editingItem.quantity = newQuantity;
            this.editingItem.totalFinalPrice = parseFloat((this.editingItem.finalPrice * this.editingItem.quantity).toFixed(6));

            var item = this.activeTransfer.articles.find(x => x.id == this.editingItem.id);
            item = this.editingItem;

            this.activeTransfer.totalAmount = 0;
            this.activeTransfer.articles.forEach(x => {
                this.activeTransfer.totalAmount += item.totalFinalPrice;
            });
        }

        this.editWarehouseTransfer();
        this.editingItem = undefined;
        this.resetItemForm();
    }

    validateAddForm() {
        var check = true;

        if (this.addWarehouseFromId == 0 || this.addWarehouseFromId == this.addWarehouseToId) {
            $("#add_transfer_warehouseFromId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_transfer_warehouseFromId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.addWarehouseToId == 0 || this.addWarehouseFromId == this.addWarehouseToId) {
            $("#add_transfer_warehouseToId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_transfer_warehouseToId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    proceedToAddItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addTransferItemsTrigger").click();
    }

    proceedToEditItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addTransferItemsTrigger").click();
    }

    backToHeader() {
        $("#headerModalTrigger").click();
    }

    negateEnter(event: KeyboardEvent) {
        if (event.key == "Enter")
            event.preventDefault();
    }

    editNumber() {
        if (!this.canEditNumber())
            return;

        this.documentNumberOpen.nativeElement.click();
        var input = $("#documentNumberInput");
        input.removeClass("is-invalid");

        if (this.activeTransfer.documentNumber != null)
            input.val(this.activeTransfer.documentNumber);
        else
            input.val(this.nextNumber);
    }
    canEditNumber() {
        if (this.editEnabled && !this.startedEditing)
            return false;

        return true;
    }
    saveNumber() {
        var input = $("#documentNumberInput");
        if (input.val() == '' || input.val().includes('.')) {
            input.addClass("is-invalid");
            return;
        } else {
            input.removeClass("is-invalid");
            this.activeTransfer.documentNumber = input.val();
            this.activeTransfer.customDocumentNumber = this.activeTransfer.documentNumber;
            this.activeTransfer.warehouseTransferNumber = this.activeTransfer.documentNumber + "/" + ((new Date()).getFullYear() % 100);
        }

        this.documentNumberClose.nativeElement.click();
    }

    switchToHeaderAndEnableAdding() {
        this.headerTrigger.nativeElement.click();
        this.enableAdding();
    }

    enableAdding() {
        this.resetActiveTransfer();
        this.getNextNumber();
        this.resetForm();
        this.addEnabled = true;
        this.editEnabled=false;
        this.headerSaved=false;
        this.enableHeaderForm();
        this._changeRef.detectChanges();
        this.firstHeaderFormElement.nativeElement.focus();
    }
    setPostAddingLayout() {
        this.addEnabled = false;
        this.editEnabled = true;
        this.startedEditing = true;
        this.disableHeaderForm(false);
        this.enableItemForm();
        this.itemsTrigger.nativeElement.click();
    }

    async addItem(save: boolean = false) {
        this.isSubmitted = false;
        if (this.addItemForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateItemForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted) {
            return;
        }

        var itemId = 0; var itemQuantity = 0;
        var existingPriceArticle = this.checkForSamePriceItem(this.selectedArticle);
        if (existingPriceArticle != null) {
            this.activeTransfer.totalAmount -= existingPriceArticle.totalFinalPrice;

            existingPriceArticle.quantity += this.iForm.quantity.value;
            existingPriceArticle.totalFinalPrice = parseFloat((existingPriceArticle.finalPrice * existingPriceArticle.quantity).toFixed(6));
            itemId = existingPriceArticle.id;
            itemQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));

            this.activeTransfer.totalAmount += existingPriceArticle.totalFinalPrice;
        }
        else {
            var item = new MovingArticleModel();
            item.id = this.selectedArticle.id;
            item.code = this.selectedArticle.code;
            item.name = this.selectedArticle.name;
            item.quantity = parseFloat((this.iForm.quantity.value).toFixed(6));
            item.measureShort = this.selectedArticle.measureShort;
            item.finalPrice = this.selectedArticle.nc;
            item.mpc =this.selectedArticle.mpc;
            item.vpc =this.selectedArticle.vpc;
            item.isNormative = this.selectedArticle.isNormative;
            item.g = this.selectedArticle.groupId;
            item.gt = this.selectedArticle.groupTypeId;
            item.totalFinalPrice = parseFloat((item.finalPrice * item.quantity).toFixed(6));

            this.activeTransfer.articles.push(item);
            this.activeTransfer.totalAmount += item.totalFinalPrice;
            itemId = item.id;
            itemQuantity = item.quantity;
        }

        this.selectedArticle = undefined;
        this.resetItemForm();

        if (save) {
            await this.editWarehouseTransfer(true);
            //this.itemLookupComponent.updateWarehouseState(itemId, -itemQuantity);
        }
    }

    resetItemForm() {
        $("#itemCodeInput").val(undefined);
        this.iForm.quantity.setValue(null);

        this.selectedArticle = undefined;
        this.editingItem = undefined;
        $("#itemCodeInput").removeClass('is-invalid');
    }

    checkForSamePriceItem(activeItem: ArticleModel = null, updating: boolean = false) {
        if (updating) {
            var finalPrice = this.editingItem.finalPrice;
            return this.activeTransfer.articles.find(x => x.id == this.editingItem.id && x.finalPrice == finalPrice && this.activeTransfer.articles.indexOf(x) != this.editingItemIndex);
        } else {
            var finalPrice = activeItem.nc;
            return this.activeTransfer.articles.find(x => x.id == activeItem.id && x.finalPrice == finalPrice);
        }
    }

    async removeItem(item: MovingArticleModel) {
        this.activeTransfer.totalAmount -= item.totalFinalPrice;

        this.activeTransfer.articles.forEach((element, index) => {
            if (element == item) this.activeTransfer.articles.splice(index, 1);
        });

        await this.editWarehouseTransfer();
    }

    validateItemForm() {
        var check = true;

        if (this.selectedArticle == undefined) {
            check = false;
            $("#itemCodeInput").addClass('is-invalid');
        }
        else {
            $("#itemCodeInput").removeClass('is-invalid');
        }

        return check;
    }

    async selectArticle(a: ArticleModel) {
        $("#itemCodeInput").val(a.code);
        this.selectedArticle = a;

        if (this.selectedArticle != undefined) {
            $("#itemCodeInput").removeClass('is-invalid');
        }
    }
    selectArticleByCode(event: KeyboardEvent, c: string) {
        if (event.key != 'Enter')
            return;

        event.preventDefault();
        this.selectedArticle = this.itemLookupComponent.articles.find(x => x.code == c);

        if (this.selectedArticle != undefined) {
            $("#itemCodeInput").removeClass('is-invalid');
        }
    }
    enterOnRow(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            var element = event.target as HTMLElement;
            element.click();
        }
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchTransfers();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchTransfers();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchTransfers();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchTransfers();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchTransfers();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}