import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SignOffModel } from "../models/documents/signOff.model";
import { DataService } from "./data.service";
import { DeleteDocumentDTO } from "../models/documents/deleteDocumentDTO";
import { UpdateInvoicePaymentDTO } from "../models/updateInvoicePaymentDTO";
import { UpdateInvoiceDiscountDTO } from "../models/documents/updateInvoiceDiscountDTO";
import * as FileSaver from 'file-saver';

@Injectable()
export class SignOffRestService{
    xmlExtension:string = 'application/xml';
    inpExtension:string = 'text/plain';
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async getSignOffs(issuerName:string, warehouseId:number, dateFrom:string, dateTo:string, clientId:number, page:number, pageSize:number){
        var params = "?IssuerName=" + issuerName + "&DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&ClientId=" + clientId + "&Page=" + page + "&PageSize=" + pageSize;
        
        if(warehouseId!=undefined)
            params+="&WarehouseId="+warehouseId;
        
        var config = environment.apiUrl;
        return await this.http.get(config + "api/SignOff/FilterSignOffs" + params,{
            headers: {
            Authorization: 'Bearer ' + this.data.token,
        }
    }).toPromise() as any;
    }

    async getTypes(){
        var config = environment.apiUrl;
            return await this.http.get(config + "api/SignOff/GetSignOffTypes",  {
                headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async createSignOff(obj: SignOffModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/SignOff/CreateSignOff", obj,{
            headers: {
            Authorization: 'Bearer ' + this.data.token,
        }
        }).toPromise() as any;
    }

    async editSignOff(obj: SignOffModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/SignOff/UpdateSignOff", obj,{
            headers: {
            Authorization: 'Bearer ' + this.data.token,
        }
    }).toPromise() as any;
    }

    async getNextNumber(clientId:number){
        var params = "?ClientId=" + clientId + "&TypeCode=" + "SIGNOFF";
        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentGeneral/GetNextNumber" + params, {
            headers: {
            Authorization: 'Bearer ' + this.data.token,
        }
        }).toPromise() as any;
    }

    async deleteSignOff(deleteId: string) {
        var dto = new DeleteDocumentDTO();
        dto.id=deleteId;
        var config = environment.apiUrl;
        return await this.http.post(config + "api/SignOff/DeleteSignOff", dto, {
            headers: {
            Authorization: 'Bearer ' + this.data.token,
        }
        }).toPromise() as any;
    }

    saveFile(docData: any, fileName: string, extension:string): void {
        FileSaver.saveAs(docData, fileName + extension);
    }

    async getSignOffPDF(clientId:number, id:any){
        var obj={
         clientId:clientId,
         id:id
        }
         var config = environment.apiUrl;
         return await this.http.post(config + "api/SignOff/GetSignOffPDF", obj, {
             headers: {
                 Authorization: 'Bearer ' + this.data.token,
                 'Accept': 'application/pdf' 
             },
             responseType: 'blob'
         }).toPromise() as any;
    }
}