export class ProductionArticleModel{
    id:number;
    code:string;
    name:string;
    quantity:number;
    measureShort:string;

    nc:number;
    vpc:number;
    mpc:number;
    marginAmount:number;
    marginPercentage:number;

    totalNC:number;
    totalVPC:number;
    totalMPC:number;

    taxId:number;
    taxName:string;
    taxPercentage:number;
    taxAmount:number;
    totalTaxAmount:number;

    rawForProductId:number;
    g:number;
    gt:number;
    presetData:boolean;

    priceUpToDate:boolean;
}