export class MovingArticleModel{
    id:number;
    name:string;
    code:string;
    quantity:number;
    measureShort:string;    
    vpc:number;
    mpc:number;

    isNormative:boolean;
    finalPrice:number;
    totalFinalPrice:number;
    g:number;
    gt:number;
}