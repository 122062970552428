import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RetailBillModel } from "../models/documents/retailBill.model";
import { DataService } from "./data.service";

@Injectable()
export class RetailBillRestService{
    constructor(private http: HttpClient, private data:DataService) { }

    async getRetailBills(clientUserName:string, dateFrom:string, dateTo:string, warehouseId:number, paymentTypeId:number, pointOfSaleId:number, customerId:number, clientId:number, page:number, pageSize:number, forConsolidated:boolean=false){
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&ClientId=" + clientId + "&Page=" + page + "&PageSize=" + pageSize + "&ForConsolidated=" + forConsolidated;

        if(clientUserName!=undefined && clientUserName!=null && clientUserName!='')
            params+="&ClientUserName=" + clientUserName;
        if(warehouseId!=undefined && warehouseId!=null && !isNaN(warehouseId))
            params+="&WarehouseId="+warehouseId;
        if(pointOfSaleId!=undefined && pointOfSaleId!=null && !isNaN(pointOfSaleId))
            params+="&PointOfSaleId="+pointOfSaleId;
        if(paymentTypeId!=undefined && paymentTypeId!=null && !isNaN(paymentTypeId))
            params+="&PaymentTypeId="+paymentTypeId;
        if(customerId!=undefined && customerId!=null && !isNaN(customerId))
            params+="&CustomerId="+customerId;
            
        var config = environment.apiUrl;
        return await this.http.get(config + "api/RetailBill/FilterRetailBills" + params,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

    async createRetailBill(obj: RetailBillModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/RetailBill/CreateRetailBill", obj,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }
}