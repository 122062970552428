import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArticleComponent } from './components/article/article.component';
import { GroupComponent } from './components/group/group.component';
import { GroupTypeComponent } from './components/groupType/groupType.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { FooterComponent } from './components/page/footer/footer.component';
import { HeaderComponent } from './components/page/header/header.component';
import { SidebarComponent } from './components/page/sidebar/sidebar.component';
import { RetailPlaceComponent } from './components/retailPlace/retailPlace.component';
import { UserAccountComponent } from './components/userAccount/userAccount.component';
import { ArticleRestService } from './services/article.service';
import { ClientRestService } from './services/client.service';
import { DataService } from './services/data.service';
import { GroupRestService } from './services/group.service';
import { GroupTypeRestService } from './services/groupType.service';
import { RetailPlaceRestService } from './services/retailPlace.service';
import { UserAccountRestService } from './services/userAccount.service';
import { TranslatePipe, TranslateService, TRANSLATION_PROVIDERS } from './translate';
import { ProfileComponent } from './components/profile/profile.component';
import { CustomerSupplierRestService } from './services/customerSupplier.service';
import { CustomerSuppliersComponent } from './components/customer-suppliers/customer-suppliers.component';
import { RetailBillRestService } from './services/retail-bill.service';
import { DatePipe } from '@angular/common';
import { PaymentTypeRestService } from './services/payment-type.service';
import { WarehouseRestService } from './services/warehouse.service';
import { OutgoingInvoiceComponent } from './components/documents/outgoing-invoice/outgoing-invoice.component';
import { OutgoingInvoiceRestService } from './services/outgoing-invoice.service';
import { CurrencyRestService } from './services/currency.service';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { TaxComponent } from './components/tax/tax.component';
import { TaxRestService } from './services/tax.service';
import { IncomingInvoiceRestService } from './services/incoming-invoice.service';
import { IncomingInvoiceComponent } from './components/documents/incoming-invoice/incoming-invoice.component';
import { WarehouseStateComponent } from './components/warehouse-state/warehouse-state.component';
import { WarehouseStateRestService } from './services/warehouseState.service';
import { RetailBillComponent } from './components/reports/retail-bill/retail-bill.component';
import { PointOfSaleRestService } from './services/pointOfSale.service';
import { WarehouseTransferComponent } from './components/documents/warehouse-transfer/warehouse-transfer.component';
import { WarehouseTransferRestService } from './services/warehouse-transfer.service';
import { DispatchNoteComponent } from './components/documents/dispatch-note/dispatch-note.component';
import { DispatchNoteRestService } from './services/dispatch-note.service';
import { ProductionDocumentComponent } from './components/documents/production-document/production-document.component';
import { StocktakingComponent } from './components/documents/stocktaking/stocktaking.component';
import { ProductionDocumentRestService } from './services/production-document.service';
import { ItemLookupComponent } from './components/documents/item-lookup/item-lookup.component';
import { PaymentTypeComponent } from './components/payment-type/payment-type.component';
import { StocktakingRestService } from './services/stocktaking.service';
import { AvatarModule } from 'ngx-avatars';
import { RecapitulationOutgoingComponent } from './components/reports/recapitulation-outgoing/recapitulation-outgoing.component';
import { ReportRestService } from './services/reports.service';
import { RecapitulationIncomingComponent } from './components/reports/recapitulation-incoming/recapitulation-incoming.component';
import { RecapitulationDispatchComponent } from './components/reports/recapitulation-dispatch/recapitulation-dispatch.component';
import { RecapitulationTransferComponent } from './components/reports/recapitulation-transfer/recapitulation-transfer.component';
import { RecapitulationProductionComponent } from './components/reports/recapitulation-production/recapitulation-production.component';
import { ArticleCardComponent } from './components/reports/article-card/article-card.component';
import { SalesArticleComponent } from './components/reports/sales-article/sales-article.component';
import { SalesOperatorComponent } from './components/reports/sales-operator/sales-operator.component';
import { SalesOperatorDateComponent } from './components/reports/sales-operator-date/sales-operator-date.component';
import { SalesCustomerComponent } from './components/reports/sales-customer/sales-customer.component';
import { WarehouseDischargeComponent } from './components/reports/warehouse-discharge/warehouse-discharge.component';
import { ShiftClosingComponent } from './components/reports/shift-closing/shift-closing.component';
import { FiscalizationSettingsComponent } from './components/fiscalization-settings/fiscalization-settings.component';
import { FiscalRestService } from './services/fiscal.service';
import { StocktakingDifferenceComponent } from './components/documents/stocktaking-difference/stocktaking-difference.component';
import { PrintFiscalReportComponent } from './components/reports/fiscalization/print-fiscal-report/print-fiscal-report.component';
import { MoneyDepositComponent } from './components/reports/fiscalization/money-deposit/money-deposit.component';
import { MoneyWithdrawalComponent } from './components/reports/fiscalization/money-withdrawal/money-withdrawal.component';
import { PrintDuplicateComponent } from './components/reports/fiscalization/print-duplicate/print-duplicate.component';
import { DashboardRestService } from './services/dashboard.service';
import { MeasureComponent } from './components/measure/measure.component';
import { MeasureRestService } from './services/measure.service';
import { FiscalReportsComponent } from './components/fiscal-reports/fiscal-reports.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SalesOperatorPdfViewerComponent } from './components/reports/sales-operator/sales-operator-pdf/sales-operator-pdf.component';
import { SalesCustomerPdfViewerComponent } from './components/reports/sales-customer/sales-customer-pdf/sales-customer-pdf.component';
import { RecapitulationIncomingPdfViewerComponent } from './components/reports/recapitulation-incoming/recapitulation-incoming-pdf/recapitulaltion-incoming-pdf.component';
import { RecapitulationOutgoingPdfViewerComponent } from './components/reports/recapitulation-outgoing/recapitulation-outgoing-pdf/recapitulation-outgoing-pdf.component';
import { ArticleCardPdfViewerComponent } from './components/reports/article-card/article-card-pdf/article-card-pdf.component';
import { RecapitulationDispatchPdfViewerComponent } from './components/reports/recapitulation-dispatch/recapitulation-dispatch-pdf/recapitulation-dispatch-pdf.component';
import { RecapitulationTransferPdfViewerComponent } from './components/reports/recapitulation-transfer/recapitulation-transfer-pdf/recapitulation-transfer-pdf.component';
import { RecapitulationProductionPdfViewerComponent } from './components/reports/recapitulation-production/recapitulation-production-pdf/recapitulation-production-pdf.component';
import { DispatchNotePdfViewerComponent } from './components/documents/dispatch-note/dispatch-note-pdf/dispatch-note-pdf.component';
import { DispatchNoteWithoutPricesPdfViewerComponent } from './components/documents/dispatch-note/without-prices-pdf/dispatch-note-without-prices-pdf.component';
import { IncomingInvoicePdfViewerComponent } from './components/documents/incoming-invoice/incoming-invoice-pdf/incoming-invoice-pdf.component';
import { OutgoingInvoicePdfViewerComponent } from './components/documents/outgoing-invoice/outgoing-invoice-pdf/outgoing-invoice-pdf.component';
import { DispatchNoteFromOutgoingInvoicePdfViewerComponent } from './components/documents/outgoing-invoice/dispatch-note-pdf/dispatch-note-pdf.component';
import { ProductionDocumentPdfViewerComponent } from './components/documents/production-document/production-document-pdf/production-document-pdf.component';
import { StocktakingDifferencePdfViewerComponent } from './components/documents/stocktaking-difference/stocktaking-difference-pdf/stocktaking-difference-pdf.component';
import { WarehouseTransferPdfViewerComponent } from './components/documents/warehouse-transfer/warehouse-transfer-pdf/warehouse-transfer-pdf.component';
import { SignOffComponent } from './components/documents/sign-off/sign-off.component';
import { SignOffRestService } from './services/sign-off.service';
import { RecapitulationSignOffComponent } from './components/reports/recapitulation-sign-off/recapitulation-sign-off.component';
import {NgxPopperjsModule} from 'ngx-popperjs';
import { DocumentPdfComponent } from './components/documents/document-pdf/document-pdf.component';
import { RecapitulationSignOffArticlesComponent } from './components/reports/recapitulation-sign-off-articles/recapitulation-sign-off-articles.component';
import { RecapitulationStocktakingDiffComponent } from './components/reports/recapitulation-stocktaking-diff/recapitulation-stocktaking-diff.component';
import { ConsolidatedInvoiceComponent } from './components/documents/consolidated-invoice/consolidated-invoice.component';
import { EstimateComponent } from './components/documents/estimate/estimate.component';
import { EstimateRestService } from './services/estimate.service';
import { CancellationComponent } from './components/reports/cancellation/cancellation.component';

@NgModule({
  declarations: [
    AppComponent,
    TranslatePipe,
    LoginComponent,
    //page
    HeaderComponent,
    SidebarComponent,
    FooterComponent,

    HomeComponent,

    //client management
    UserAccountComponent,
    RetailPlaceComponent,

    //general data
    ArticleComponent,
    GroupComponent,
    GroupTypeComponent,
    ProfileComponent,
    CustomerSuppliersComponent,
    OutgoingInvoiceComponent,
    WarehouseComponent,
    TaxComponent,
    MeasureComponent,
    IncomingInvoiceComponent,
    WarehouseStateComponent,
    WarehouseTransferComponent,
    DispatchNoteComponent,
    ProductionDocumentComponent,
    StocktakingComponent,
    StocktakingDifferenceComponent,
    ItemLookupComponent,
    PaymentTypeComponent,
    SignOffComponent,

    //reports
    RetailBillComponent,
    RecapitulationOutgoingComponent,
    RecapitulationIncomingComponent,
    RecapitulationDispatchComponent,
    RecapitulationTransferComponent,
    RecapitulationProductionComponent,
    ArticleCardComponent,
    SalesArticleComponent,
    SalesOperatorComponent,
    SalesOperatorDateComponent,
    SalesCustomerComponent,
    WarehouseDischargeComponent,
    ShiftClosingComponent,
    FiscalizationSettingsComponent,
    PrintFiscalReportComponent,
    MoneyDepositComponent,
    MoneyWithdrawalComponent,
    PrintDuplicateComponent,
    FiscalReportsComponent,
    SalesOperatorPdfViewerComponent,
    SalesCustomerPdfViewerComponent,
    RecapitulationIncomingPdfViewerComponent,
    RecapitulationOutgoingPdfViewerComponent,
    ArticleCardPdfViewerComponent,
    RecapitulationDispatchPdfViewerComponent,
    RecapitulationTransferPdfViewerComponent,
    RecapitulationProductionPdfViewerComponent,
    DispatchNotePdfViewerComponent,
    DispatchNoteWithoutPricesPdfViewerComponent,
    IncomingInvoicePdfViewerComponent,
    OutgoingInvoicePdfViewerComponent,
    DispatchNoteFromOutgoingInvoicePdfViewerComponent,
    ProductionDocumentPdfViewerComponent,
    StocktakingDifferencePdfViewerComponent,
    WarehouseTransferPdfViewerComponent,
    RecapitulationSignOffComponent,
    DocumentPdfComponent,
    RecapitulationSignOffArticlesComponent,
    RecapitulationStocktakingDiffComponent,
    ConsolidatedInvoiceComponent,
    EstimateComponent,
    CancellationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgHttpLoaderModule.forRoot(),
    AvatarModule,
    PdfViewerModule,
    NgxPopperjsModule
  ],
  providers: [
    DataService,
    TRANSLATION_PROVIDERS,
    TranslateService,
    ClientRestService,
    ArticleRestService,
    GroupRestService,
    GroupTypeRestService,
    UserAccountRestService,
    RetailPlaceRestService,
    CustomerSupplierRestService,
    RetailBillRestService,
    PaymentTypeRestService,
    WarehouseRestService,
    WarehouseStateRestService,
    OutgoingInvoiceRestService,
    IncomingInvoiceRestService,
    WarehouseTransferRestService,
    StocktakingRestService,
    CurrencyRestService,
    PointOfSaleRestService,
    TaxRestService,
    DatePipe,
    DispatchNoteRestService,
    ProductionDocumentRestService,
    ReportRestService,
    FiscalRestService,
    DashboardRestService,
    MeasureRestService,
    SignOffRestService,
    EstimateRestService,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'hr' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }