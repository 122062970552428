import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ArticleModel } from 'src/app/models/article.model';
import { ArticleRestService } from 'src/app/services/article.service';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/translate';
import * as focusTrap from 'focus-trap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MeasureModel } from 'src/app/models/measure.model';
import { GroupModel } from 'src/app/models/group.model';
import { TaxModel } from 'src/app/models/tax.model';
import { GroupRestService } from 'src/app/services/group.service';
import { TaxRestService } from 'src/app/services/tax.service';
import { MeasureRestService } from 'src/app/services/measure.service';
import toastr from 'toastr';

declare var $: any;
declare var bootstrap:any;
@Component({
  selector: 'app-item-lookup',
  templateUrl: './item-lookup.component.html',
})
export class ItemLookupComponent implements OnInit {
  public articles: ArticleModel[] = [];
  public itemsToAdd: ArticleModel[];
  public selectedArticle: ArticleModel;
  public selectedArticleCode: string;

  public itemModalOpen: boolean = false;
  public fetchInProgress: boolean = false;
  public firstArticleFetch: boolean = true;
  public preventScrollLoadingitem: boolean = false;

  public articlePage: number = 1;
  public articlePageSize: number = 20;
  public searchItemCodeName: string;
  public articleSearch: string = undefined;
  public previousArticleSearch: string = undefined;
  @Input() public buttonIsHidden: boolean = true;
  @Input() warehouseId: number;
  @Input() selectedCurrency: string;
  @Input() incomingInvoiceSelection: boolean;
  @Input() addingRaw: boolean = false;
  @Input() productsOnly: boolean = false;
  @Input() noStates: boolean = false;
  @Input() includeNormatives: boolean = false;
  @Input() excludeArticleId:any=null;
  @Input() customModalId:string="kt_modal_select_invoice_item";

  @Output() selectArticleEvent = new EventEmitter<ArticleModel>();
  @Output() sendRegistryArticlesEvent = new EventEmitter<Array<ArticleModel>>();
  @Output() closedModal = new EventEmitter();
  @ViewChild('itemSelectBody') itemSelectBody: ElementRef;
  @ViewChild('searchItemInput') searchItemInput: ElementRef;
  @ViewChild('modalTrigger') modalTrigger:ElementRef;
  @ViewChild('modal') modal:ElementRef;
  public addEditGroupId: number = null;
  public addEditMeasureId: number = null;

  //Quick add
  @Input() public quickAddEnabled:boolean=false;
  public quickAdd:boolean=false;
  public quickAddForm:FormGroup;
  @ViewChild('selectAddArticleGroupId') selectAddArticleGroupId: ElementRef;
  @ViewChild('selectAddMeasureId') selectAddMeasureId: ElementRef;
  public showBarcodes: boolean = false;
  public newBarcode: string;
  public barcodesList: string[] = [];
  public displaySecondaryBarcodes: string[] = [];
  public showBarcodeErrorAdd = false;
  public showBarcodeErrorEdit: boolean = false;
  public barcodeErrorTextAdd: string = "";
  public barcodeErrorTextEdit: string = "";
  get f() { return this.quickAddForm.controls; }
  public isSubmitted:boolean=false;
  public measures: MeasureModel[];
  public groups: GroupModel[];
  public taxes: TaxModel[];
  public mpc: number;
  public vpc: number;
  public nc: number;
  public hh: number;
  public margin: number;
  public nextNumber: number;
  public shiftPressed:boolean=false;
  @ViewChild('saveArticleButton') saveArticleButton:ElementRef;
  @ViewChild('firstQuickAddElement') firstQuickAddElement:ElementRef;

  @HostListener('document:mousewheel', ['$event'])
  onScroll(event: Event): void {
    if (!this.itemModalOpen)
      return;
    const scrollContainer = this.itemSelectBody.nativeElement;
    var diff = (scrollContainer.scrollHeight - scrollContainer.scrollTop) - scrollContainer.clientHeight;
    if ((diff >= -1 && diff <= 1) && !this.fetchInProgress && !this.preventScrollLoadingitem) {
      this.articlePage++;
      this.fetchArticles();
    }
  }

  constructor(
    private articleRestService: ArticleRestService,
    private groupRest:GroupRestService,
    private taxRest:TaxRestService,
    private measureRest:MeasureRestService,
    private data: DataService,
    private translate: TranslateService,
    private _changeRef: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.fetchGroups();
    this.fetchTaxes();
    this.fetchMeasures();
    this.createQuickAddForm();
  }

  ngAfterViewInit(): void {
    $('#' + this.customModalId).on('shown.bs.modal', () => {
      this.itemModalOpen = true;
      this._changeRef.detectChanges();
      $("#searchItemInput").focus();
    });

    $('#'+this.customModalId).on('hidden.bs.modal', () => {
      this.itemModalOpen = false;
      this.refreshItemLookup();
      this._changeRef.detectChanges();
      if(!this.quickAdd){
        $("#amountInput").focus();
        this.closedModal.emit();
      }
    });
  }

  openItemsModal() {
    if (this.articles.length == 0)
      this.fetchArticles();
    const modal = new bootstrap.Modal($('#' + this.customModalId));
    modal.show();
  }

  refreshItemLookup() {
    if (this.searchItemCodeName != '' && this.searchItemCodeName != undefined) {
      this.searchItemCodeName = '';
      this.articleSearch = '';
      this.previousArticleSearch = '';
      this.fetchArticles(true);
    }
  }

  async searchItems() {
    this.articleSearch = this.searchItemCodeName;

    if (this.articleSearch != this.previousArticleSearch) {
      this.articlePage = 1;
      this.preventScrollLoadingitem = false;
      await this.fetchArticles();
      this.previousArticleSearch = this.articleSearch;
      this.selectedArticle = undefined;
    }
  }

  async fetchArticles(refresh: boolean = false) {
    try {
      this.fetchInProgress = true;
      if(refresh)
        this.articlePage=1;

      var response = undefined;
      if (this.noStates)
        response = (await this.articleRestService.getArticles(this.searchItemCodeName, null, this.data.user.clientId, null, null, this.articlePage, this.articlePageSize, false)).data;
      else
        response = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.warehouseId, this.articlePage, this.articlePageSize, this.articleSearch, this.productsOnly, null, null, this.includeNormatives, this.excludeArticleId);

      if (this.articleSearch != this.previousArticleSearch || refresh)
        this.articles = response;
      else if (this.articles == undefined || this.articles.length == 0)
        this.articles = response;
      else {
        var previousLength = this.articles.length;
        this.articles = this.articles.concat(response);
        if (this.articles.length == previousLength)
          this.preventScrollLoadingitem = true;
      }

      this.sendRegistryArticlesEvent.emit(this.articles);
      this.itemsToAdd = this.articles;
      this.fetchInProgress = false;
    }
    catch (err) {
      if (err.status == 401) {
        this.data.logout();
      }
    }
  }


  async selectArticle(a: ArticleModel) {
    this.selectedArticle = a;
    this.selectArticleEvent.emit(this.selectedArticle);
  }
  enterOnRow(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      event.preventDefault();
      var element = event.target as HTMLElement;
      element.click();
    }
  }

  updateWarehouseState(id: number, quantity: number) {
      this.articles.find(x => x.id == id).warehouseState += quantity;
  }


  //Quick Add
  openQuickAdd(){
    this.quickAdd=true;
    this.preventScrollLoadingitem=true;
    this.resetQuickAddForm();
    this._changeRef.detectChanges();
    this.firstQuickAddElement.nativeElement.focus();
  }
  closeQuickAdd(){
    this.quickAdd=false;
    this.preventScrollLoadingitem=false;
    this._changeRef.detectChanges();
    this.searchItemInput.nativeElement.focus();
  }

  @HostListener('document:keydown', ['$event'])
  checkForShiftPress(event: KeyboardEvent) {
    if(event.key=='Shift'){
      this.shiftPressed=true;
    }
  }
  @HostListener('document:keyup', ['$event'])
  checkForShiftRelease(event: KeyboardEvent) {
    if(event.key=='Shift'){
      this.shiftPressed=false;
    }
  }
  public loopQuickAddForm(event: KeyboardEvent, lastElement:boolean){
      if(lastElement){
          if(event.key=='Tab' && !this.shiftPressed){
              event.preventDefault();
              this.firstQuickAddElement.nativeElement.focus();
          }
      }else{
          if(event.key=='Tab' && this.shiftPressed){
              event.preventDefault();
              this.saveArticleButton.nativeElement.focus();
          }
      }
  }

  createQuickAddForm(){
    this.quickAddForm = this.formBuilder.group({
      name: ['', Validators.required],
      code: [''],
      addEditGroupId: [0, Validators.required],
      mpc: [0],
      nc: [0],
      vpc: [0],
      addEditMeasureId: [0, Validators.required],
    });

    var self = this;
    $("#article_add_measureId").select2();
    $('#article_add_measureId').on("change", function () {
        self.selectAddMeasureId.nativeElement.value = $(this).val();
        self.addEditMeasureId = $(this).val();
    });

    $("#article_add_groupId").select2();
    var self = this;
    $('#article_add_groupId').on("change", function () {
        self.selectAddArticleGroupId.nativeElement.value = $(this).val();
        self.addEditGroupId = $(this).val();
    });
  }
  
  addBarcode(inputId: string, addCheck: boolean) {
    if (this.validateBarcode(inputId, addCheck))
        this.barcodesList.push(this.newBarcode);
  }
  validateBarcode(inputId: string, addCheck: boolean) {
    if (this.newBarcode.toString().length < 12 || this.newBarcode.toString().length > 15) {
        $(inputId).addClass("is-invalid");
        addCheck ? this.showBarcodeErrorAdd = true : this.showBarcodeErrorEdit = true;
        addCheck ? this.barcodeErrorTextAdd = "Barcode must be between 12 and 15 digits" :
            this.barcodeErrorTextEdit = "Barcode must be between 12 and 15 digits";
        return false;
    }
    else {
        $(inputId).removeClass("is-invalid");
        addCheck ? this.showBarcodeErrorAdd = false : this.showBarcodeErrorEdit = false;
    }

    if (this.barcodesList.includes(this.newBarcode)) {
        $(inputId).addClass("is-invalid");
        addCheck ? this.showBarcodeErrorAdd = true : this.showBarcodeErrorEdit = true;
        addCheck ? this.barcodeErrorTextEdit = "Duplicate barcode" : this.barcodeErrorTextEdit = "Duplicate barcode";
        return false;
    }
    else {
        $(inputId).removeClass("is-invalid");
        addCheck ? this.showBarcodeErrorAdd = false : this.showBarcodeErrorEdit = false;
    }

    return true;
  }
  removeBarcode(b: string) {
      var removeIndex = this.barcodesList.indexOf(b);

      var dataAfterRemoval: string[] = [];
      for (let index = 0; index < this.barcodesList.length; index++) {
          if (index != removeIndex)
              dataAfterRemoval.push(this.barcodesList[index]);
      }

      this.barcodesList = dataAfterRemoval;
  }
  calculateMargin() {
    this.margin = this.mpc - this.nc;
  }
  calculatePrices() {
      this.hh = this.mpc;
      if(this.addEditGroupId==null || this.addEditGroupId==undefined)
          return;

      var group = this.groups.find(x=>x.id==this.addEditGroupId);
      var taxToApply = this.taxes.find(x=>x.id==group.taxId).amount;
      if(taxToApply==0)
          this.vpc = this.mpc;
      else
          this.vpc = parseFloat((this.mpc / (1 + taxToApply / 100)).toFixed(6));
      this.quickAddForm.controls.vpc.setValue(this.vpc);
  }

  resetQuickAddForm() {
    this.quickAddForm.controls['name'].setValue("");
    this.quickAddForm.controls['code'].setValue("");
    this.quickAddForm.controls['addEditGroupId'].setValue(0);
    this.quickAddForm.controls['mpc'].setValue(0);
    this.quickAddForm.controls['nc'].setValue(0);
    this.quickAddForm.controls['vpc'].setValue(0);
    this.quickAddForm.controls['addEditMeasureId'].setValue(0);

    $('#article_add_groupId').val(null).trigger("change");
    $('#article_add_measureId').val(null).trigger("change");

    this.barcodesList = [];
    this.newBarcode = "";
    this.showBarcodeErrorAdd = false;
    $("#newBarcodeAdd").removeClass('is-invalid');
    this.getNextNumber();

    this.vpc=0;
    this.mpc=0;
    this.nc=0;
    this.hh=0;
    this.margin=0;
  }

  async getNextNumber() {
    this.nextNumber = await this.articleRestService.getNextNumber(this.data.user.clientId);
    this.quickAddForm.controls.code.setValue(this.nextNumber);
  }

  async fetchGroups() {
    var response = await this.groupRest.getGroups("", null, this.data.user.clientId, 1, 10000);
    this.groups = response.data;
  }
  async fetchTaxes() {
      var response = await this.taxRest.getTaxes(this.data.user.clientId, '', 1, 10000);
      this.taxes = response.data;
  }
  async fetchMeasures() {
      var response = await this.measureRest.getMeasures(this.data.user.clientId, "", 1, 10000);
      this.measures = response.data;
  }

  async addArticle() {
    this.isSubmitted = false;
    if (this.quickAddForm.invalid) {
        this.isSubmitted = true;
    }

    if (!this.validateAddForm()) {
        this.isSubmitted = true;
    }
    if (this.isSubmitted == true) {
        return;
    }

    var obj = this.createAddObject();

    try {
        var response=await this.articleRestService.createArticle(obj);
        obj.id=response;
        this.searchItemCodeName = obj.name;
        await this.searchItems();
        this.closeQuickAdd();
        this.selectArticle(obj);
        toastr.options = this.data.toastrOptions;
        toastr.success(this.translate.instant('_Added_article_text'), this.translate.instant("_Success"));
    }
    catch (err) {
        if(err.status==200){
          this.closeQuickAdd();
          this.searchItemCodeName = obj.name;
          await this.searchItems();
          this.selectArticle(obj);
          toastr.options = this.data.toastrOptions;
          toastr.success(this.translate.instant('_Added_article_text'), this.translate.instant("_Success"));
        }
        else{
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }
  }
  validateAddForm() {
      var check = true;

      if (this.selectAddArticleGroupId.nativeElement.value == 0) {
          $("#article_add_groupId").nextAll('span').first().addClass("is-invalid");
          check = false;
      }
      else {
          $("#article_add_groupId").nextAll('span').first().removeClass("is-invalid");
      }

      if (this.selectAddMeasureId.nativeElement.value == 0) {
          $("#article_add_measureId").nextAll('span').first().addClass("is-invalid")
          check = false;
      }
      else {
          $("#article_add_measureId").nextAll('span').first().removeClass("is-invalid");
      }

      return check;
  }
  createAddObject() {
    var obj = new ArticleModel();
    obj.barcodes = this.barcodesList;
    obj.clientId = this.data.user.clientId;
    obj.description = '';
    obj.groupId = this.quickAddForm.controls.addEditGroupId.value;
    obj.shouldProduce = false;
    obj.isNormative = false;
    obj.measureId = this.quickAddForm.controls.addEditMeasureId.value;
    obj.name = this.quickAddForm.controls.name.value;
    obj.code = this.quickAddForm.controls.code.value;
    if (this.nextNumber.toString() != obj.code)
        obj.customCode = true;
    obj.isActive = true;
    obj.mpc = this.quickAddForm.controls.mpc.value == null ? 0 : parseFloat((this.quickAddForm.controls.mpc.value).toFixed(6));
    obj.nc = this.quickAddForm.controls.nc.value == null ? 0 : parseFloat((this.quickAddForm.controls.nc.value).toFixed(6));
    obj.vpc = this.quickAddForm.controls.vpc.value == null ? 0 : parseFloat((this.quickAddForm.controls.vpc.value).toFixed(6));
    obj.happyHourPrice = obj.mpc;
    obj.minAmount = 0;
    obj.margin = parseFloat(this.margin.toFixed(6));
    return obj;
  }
}