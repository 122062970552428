import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { CustomerSuppliersModel } from "../models/customerSupplier.model";
import { DataService } from "./data.service";

@Injectable()
export class CustomerSupplierRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }


    async getCustomerSuppliers(clientId: number, name: string, countryId: number, townId: number, isCustomer:boolean, isSupplier:boolean, isCompany:boolean, page: number, pageSize: number) {
        var params = "?ClientId=" + clientId + "&Page=" + page + "&PageSize=" + pageSize;

        if(name!=undefined && name!=null && name!='')
            params+="&Name=" + name;
        if(isSupplier!=null)
            params += "&IsSupplier=" + isSupplier;
        if(isCustomer!=null)
            params += "&IsCustomer=" + isCustomer; 
        if(isCompany!=null)
            params += "&IsCompany=" + isCompany; 

               
        if (countryId != null && countryId != undefined && countryId != 0 && !isNaN(countryId))
            params += "&CountryId=" + countryId;

        if (townId != null && townId != undefined && townId != 0 && !isNaN(townId))
            params += "&TownId=" + townId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/CustomersAndSuppliers/Filter" + params,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

    async createCustomerSupplier(obj:CustomerSuppliersModel){
        var config = environment.apiUrl;
        return await this.http.post(config + "api/CustomersAndSuppliers/Create", obj,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

    async updateCustomerSupplier(obj:CustomerSuppliersModel){
        var config = environment.apiUrl;
        return await this.http.post(config + "api/CustomersAndSuppliers/Update", obj,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

    async getCountries(){
        var params = "?Page=1&PageSize=100000";
        var config = environment.apiUrl;
        return await this.http.get(config + "api/Country/GetAll" + params,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

    async getTowns(){
        var params = "?Page=1&PageSize=100000";
        var config = environment.apiUrl;
        return await this.http.get(config + "api/Town/GetAll" + params,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

    async fetchTownsByCountry(countryId:number){
        var params = "?CountryId=" + countryId;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/Town/GetByCountry" + params,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }

    async getNextNumber(clientId:number){
        var params = "?ClientId=" + clientId;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/CustomersAndSuppliers/GetNextNumber" + params,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }
        ).toPromise() as any;
    }
}