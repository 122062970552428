<div class="post d-flex flex-column" id="kt_post" style="width:100%">
    <div id="kt_content_container" style="margin-top:25px;margin-bottom:25px;">
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title">
                    <button type="button" class="btn btn-info me-3" (click)="back()"><i class="fas fa-arrow-left"></i>{{'_Back' | translate}}
                    </button>
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <button type="button" class="btn btn-primary me-3 btnPrimaryDarkerFocus" (click)="printPDF()"><i class="fas fa-print"></i> {{'_Print' | translate}}
                        </button>
                        <button type="button" class="btn btn-primary me-3 btnPrimaryDarkerFocus" (click)="saveFile()"><i class="fas fa-download"></i>{{'_Download' | translate}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <pdf-viewer
                [src]="pdfSrc"
                [rotation]="0"
                [original-size]="true"
                [show-all]="true"
                [fit-to-page]="true"
                [zoom]="1"
                [zoom-scale]="'page-width'"
                [stick-to-page]="false"
                [render-text]="true"
                [external-link-target]="'blank'"
                [autoresize]="true"
                [show-borders]="false"
                style="width: 100%; height: 100vh;border:1px solid #eee;padding-top:10px;padding-bottom:10px;"
                ></pdf-viewer>
            </div>
        </div>
    </div>
</div>

<iframe id="pdfIframeII" style="display:none" [src]="sanitizedPdfSrc"></iframe>