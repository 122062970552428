<div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    id="login_background" style="background-size:cover;background-image: url(assets/media/bg/bg_7.jpg);">
    <div class="d-flex flex-row" style="height: 100vh;">
        <!-- Logo na vrhu levo -->
        <div class="d-flex flex-column" style="height: 100vh; position: relative;">
            <!-- Logo na vrhu -->
          
        
            <!-- H1 elementi pri dnu -->
            <div class="d-flex flex-column justify-content-end" style="flex-grow: 1;">
                <h1 style="font-size: 24px; font-weight: bolder; color: #fff; margin-left: 30px;">{{'_Techical_support' | translate}}</h1>
                <h1 style="font-size: 32px; font-weight: bolder; color: #f26b38; margin-left: 30px; margin-bottom: 30px;">+387 62 000 000</h1>
            </div>
        </div>
        <!-- Login form div -->
        <div class="d-flex flex-column justify-content-center align-items-center h-100" style="margin-left:auto; 
            background-color: rgba(3, 2, 7, 0.3); 
            backdrop-filter: blur(6px); 
            border: 1px solid rgba(3, 2, 7, 0.2); 
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); 
            border-radius: 10px;
            width:40%">
            <div class="w-lg-600px bg-body  p-10 p-lg-15 d-flex flex-column align-items-center" style="background-color:transparent!important">
                <img src="assets/media/logos/logo.png" width="250" style="margin-bottom: 50px;" />

                <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" action="#"
                    [formGroup]="loginForm">
                    <div class="text-center mb-10">
                        <h1 class="text-gray-900 mb-3" style="color:#fff!important">{{'_Login_text' | translate}}</h1>
                    </div>
                    <div class="fv-row mb-10">
                        <label class="form-label fs-5 fw-bolder text-gray-900"
                            style="color:#fff!important">{{'_Username' | translate}}</label>
                        <input class="form-control form-control-lg form-control-solid" type="text" name="email"
                            formControlName="username" autocomplete="off" required
                            [ngClass]="{ 'is-invalid': isSubmitted && f.username.errors }" />
                    </div>
                    <div class="fv-row mb-10">
                        <div class="d-flex flex-stack mb-2">
                            <label class="form-label fw-bolder text-gray-900 fs-5 mb-0"
                                style="color:#fff!important">{{'_Password' | translate}}</label>
                        </div>
                        <input class="form-control form-control-lg form-control-solid" type="password" name="password"
                            autocomplete="off" formControlName="password" required
                            [ngClass]="{ 'is-invalid': isSubmitted && f.password.errors }" />
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn btn-lg w-100 mb-5" id="btnLogin"
                           (click)="login()">
                            <span class="indicator-label">{{'_Login' | translate}}</span>
                            <span class="indicator-progress">Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>